/// <reference path="../global.d.ts"/>

import "jquery-mousewheel";

export default class Slider{

    private _dom: DOMManipulator

    constructor(dom: DOMManipulator){
        this._dom = dom;
    }

    private _mouseWheelEvent(event: TrigEvent, delta: number){
        event.preventDefault();
        event.currentTarget.scrollLeft -= delta * 30;
    }

    apply(selector: string){
        this._dom(selector).on('mousewheel', this._mouseWheelEvent.bind(this));
    }

    static register(dom: DOMManipulator): Slider{
        const slider = new Slider(dom);
        slider.apply(".image-slider");

        return slider;
    }
}