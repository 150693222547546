/// <reference path="../global.d.ts"/>

import InputHelper from "../Utils/InputHelper";
import Modal from "./Modal";

export default class ButtonNumber {
  private _dom: DOMManipulator;
  private _modal: Modal;

  constructor(dom: DOMManipulator, modal: Modal) {
    this._dom = dom;
    this._modal = modal;
  }

  clickButtonEvent(event: TrigEvent) {
    event.preventDefault();

    const $target = this._dom(event.currentTarget);
    const fieldName: string = $target.data("field");
    const $input = $target
      .closest("form")
      .find("input[name='" + fieldName + "']");
    let currentVal = parseInt($input.val() as string);
    if (!isNaN(currentVal)) {
      const type: string = $target.data("type");
      if (type === "minus") {
        const minValue = parseInt($input.attr("min") as string);
        if (currentVal > minValue) {
          currentVal--;
          InputHelper.trigChange($input.val(currentVal));
        }
        if (currentVal === minValue) {
          $target.attr("disabled", "true");
        }
      } else if (type === "plus") {
        const maxValue = parseInt($input.attr("max") as string);
        if (currentVal < maxValue) {
          currentVal++;
          InputHelper.trigChange($input.val(currentVal));
        }
        if (currentVal === maxValue) {
          $target.attr("disabled", "true");
        }
      }
    } else {
      $input.val(0);
    }
  }

  focusinEvent(event: TrigEvent) {
    const $target = this._dom(event.currentTarget);
    $target.data("oldValue", $target.val() as string);
  }

  private _onlyChangeValue(event: TrigEvent) {
    const $target = this._dom(event.currentTarget);

    const value = parseInt($target.val() as string);
    const $title = $target.data("message-title");
    if (isNaN(value)) {
      const message = $target.data("message-nan");
      this._modal.info(message, $title);
      return;
    }

    const values = {
      min: parseInt($target.attr("min") as string),
      max: parseInt($target.attr("max") as string),
      current: value,
      old: parseInt($target.data("oldValue") as string),
    };

    var $formGroup = $target.closest(".input-group");
    if (values.current >= values.min) {
      $formGroup.find(".btn-number[data-type='minus']").removeAttr("disabled");
    } else {
      const message = $target.data("message-min");
      this._modal.info(message, $title);
      $target.val(values.old);
    }
    if (values.current <= values.max) {
      $formGroup.find(".btn-number[data-type='plus']").removeAttr("disabled");
    } else {
      const message = $target.data("message-max");
      this._modal.info(message, $title);
      $target.val(values.old);
    }
  }

  apply(
    $context: JQueryExtends<Document>,
    inputSelector: string,
    buttonSelector: string,
  ) {
    $context.on("focusin", inputSelector, this.focusinEvent.bind(this));
    InputHelper.change(
      $context as unknown as DOMBase,
      this._onlyChangeValue.bind(this),
      inputSelector,
    );
    $context.on("click", buttonSelector, this.clickButtonEvent.bind(this));
  }

  static register(
    dom: DOMManipulator,
    $context: JQueryExtends<Document>,
    modal: Modal,
  ): ButtonNumber {
    const buttonNumber = new ButtonNumber(dom, modal);
    buttonNumber.apply($context, "input.input-number", "button.btn-number");
    return buttonNumber;
  }
}
