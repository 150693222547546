/// <reference path="../../global.d.ts"/>

import AFilter from "./AFilter";

export default class FilterCheckable extends AFilter {
  static USE_VALUE = ["color"];
  private _values: Set<string>;

  constructor(dom: DOMManipulator, $elem: JQueryExtends) {
    super(dom, $elem);
    this._values = new Set();

    this.refresh();

    $elem.on("click", "input", this._clickCallback.bind(this));
  }

  refresh(): this {
    this._values.clear();
    this._$elem
      .find("input")
      .filter((index, elem) => elem.checked)
      .map((index, elem) => elem.value)
      .get()
      .reduce((set, v) => (set.add(v), set), this._values);

    return this;
  }

  private _clickCallback(event: TrigEvent): void {
    const target = event.currentTarget;

    if (target.checked) {
      this._values.add(target.value);
    } else {
      this._values.delete(target.value);
    }

    const values = this.getValues();
    let options = {
      [this.getAttribute()]: this.serialize(values),
    };
    this._setUsed(values.length > 0);

    this.notifyVisitor(options);
  }

  setValues(values: string[]): this {
    this._values.clear();
    values.reduce((set, v) => (set.add(v), set), this._values);

    this._setUsed(0 < this._values.size);
    this.render();

    return this;
  }

  render(): void {
    this._$elem.find("input").each((i, elem) => {
      const $input = this._dom(elem);
      const name = $input.attr("value") as string;
      const value = this._values.has(name);
      $input.prop("checked", value);
    });
  }

  getValues(): Array<any> {
    return Array.from(this._values.values());
  }

  formatValues(): string {
    let formatValue: string[] = [];
    this._$elem.find("input").each((i: number, elem: HTMLElement) => {
      const $input = this._dom(elem);
      const value = $input.attr("value") as string;
      if (0 > FilterCheckable.USE_VALUE.indexOf(this.getAttribute())) {
        this._insertIfInSet(
          formatValue,
          this._values,
          value,
          $input.next().html(),
        );
      } else {
        this._insertIfInSet(formatValue, this._values, value, value);
      }
    });

    return formatValue.join(", ");
  }

  private _insertIfInSet(
    collection: Array<string>,
    set: Set<string>,
    value: string,
    title: string,
  ): void {
    if (set.has(value)) {
      let tmp = title.replace(/<small>[^<]*<\/small>/g, "").trim();
      collection.push(tmp);
    }
  }
}
