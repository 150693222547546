export default class Cookie {
  private _w: Window;

  constructor(w: Window) {
    this._w = w;
  }

  set(cname: string, cvalue: string, exdays: number): this {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    this._w.document.cookie =
      cname +
      "=" +
      cvalue +
      "; expires=" +
      d.toUTCString() +
      "; path=/; SameSite=Strict;";
    return this;
  }

  get(cname: string): string {
    return (this._w as WindowLoader).arival.utils.getCookieByName(cname);
  }
}
