
export type PromiseResolve<T> = (value: T | PromiseLike<T>) => void;
export type PromiseReject = (reason?: any) => void;

export type TimeoutPromiseCallback<T> = (resolve: PromiseResolve<T>, reject: PromiseReject) => void;
export type TimeoutPromiseCallbackAny = TimeoutPromiseCallback<any>;

export default class TimeoutPromise<T> {
  private _promise: Promise<T> | null
  private _executor: TimeoutPromiseCallback<T> | null
  private _semaphore: number;
  private _timeout: number;
  private _idTimeout: NodeJS.Timeout | null;

  constructor(timeout?: number) {
    this._promise = null;
    this._executor = null;
    this._timeout = timeout || 0;
    this._semaphore = 0;
    this._idTimeout = null;
  }

  static create(timeout?: number): TimeoutPromise<void> {
    return new TimeoutPromise(timeout)
  }

  setTimeout(timeout: number): this {
    this._timeout = timeout;
    return this;
  }

  get(): Promise<T> {
    if (!this._semaphore) {
      let timeout = this._timeout;
      let promise = new Promise((resolve: PromiseResolve<void>, reject: PromiseReject): void => {
        this._idTimeout = setTimeout(() => {
          this.clean()
          resolve()
        }, timeout);
      }) as Promise<T>;

      if (null === this._executor) {
        this._promise = promise
      } else {
        this._promise = new Promise(this._executor);
        const withPromise = this._promise
        promise.then(() => {
          return withPromise
        })
      }
    }
    this._semaphore++;

    if (null === this._promise) {
      throw new Error('not set promise for set')
    }

    return this._promise;
  }

  with<U>(executor: TimeoutPromiseCallback<U>): TimeoutPromise<U> {
    const self = this as any as TimeoutPromise<U>
    if (0 === this._semaphore) {
      self._executor = executor
    }

    return self
  }

  clean() {
    this._promise = null;
    this._semaphore = 0;
    if (null !== this._idTimeout) {
      clearTimeout(this._idTimeout);
    }
  }

  isFirstGetting() {
    return 1 === this._semaphore;
  }
}