/// <reference path="../global.d.ts"/>

import { forSnippet, SNIPPET_FULL } from "../Utils/NameSnippets";
import type SnippetHandler from "../Utils/SnippetHandler";
import type { BeforeUpdateEvent, CompleteEvent } from "../Utils/SnippetHandler";
import type DOMMan from "../Utils/DOMMan";

interface Rule {
  op: string;
  arg: string;
}

export default class Spam {
  constructor(private _dom: DOMMan) {}

  fillValue($input: HTMLInputElement) {
    if ("string" !== typeof $input.dataset.netteRules) {
      return;
    }

    let rules: Rule[];
    try {
      rules = JSON.parse($input.dataset.netteRules) as Rule[];
    } catch {
      return;
    }

    rules.forEach(function (rule, index) {
      switch (rule.op) {
        case ":blank":
          $input.value = "";
          break;
        case ":equal":
          $input.value = rule.arg;
          break;
        default:
      }
    });
  }

  hideInput($input: HTMLInputElement) {
    const $wrapper = this._dom.parent(this._dom.parent($input));

    if (
      this._dom.containsClass($wrapper, "row") &&
      (this._dom.containsClass($wrapper, "form-group") ||
        this._dom.containsClass(
          $wrapper?.children[0] as HTMLElement,
          "col-form-label",
        ))
    ) {
      this._dom.hide($wrapper);
    } else {
      this._dom.hide($input);
    }
  }

  apply(selector: string): void {
    (this._dom.find(selector) as HTMLInputElement[]).forEach(($item) => {
      this.fillValue($item);
      this.hideInput($item);
    });
  }

  registerSnippetHandler(snippetHandler: SnippetHandler): this {
    snippetHandler.always().then((e: CompleteEvent) => {
      this.apply("input.junk");
    });

    return this;
  }

  static register(dom: DOMMan): Spam {
    const spam = new Spam(dom);
    spam.apply("input.junk");

    return spam;
  }
}
