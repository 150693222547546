/// <reference path="../../global.d.ts"/>
/// <reference path="../../Utils/NotifyVisitor.d.ts"/>

type JQueryCollapse = JQueryExtends;

export default abstract class AFilter {
  protected _dom: DOMManipulator;
  protected _$elem: JQueryCollapse;
  private _attribute: string;
  private _title: string;
  private _used: boolean;
  private _visitor: NotifyVisitor | null;

  constructor(dom: DOMManipulator, $elem: JQueryExtends) {
    this._dom = dom;
    this._$elem = $elem;
    this._attribute = $elem.data("attribute");
    this._title = $elem.prev().text();
    this._used = false;
    this._visitor = null;
  }

  registerVisitor(visitor: NotifyVisitor): this {
    this._visitor = visitor;

    return this;
  }

  notifyVisitor(options: object): void {
    if (null !== this._visitor) {
      this._visitor.notify(options);
    }
  }

  getTitle(): string {
    return this._title;
  }

  getAttribute(): string {
    return this._attribute;
  }

  serialize(values: any[]): string {
    return values ? values.join(";") : "";
  }

  unserialize(values: string): string[] {
    return values ? values.split(";") : [];
  }

  setState(state: string): this {
    if (state) {
      this.show();
    }
    let values = this.unserialize(state);
    this.setValues(values);

    return this;
  }

  abstract setValues(values: string[]): this;

  abstract getValues(): Array<any>;

  show(): this {
    this._$elem.collapse("show");

    return this;
  }

  hide(): this {
    this._$elem.collapse("hide");

    return this;
  }

  isUsed(): boolean {
    return this._used;
  }

  protected _setUsed(on: boolean) {
    this._used = !!on;
    return this;
  }

  abstract formatValues(): string;

  toString(): string {
    return `${this._title}: ${this.formatValues()}`;
  }
}
