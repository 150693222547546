type PartCallback = Function;
interface Part {
  name: string;
  dependencies: Array<string>;
  callback: PartCallback;
  fired: boolean;
}

export default class Stack {
  private stack: Array<Function>;
  private parts: Map<string, Part>;

  constructor(private window: WindowsStorageAny) {
    this.stack = window["__stack__"] = window["__stack__"] || [];
    this.parts = new Map<string, Part>();
  }

  use(): this {
    this.flushStack().mockPush();

    return this;
  }

  private flushStack(stack?: Array<Function>): this {
    stack = stack || this.stack;
    while (stack.length) {
      stack.shift()?.call(this.window);
    }

    return this;
  }

  private mockPush(): this {
    this.stack.push = (...cb: Array<PartCallback>): number => {
      this.flushStack(cb);

      return this.stack.length;
    };

    return this;
  }

  static register(window: Window): Stack {
    const w = window as WindowsStorageAny;

    return w.__stack ? w.__stack : new Stack(w);
  }
}
