/// <reference path="global.d.ts"/>

declare const module: __WebpackModuleApi.Module;
declare const window: Window;

import type { Naja } from "naja/dist/Naja";

import jQuery from "./Utils/ExtendsJQuery";
import "bootstrap";

import Dispatcher from "./Event/Dispatcher";
import DefaultDispatcher from "./Event/DefaultDispatcher";
import LazyDispatcher from "./Event/LazyDispatcher";
import Breakpoints from "./Utils/Breakpoints";
import { naja, LiveFormExtension } from "./Form/LiveFormValidationNaja";
import DOMMan from "./Utils/DOMMan";
import Modal from "./Control/Modal";
import Cookie from "./Utils/Cookie";
import WebUI from "./Control/WebUI";
import Helpers from "./Utils/Helpers";
import SnippetHandler from "./Utils/SnippetHandler";
import Stack from "./Utils/Stack";
import Fragment from "./Utils/Fragment";
import UrlFactory from "./Utils/UrlFactory";
import Urls from "./Utils/Urls";
import BuilderFactory from "./Utils/BuilderFactory";
import AdaptiveMenu from "./Control/Menu/AdaptiveMenu";

type FrontendContainer = ServiceContainer & {
  dom: DOMManipulator;
  domMan: DOMMan;
  breakpoints: Breakpoints;
  naja: Naja;
  modal: Modal;
  cookie: Cookie;
  webUI: WebUI;
  helpers: Helpers;
  urlFactory: UrlFactory;
  fragment: Fragment;
  documentDispatcher: Dispatcher;
  windowDispatcher: Dispatcher;
  menuDispatcher: Dispatcher;
  snippetHandler: SnippetHandler;
  stack: Stack;
  builderFactory: BuilderFactory;
  urls: Urls;
};

import ServiceContainerImpl from "./Utils/ServiceContainer";
function initContainerHandler(c: FrontendContainer, d: Document, w: Window) {
  c.dom = jQuery as unknown as DOMManipulator;
  c.domMan = new DOMMan(w);
  c.documentDispatcher = new DefaultDispatcher("arival", w.document);
  c.windowDispatcher = new DefaultDispatcher("arival", w);
  c.menuDispatcher = new LazyDispatcher("arival", c.dom, ".rmm");
  c.fragment = Fragment.register(c.documentDispatcher, c.dom, w);
  c.snippetHandler = SnippetHandler.register(c.naja);
  const $window = c.dom(w);
  c.breakpoints = Breakpoints.register(
    {
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
    c.windowDispatcher,
  ).registerWindow($window);
  c.modal = new Modal(c.dom);
  c.cookie = new Cookie(w);
  c.webUI = new WebUI(c.dom);
  c.helpers = Helpers.register(c.dom, w);
  c.stack = Stack.register(w);
  c.builderFactory = BuilderFactory.register(c.dom, c.naja);
  c.urlFactory = UrlFactory.register(w);
  c.urls = Urls.register(w, c.urlFactory);
}

import UserConsent from "./Control/UserConsent";
import Search from "./Form/Search";
import ReloadUrl from "./Control/ReloadUrl";
import InfoTgm from "./Control/InfoTgm";
import AdaptiveImages from "./Control/AdaptiveImages";
import StickyMenu from "./Control/Menu/StickyMenu";
import Tooltip from "./Control/Tooltip";
import Gallery from "./Control/Gallery";
import EmailShield from "./Control/EmailShield";
import Spam from "./Control/Spam";
import Carousel from "./Control/Carousel";
import HiddenPartsBreakpoints from "./Control/HiddenPartsBreakpoints";

function basicHandler(c: FrontendContainer, d: Document, w: Window) {
  UserConsent.register(c.dom, c.modal, c.cookie)?.setUpdateCallback(
    (w as WindowsStorageAny)["arivalUpdateCcs"],
  );
  Search.register(c.dom, w);

  const menu = AdaptiveMenu.register(
    c.dom,
    c.menuDispatcher,
  ).registerBreakpoints(c.breakpoints);
  const reloader = ReloadUrl.register(c.naja, c.urlFactory);
  //reloader.registerMenuDispatcher(c.menuDispatcher)
  const hiddenMainContentOnMobile = HiddenPartsBreakpoints.register(c.dom)
    .registerBreakpoins(c.breakpoints)
    .registerMenuDispatcher(c.menuDispatcher);

  const $window = c.dom(w);
  InfoTgm.register(c.dom, c.documentDispatcher, $window);
  AdaptiveImages.register(c.dom, c.windowDispatcher);

  StickyMenu.register(c.dom, c.breakpoints, $window);
  const $document = c.dom(d) as unknown as JQueryExtends<Document>;

  Tooltip.register(c.dom).registerSnippetHandler(c.snippetHandler, $document);
  Gallery.register(c.dom, null);

  EmailShield.register(c.dom);
  const spam = Spam.register(c.domMan);
  spam.registerSnippetHandler(c.snippetHandler);

  c.stack.use();
  c.fragment.triggerUpdate();

  Carousel.register(c.dom);
}

import ProductDetail from "./Control/ProductDetail";
import ProductList from "./Control/ProductList";
import AddButton from "./Control/AddButton";
import Slider from "./Control/Slider";
import NotifyMessage from "./Control/NotifyMessage";
import ButtonNumber from "./Control/ButtonNumber";

function productsHandler(c: FrontendContainer, d: Document, w: Window) {
  const $window = c.dom(w);
  const $document = c.dom(d) as unknown as JQueryExtends<Document>;
  const $body = c.dom("body") as DOMBase;

  ProductList.register(
    c.dom,
    c.naja,
    c.snippetHandler,
    c.documentDispatcher,
    c.fragment,
    $window,
    c.modal,
    c.webUI,
    c.urlFactory,
    c.breakpoints,
    c.menuDispatcher,
  );

  ProductDetail.register(c.dom, c.documentDispatcher, c.fragment);
  AddButton.register(c.dom, c.naja, c.documentDispatcher, w, $body, $document);
  Slider.register(c.dom);
  NotifyMessage.register(c.dom);
  ButtonNumber.register(c.dom, $document, c.modal);
}

import FormCart from "./Form/Cart";
import FormSelectShippingPayment from "./Form/SelectShippingPayment";
import FormCustomer from "./Form/Customer";
import GatewayPayment from "./Form/GatewayPayment";
import { GoPayLite } from "./Form/GoPay";
import { ComgateLite } from "./Form/Comgate";

function orderProcessHandler(c: FrontendContainer, d: Document, w: Window) {
  FormCart.register(c.builderFactory, c.snippetHandler, c.modal);
  FormSelectShippingPayment.register(c.builderFactory, c.snippetHandler);
  const gatewayPayment = GatewayPayment.register(
    c.dom,
    c.urls,
    c.naja,
    c.modal,
  );
  FormCustomer.register(
    c.builderFactory,
    gatewayPayment,
    w,
    c.webUI,
    c.helpers,
  );
  GoPayLite.register(gatewayPayment, w);
  ComgateLite.register(gatewayPayment, c.helpers);
}

function inicializeNaja(d: Document, n: Naja) {
  const liveForm = new LiveFormExtension(d);
  liveForm.selectAjax(n);
  n.registerExtension(liveForm);
  n.initialize();

  return n;
}

function initializeApplication() {
  ServiceContainerImpl.create<FrontendContainer>("frontend", window)
    .init(function (c: FrontendContainer, d: Document, w: Window) {
      c.naja = inicializeNaja(d, naja);
      initContainerHandler(c, d, w);
    })
    .run("common", basicHandler)
    .run("products", productsHandler)
    .run("order-process", orderProcessHandler);
}

if (module.hot) {
  if (!(window as WindowsStorageAny)["initialize"]) {
    (window as WindowsStorageAny)["initialize"] = true;
    initializeApplication();
  }
  module.hot.accept();
} else {
  initializeApplication();
}
