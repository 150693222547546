import type State from "./State";

export default class DefaultState implements NotifyVisitor {
  private _state: State;
  private _default: Map<string, any>;

  constructor(state: State) {
    this._state = state;
    this._default = new Map();
  }

  setDefault(key: string, value: any): this {
    this._default.set(key, value);

    return this;
  }

  getDefault(key: string): any {
    return this._default.get(key) || null;
  }

  getState(): State {
    return this._state;
  }

  notify(options: object): void{
    this._state.notify(options);
  }

  getStateOrDefault(key: string): any {
    return this._state.getValue(key) || this.getDefault(key);
  }

  getClearedDefaultValue(options: object): [string, any][] {
    return Object.entries(options).map(
      ([k, v]) => ((v = this._default.get(k) === v ? null : v), [k, v])
    );
  }
}
