/// <reference path="../global.d.ts"/>

import type WebUI from "../Control/WebUI";

import BuilderFormAjaxRequest from "../Utils/BuilderFormAjaxRequest";
import InputHelper from "../Utils/InputHelper";
import OrderForm from "./OrderForm";

export default class Address extends OrderForm {
  static MISSING_NUMBER_STREET_MESSAGE =
    "Pravděpodobně chybi na konci ulice číslo domu";
  static INVOICE = 1;
  static SHIPPING = 2;
  static PARTS = [
    "company",
    "personName",
    "street",
    "city",
    "zip",
    "phoneNumber",
  ];

  private _dom: DOMManipulator;
  private _webUI: WebUI;
  private _changeIcoHandler: EventHandler | null;
  private _keyPressIcoHandler: EventHandler;
  private _clickLoadAresHandler: EventHandler;
  private _showDifferentAddressHandler: EventHandler;
  private _syncCollapseInputHandler: EventHandler;
  private _notNumberNotifyHandler: EventHandler;
  private _buttonLoadAresSelector: string | null;
  private _notifyOnlyFirstEvent: boolean;

  constructor(
    $orderForm: JQueryExtends,
    requestBuilder: BuilderFormAjaxRequest,
    dom: DOMManipulator,
    webUI: WebUI,
  ) {
    super($orderForm, requestBuilder);
    this._dom = dom;
    this._webUI = webUI;
    this._changeIcoHandler = null;
    this._clickLoadAresHandler = (event: TrigEvent) => {
      this._loadAresCallback(event);
    };
    this._keyPressIcoHandler = this._keyPressIcoCallback.bind(this);
    this._showDifferentAddressHandler =
      this._showDifferentAddressCallback.bind(this);
    this._syncCollapseInputHandler = this._syncCollapseInputCallback.bind(this);
    this._notNumberNotifyHandler = this._notNumberNotifyCallback.bind(this);
    this._buttonLoadAresSelector = null;
    this._notifyOnlyFirstEvent = true;
  }

  private findAddressInput(type: number, name: string) {
    let prefix = Address.SHIPPING === type ? "shipping" : "invoice";

    return this.findInput(`[${prefix}][${name}]`);
  }

  private _getButtonLoadAres(): DOMBase<HTMLButtonElement> {
    const selector = this._buttonLoadAresSelector;
    if (null === selector) {
      throw new Error("Unknow load ares button");
    }

    return this._$orderForm.find(selector) as DOMBase<HTMLButtonElement>;
  }

  private _keyPressIcoCallback(event: TrigEvent): void {
    if ("Enter" === event.key) {
      event.stopPropagation();
      const $button = this._getButtonLoadAres();
      event.target = $button.get(0);
      this._loadAresCallback(event);
    }
  }

  private _setStateButtonLoadAres(isDisabled: boolean): void {
    const $button = this._getButtonLoadAres();
    if (isDisabled) {
      $button.attr("disabled", "true");
    } else {
      $button.removeAttr("disabled");
    }
  }

  private _changeIcoCallback(event: TrigEvent): void {
    const $target = this._dom(event.currentTarget);
    this._setStateButtonLoadAres(!$target.val());
  }

  registerCIN(selector: string): this {
    if (null !== this._changeIcoHandler) {
      InputHelper.disableChange(
        this._$orderForm,
        this._changeIcoHandler,
        selector,
      );
    }
    this._changeIcoHandler = InputHelper.change(
      this._$orderForm,
      this._changeIcoCallback.bind(this),
      selector,
    );

    this._$orderForm.off("keypress", selector, this._keyPressIcoHandler);
    this._$orderForm.on("keypress", selector, this._keyPressIcoHandler);

    return this;
  }

  private _loadAresCallback(event: TrigEvent): void {
    this._webUI.startLoading();
    this._formRequestBuilder.build(event).then((data) => {
      this._webUI.stopLoading();
    });
  }

  registerAresLoad(selector: string): this {
    this._buttonLoadAresSelector = selector;
    this._setStateButtonLoadAres(true);
    this._$orderForm.off("click", selector, this._clickLoadAresHandler);
    this._$orderForm.on("click", selector, this._clickLoadAresHandler);

    return this;
  }

  private _showDifferentAddressCallback(event: TrigEvent): void {
    const isEmpty = Address.PARTS.every((part: string) => {
      const v = this.findAddressInput(Address.SHIPPING, part).val() as string;
      return "" === v || null === v || "undefined" === typeof v;
    });

    if (isEmpty) {
      Address.PARTS.forEach((part: string) => {
        const v = this.findAddressInput(Address.INVOICE, part).val() as string;
        this.findAddressInput(Address.SHIPPING, part).val(v);
      });
    }
  }

  registerDifferentAddress(selector: string): this {
    this._$orderForm.off(
      "show.bs.collapse",
      selector,
      this._showDifferentAddressHandler,
    );
    this._$orderForm.on(
      "show.bs.collapse",
      selector,
      this._showDifferentAddressHandler,
    );

    return this;
  }

  private _syncCollapseInputCallback(event: TrigEvent): void {
    const $target = this._dom(event.currentTarget);
    $target
      .prev(".checkbox")
      .children("input")
      .prop("checked", event.type === "shown");
  }

  registerSyncCheckbox(selector: string): this {
    this._$orderForm.off(
      "shown.bs.collapse hidden.bs.collapse",
      selector,
      this._syncCollapseInputHandler,
    );
    this._$orderForm.on(
      "shown.bs.collapse hidden.bs.collapse",
      selector,
      this._syncCollapseInputHandler,
    );

    return this;
  }

  private _notNumberNotifyCallback(event: TrigEvent): void {
    const $target = this._dom(event.target) as DOMBase;

    const $messageContainer = $target.siblings(
      ".not-number-notify",
    ) as unknown as JQueryExtends;
    if (!$messageContainer.exists()) {
      return;
    }

    const numberPartRegexp = $target.data("streetNumberRegexp");
    if (!numberPartRegexp) {
      return;
    }

    if (
      new RegExp(`^${numberPartRegexp}$`, "i").test($target.val() as string)
    ) {
      $messageContainer.empty().removeClass("help-block text-danger");
    } else {
      $messageContainer
        .text(Address.MISSING_NUMBER_STREET_MESSAGE)
        .addClass("help-block text-danger");
    }
  }

  registerNotNumberNotify(selector: string): this {
    this._$orderForm.off("change", selector, this._notNumberNotifyHandler);
    this._$orderForm.on("change", selector, this._notNumberNotifyHandler);

    return this;
  }

  static register(
    $form: JQueryExtends,
    requestBuilder: BuilderFormAjaxRequest,
    dom: DOMManipulator,
    webUI: WebUI,
  ): Address {
    super.defaultRequestBuilder(requestBuilder);
    const address = new Address($form, requestBuilder, dom, webUI)
      .registerCIN("input[name$='[invoice][CIN]']")
      .registerAresLoad("button[name$='[loadAres]']")
      .registerDifferentAddress("#different-collapse")
      .registerSyncCheckbox(".checkbox-collapse")
      .registerNotNumberNotify("input[name$='[street]']");

    return address;
  }
}
