/// <reference path="../global.d.ts"/>

export default class Template {
    template: string;
    constructor(template: string) {
        this.template = template;
    }

    apply(data: Record<string, string>): string {
        return Object.keys(data).reduce((template: string, key: string) => {
            return template.split("{" + key + "}").join(data[key]);
        }, this.template);
    }
}