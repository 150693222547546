/// <reference path="../global.d.ts"/>

import type { Naja } from "naja/dist/Naja";

export default class ServiceContainerImpl<T> {
  private _name: string;
  private _loader: Loader;

  constructor(
    private _prefix: string,
    private _window: Window,
  ) {
    this._loader = (_window as WindowLoader).arival.loader;
    this._name = `${_prefix}-container`;
  }

  init(handler: (c: T, d: Document, w: Window) => void): this {
    this._runLoader(["loaded-document"], handler, this._name);

    return this;
  }

  run(
    name: string,
    handler: (c: T, d: Document, w: Window) => void,
    deps: Array<string> | null = null,
  ): this {
    const fullName = `${this._prefix}-${name}`;
    deps ??= [
      "loaded-document",
      `loaded-${fullName}`,
      `inicialized-${this._name}`,
    ];
    if (null === this._window) {
      throw new Error("not initialize container");
    }
    this._runLoader(deps, handler, fullName);
    this._loader.loaded(fullName);

    return this;
  }

  private _runLoader(
    deps: Array<string>,
    handler: (c: T, d: Document, w: Window) => void,
    inicializedName: string,
  ): void {
    this._loader.run(deps, (cs: ServiceContainers, d: Document) => {
      const container = (cs[this._prefix] || (cs[this._prefix] = {})) as T;
      handler(container, d, this._window);
      this._loader.inicialized(inicializedName);
    });
  }

  static create<U>(prefix: string, w: Window): ServiceContainerImpl<U> {
    return new ServiceContainerImpl(prefix, w);
  }
}
