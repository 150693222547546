import Template from "../Utils/Template";

import type Cookie from "../Utils/Cookie";
import type Modal from "./Modal";

type UpdateCallback = (data: any) => void;

type ConsentContainer = Record<string, string>;

type StorageText = {
  label: string;
  content: string;
};

type CookieConsentConfig = {
  expireDays: number;
  content: string;
  consents: Record<string, string[]>;
  texts: Record<string, StorageText>;
  labels: Record<string, string>;
  notChange: Record<string, boolean>;
};

class Consents {
  [key: string]: boolean | null | ((on: boolean) => this);

  public functionality: boolean | null;
  public analytic: boolean | null;
  public marketing: boolean | null;

  constructor() {
    this.functionality = true;
    this.analytic = false;
    this.marketing = false;
  }

  public all(on: boolean): this {
    this.functionality = true;
    this.analytic = on;
    this.marketing = on;

    return this;
  }
}

type ConsentsBadge = Record<string, boolean | null>;

export default class UserConsent {
  static COOKIE_CONSENT_NAME: string = "_cc_20240307";
  static COOKIE_GRANTED: string = "granted";
  static COOKIE_DENIED: string = "denied";
  static COOKIE_UNKNOW: string = "unknow";
  static TEMPLATE_TEXT: Template = new Template(`<div class="consents-manager">
    <div class="row">
      <div class="col">
        {content}
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-12 text-right">
        <button class="btn btn-secondary m-2" id="consent-settings">{labels_more}</a>
        <button class="btn btn-success m-2" id="consents-allow">{labels_allow}</a>
      </div>
    </div>`);
  static TEMPLATE_SETTINGS: Template =
    new Template(`<div class="consents-manager">
    <div class="row">
      <div class="col-12 col-sm-4">
        <button class="btn btn-secondary m-2" id="consents-deny">{labels_deny}</a>
      </div>
      <div class="col-12 col-sm-8 text-right">
        <button class="btn btn-secondary m-2" id="consent-save">{labels_save}</a>
        <button class="btn btn-success m-2" id="consents-allow">{labels_allow}</a>
      </div>
    </div>
</div>
  `);
  static TEMPLATE_ITEM: Template =
    new Template(`<div class="row border-bottom mt-2">
  <div class="col-12 col-sm-9">
    <h3>{consent_label}</h3>
    <p>{consent_content}</p>
  </div>
  <div class="col-12 col-sm-3 text-right">
  <label class="switch">
    <input id="consent-{consent_name}" type="checkbox"{consent_checked}{consent_disabled}>
    <span class="slider"></span>
  </label>
  </div>
</div>`);

  private _modal: Modal;
  private _dom: DOMManipulator;
  private _cookie: Cookie;
  private _consents: Consents | null;
  private _config: CookieConsentConfig | null;
  private _$dataContainer: JQueryExtends;
  private _updateCallback: UpdateCallback | null;

  constructor(
    dom: DOMManipulator,
    $dataContaner: JQueryExtends,
    cookie: Cookie,
    modal: Modal,
  ) {
    this._dom = dom;
    this._cookie = cookie;
    this._modal = modal;
    this._config = null;
    this._consents = null;
    this._$dataContainer = $dataContaner;
    this._updateCallback = null;
  }

  setUpdateCallback(cb: UpdateCallback): this {
    this._updateCallback = cb;

    return this;
  }

  getConfig(): CookieConsentConfig {
    if (null === this._config) {
      this._config = {
        expireDays: this._$dataContainer.data("expires"),
        content: this._$dataContainer.data("content"),
        consents: this._$dataContainer.data("consents") as Record<
          string,
          string[]
        >,
        texts: this._$dataContainer.data("texts") as Record<
          string,
          StorageText
        >,
        labels: this._$dataContainer.data("labels") as Record<string, string>,
        notChange: this._$dataContainer.data("notChange") as Record<
          string,
          boolean
        >,
      };
    }

    return this._config;
  }

  getConsents(): Consents {
    if (null === this._consents) {
      this._consents = new Consents();
    }

    return this._consents;
  }

  loadConsets(): boolean {
    const consetsCookies = this._cookie.get(UserConsent.COOKIE_CONSENT_NAME);
    const config = this.getConfig();
    let loaded: boolean = false;
    let consents: Record<string, string>;
    try {
      consents = JSON.parse(consetsCookies);
      if (null === consents || "object" !== typeof consents) {
        throw new Error();
      }

      loaded = true;
    } catch (e) {
      consents = {};
    }

    const map = Object.keys(config.consents).reduce(function (
      m: Record<string, string>,
      k: string,
    ): Record<string, string> {
      config.consents[k].reduce(function (
        c: Record<string, string>,
        v: string,
        i: number,
      ): Record<string, string> {
        c[v] = k;

        return c;
      }, m);

      return m;
    }, {});

    const readed: string[] = [];
    this._consents = Object.keys(consents).reduce(function (
      o: Consents,
      k: string,
    ): Consents {
      const name = map[k];
      if (null === name || "undefined" === typeof name) {
      } else if (name in config.notChange) {
        o[name] = config.notChange[name];
      } else {
        const value = UserConsent.COOKIE_GRANTED === consents[k];
        if (readed.includes(name)) {
          o[name] &&= value;
        } else {
          readed.push(name);
          o[name] = value;
        }
      }

      return o;
    }, new Consents());

    return loaded;
  }

  storeConsets(): this {
    const config = this.getConfig();
    const o = this.buildPersistentConsents();
    if (null !== this._updateCallback) {
      this._updateCallback(o);
    }

    this._cookie.set(
      UserConsent.COOKIE_CONSENT_NAME,
      JSON.stringify(o),
      config.expireDays,
    );

    return this;
  }

  buildPersistentConsents(): ConsentContainer {
    const config = this.getConfig();
    const consents = this.getConsents() as ConsentsBadge;

    return Object.keys(consents).reduce(function (
      o: ConsentContainer,
      k: string,
    ): ConsentContainer {
      const value = consents[k];
      const rawValue =
        null === value
          ? UserConsent.COOKIE_UNKNOW
          : value
            ? UserConsent.COOKIE_GRANTED
            : UserConsent.COOKIE_DENIED;

      config.consents[k].reduce(function (
        c: ConsentContainer,
        v: string,
        i: number,
      ): ConsentContainer {
        c[v] = rawValue;

        return c;
      }, o);

      return o;
    }, {});
  }

  showSettings(): this {
    const config = this.getConfig();
    const $userConsentsSettings = this._dom(
      UserConsent.TEMPLATE_SETTINGS.apply({
        labels_save: config.labels.save,
        labels_deny: config.labels.deny,
        labels_allow: config.labels.allow,
      }),
    );

    const consents = this.getConsents() as ConsentsBadge;
    const $context = $userConsentsSettings.find("div.row") as DOMBase;
    Object.keys(consents).reduce(($el: DOMBase, key: string): DOMBase => {
      const consent: StorageText = config.texts[key];
      const $consent = this._dom(
        UserConsent.TEMPLATE_ITEM.apply({
          consent_label: consent.label,
          consent_content: consent.content,
          consent_name: key,
          consent_checked: consents[key] ? ' checked="checked"' : "",
          consent_disabled: key in config.notChange ? ' disabled="true"' : "",
        }),
      );
      $consent.insertBefore($el);

      return $el;
    }, $context);
    this._modal
      .useLarge()
      .disableClose()
      .custom($userConsentsSettings, config.labels.header);
    this._dom($userConsentsSettings).on(
      "click",
      "button",
      this._createButtonCallback(this._subCallbackSettings.bind(this)),
    );

    return this;
  }

  _subCallbackSettings($target: DOMBase): void {
    const id = $target.attr("id") as string;

    if (id.startsWith("consent-")) {
      if (id.endsWith("-save")) {
        const $context = $target.closest(".modal-body");
        this.applySettings($context);
        this.storeConsets();
        this._modal.hide();
      }
    }
  }

  _createButtonCallback(cb: ($target: DOMBase) => void) {
    return (event: TrigEvent) => {
      event.preventDefault();
      const $target = this._dom(event.target);
      const id = $target.attr("id") as string;
      const consents = this.getConsents();

      if (id.startsWith("consents-")) {
        if (id.endsWith("-allow")) {
          consents.all(true);
        } else if (id.endsWith("-deny")) {
          consents.all(false);
        }
        this.storeConsets();
        this._modal.hide();
      } else {
        cb($target);
      }
    };
  }

  applySettings($context: DOMBase): void {
    const consents = this.getConsents() as ConsentsBadge;
    Object.keys(consents).reduce((o: ConsentsBadge, k: string) => {
      o[k] = $context
        .find(`input[type="checkbox"]#consent-${k}`)
        .is(":checked");

      return o;
    }, consents);
  }

  bar(): this {
    if (this.loadConsets()) {
      const consents = this.getConsents();
    } else {
      const config = this.getConfig();
      const $userConsets = this._dom(
        UserConsent.TEMPLATE_TEXT.apply({
          content: config.content,
          labels_more: config.labels.more,
          labels_deny: config.labels.deny,
          labels_allow: config.labels.allow,
        }),
      );
      this._modal
        .useLarge()
        .disableClose()
        .custom($userConsets, config.labels.header);

      this._dom($userConsets).on(
        "click",
        "button",
        this._createButtonCallback(this._subCallbackBar.bind(this)),
      );
    }

    return this;
  }

  _subCallbackBar($target: DOMBase): void {
    const id = $target.attr("id") as string;

    if (id.startsWith("consent-")) {
      if (id.endsWith("-settings")) {
        this.showSettings();
      }
    }
  }

  registerSettings(selector: string): this {
    this._dom(selector).on("click", (event: TrigEvent): void => {
      this.showSettings();
    });
    return this;
  }

  static register(
    dom: DOMManipulator,
    modal: Modal,
    cookie: Cookie,
  ): UserConsent | null {
    const $container = dom(
      "#cookie-consent-settings",
    ) as unknown as JQueryExtends;
    let userConsent = null;
    if ($container.exists()) {
      userConsent = new UserConsent(dom, $container, cookie, modal);
      userConsent.bar().registerSettings(".show-cookies-settings");
    }

    return userConsent;
  }
}
