/// <reference path="../global.d.ts"/>

import Template from "../Utils/Template";

type ConfirmCallback = (result: string) => void;
type JQueryModal = JQueryExtends;

class ModalPart {
  $context!: JQueryModal;
  $header!: DOMBase;
  $content!: DOMBase;
  $footer!: DOMBase;
}

export default class Modal {
  static C_YES = "yes";
  static C_NO = "no";
  static C_CANCEL = "cancel";

  static T_INFO = "info";
  static T_SUCCESS = "success";
  static T_ERROR = "error";

  static TEMPLATE_MANAGER: Template = new Template(`
<div id="{ID_MODAL}" class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="{ID_MODAL}-label" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="{ID_MODAL}-label">Informace</h5>
        <button type="button" class="close" data-dismiss="modal" data-target="#{ID_MODAL}" data-bs-dismiss="modal" data-bs-target="#{ID_MODAL}" data-confirm="cancel" aria-label="Zavřít">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      </div>
      <div class="modal-footer">
      </div>
    </div>
  </div>
</div>`);
  private _dom: DOMManipulator;
  private _id: string;
  private _parts: ModalPart | null;
  private _infoFooter: DOMBase | null;
  private _confirmFooter: DOMBase | null;
  private _showed: boolean;
  private _disableClose: boolean;
  private _useLarge: boolean;
  private _confirmCallback: ConfirmCallback | null;
  private _theme: string;

  constructor(dom: DOMManipulator, id?: string) {
    this._dom = dom;
    this._id = id || "message-modal";
    this._showed = false;
    this._parts = null;
    this._infoFooter = null;
    this._confirmFooter = null;
    this._confirmCallback = null;
    this._disableClose = false;
    this._useLarge = false;
    this._theme = Modal.T_INFO;
  }

  custom(content: string | DOMBase, title?: string): void {
    const parts = this._getPart();
    this._fillCommonPart(parts, content, title);

    parts.$footer.empty();
    this._confirmCallback = null;

    const $modal = this._getPart().$context;
    this._showed = true;
    $modal.modal("show");
  }

  info(content: string | DOMBase, title?: string): void {
    const parts = this._getPart();
    this._fillCommonPart(parts, content, title);

    parts.$footer.empty().append(this._getInfoFooter());
    this._confirmCallback = null;

    const $modal = this._getPart().$context;
    this._showed = true;
    $modal.modal("show");
  }

  confirm(
    content: string | DOMBase,
    cb: ConfirmCallback,
    title?: string,
  ): void {
    const parts = this._getPart();
    this._fillCommonPart(parts, content, title);

    parts.$footer.empty().append(this._getConfirmFooter());
    this._confirmCallback = cb;

    const $modal = this._getPart().$context;
    this._showed = true;
    $modal.modal("show");
  }

  hide(): void {
    const $modal = this._getPart().$context;
    $modal.modal("hide");
    this._showed = false;
  }

  withTheme(theme: string): this {
    this._theme = theme;

    return this;
  }

  useLarge(on: boolean = true): this {
    this._useLarge = on;

    return this;
  }

  disableClose(on: boolean = true): this {
    this._disableClose = on;

    return this;
  }

  showed(): boolean {
    return this._showed;
  }

  private _clickButtonEvent(event: TrigEvent) {
    if (
      "undefined" !== typeof this._confirmCallback &&
      null !== this._confirmCallback
    ) {
      const $target = this._dom(event.currentTarget);
      const result = $target.data("confirm");
      this._confirmCallback(result);
      this._confirmCallback = null;
    }
  }

  private _fillCommonPart(
    parts: ModalPart,
    content: string | DOMBase,
    title?: string,
  ): void {
    const $modal = parts.$context.children(".modal-dialog");
    $modal.toggleClass("modal-lg", this._useLarge);
    this._useLarge = false;

    this.applyThemeClass(parts.$header);
    const $modalTitle = parts.$header.children(".modal-title");
    if ("undefined" !== typeof title || null === title) {
      $modalTitle.text(title);
    } else {
      this.applyThemeTitle($modalTitle);
    }

    const $closeButton = parts.$header.children("button.close");
    $closeButton.toggleClass("d-none", this._disableClose);
    this._disableClose = false;

    if ("string" === typeof content) {
      parts.$content.text(content);
    } else {
      parts.$content.empty();
      parts.$content.append(content);
    }
    this.applyThemeClass(parts.$footer);
    this._theme = Modal.T_INFO;
  }

  private applyThemeClass($el: DOMBase) {
    let themeClass;
    switch (this._theme) {
      case Modal.T_SUCCESS:
        themeClass = "alert-success";
        break;
      case Modal.T_ERROR:
        themeClass = "alert-danger";
        break;
      default:
        themeClass = "alert-info";
    }
    $el
      .removeClass(["alert-success", "alert-danger", "alert-info"])
      .addClass(themeClass);
  }

  private applyThemeTitle($el: DOMBase) {
    let themeTitle;
    switch (this._theme) {
      case Modal.T_SUCCESS:
        themeTitle = "Úspěch";
        break;
      case Modal.T_ERROR:
        themeTitle = "Chyba";
        break;
      default:
        themeTitle = "Informace";
    }
    $el.text(themeTitle);
  }

  private _getPart(): ModalPart {
    if ("undefined" === typeof this._parts || null === this._parts) {
      const $context = this._dom(
        Modal.TEMPLATE_MANAGER.apply({
          ID_MODAL: this._id,
        }),
      );
      this._dom("body").append($context);
      this._parts = new ModalPart();
      this._parts.$context = $context as unknown as JQueryModal;
      const $content = $context.find(".modal-content");
      this._parts.$header = $content.children(".modal-header");
      this._parts.$content = $content.children(".modal-body");
      this._parts.$footer = $content.children(".modal-footer");
      $content.on(
        "click",
        "button[data-confirm]",
        this._clickButtonEvent.bind(this),
      );
    }

    return this._parts;
  }

  private _getInfoFooter(): DOMBase {
    if ("undefined" === typeof this._infoFooter || null === this._infoFooter) {
      this._infoFooter = this._dom(
        `<button type="button" class="btn btn-secondary" data-dismiss="modal" data-target="#${this._id}" data-bs-dismiss="modal" data-bs-target="#${this._id}">Zavřít</button>`,
      );
    }

    return this._infoFooter;
  }

  private _getConfirmFooter(): DOMBase {
    if (
      "undefined" === typeof this._confirmFooter ||
      null === this._confirmFooter
    ) {
      this._confirmFooter = this._dom(`
        <button type="button" class="btn btn-secondary" data-confirm="yes" data-dismiss="modal" data-target="#${this._id}" data-bs-dismiss="modal" data-bs-target="#${this._id}">Ano</button>
        <button type="button" class="btn btn-secondary" data-confirm="no" data-dismiss="modal" data-target="#${this._id}" data-bs-dismiss="modal" data-bs-target="#${this._id}">Ne</button>
       `);
    }

    return this._confirmFooter;
  }
}
