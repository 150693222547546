export type RequestEvent<T> = CustomEvent<T>;

export type RequestEventDetail = {
  settings: {};
};

export type BeforeSendLoaderEvent = RequestEvent<RequestEventDetail>;
export type AfterSendLoaderEvent = RequestEvent<RequestEventDetail>;
export type ErrorSendLoaderEvent = RequestEvent<RequestEventDetail>;

export default class RequestEvents {
  static BeforeSendLoaderEvent = "before.send.loader";
  static AfterSendLoaderEvent = "after.send.loader";
  static ErrorSendLoaderEvent = "error.send.loader";
}
