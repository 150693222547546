/// <reference path="../global.d.ts"/>

import type Dispatcher from "../Event/Dispatcher";
import type { AddItemToCartEvent } from "./ControlEvents";

import type { Naja } from "naja/dist/Naja";
import ControlEvents from "./ControlEvents";

export default class AddButton {
  private _dom: DOMManipulator;
  private _dispatcher: Dispatcher;
  window: Window;
  _$body: DOMBase;

  constructor(
    dom: DOMManipulator,
    dispatcher: Dispatcher,
    window: Window,
    $body: DOMBase,
  ) {
    this._dom = dom;
    this._dispatcher = dispatcher;
    this.window = window;

    this._$body = $body;
  }

  getIconCart() {
    let $cart = this._dom("#snippet-header-cart-header-basket .basket-icon");
    let scrollTop = this._dom(this.window).scrollTop() as number;
    let $menu = this._dom("#navbar-main") as DOMBase;
    if (scrollTop > ($menu.offset()?.top ?? 0)) {
      var $tmp = $cart
        .clone()
        .wrapAll(
          '<div class="cloned-basket-container cart-element-cloned"></div>',
        )
        .parent()
        .css({
          position: "fixed",
          top: "60px",
          left: $cart.offset()?.left ?? 0,
        })
        .appendTo(this._$body);
      $cart = $tmp;
    }
    return $cart;
  }

  moveToCart($image: DOMBase) {
    if ($image) {
      let $cart = this.getIconCart();
      let $imgclone = $image
        .clone()
        .addClass("cart-element-cloned")
        .offset({
          top: $image.offset()?.top ?? 0,
          left: $image.offset()?.left ?? 0,
        })
        .css({
          opacity: "0.5",
          position: "absolute",
          width: $image.width() ?? "",
          height: $image.height() ?? "",
        })
        .appendTo(this._$body)
        .animate(
          {
            top: ($cart.offset()?.top ?? 0) + 20,
            left: ($cart.offset()?.left ?? 0) + 20,
            width: 75,
            height: 75,
          },
          1000,
          "linear",
        );
      $imgclone.animate(
        {
          width: 0,
          height: 0,
        },
        this._afterAnimateCallback.bind(this),
      );
    }
  }

  private _afterAnimateCallback(): void {
    this._dom(".cart-element-cloned").detach();
  }

  registerProductList(selector: string) {
    this._dom(".product-list").on("click", selector, (event) => {
      const $target = this._dom(event.currentTarget);
      const $img = $target
        .closest(".product")
        .find(".image-wrapper")
        .children("img")
        .eq(0);
      this.moveToCart($img);
    });
    return this;
  }

  registerProductDetail(selector: string) {
    this._dom(".pd-buy").on("click", selector, (event) => {
      const $target = this._dom(event.currentTarget);
      const $img = $target
        .closest(".pd-detail")
        .find(".pd-main-image")
        .find("img")
        .eq(0);
      this.moveToCart($img);
    });
    return this;
  }

  registerBuyForm(
    $context: JQueryExtends<Document>,
    naja: Naja,
    selector: string,
  ) {
    $context.on("submit", selector, (event) => {
      event.preventDefault();

      naja.uiHandler
        .submitForm(event.target, {
          unique: false,
        })
        .then((payload) => {
          this._dispatcher
            .createEvent(
              ControlEvents.AddItemToCartEvent,
              payload as AddItemToCartEvent,
            )
            .dispatch();
        });
    });

    return this;
  }

  static register(
    dom: DOMManipulator,
    naja: Naja,
    dispatcher: Dispatcher,
    window: Window,
    $body: DOMBase,
    $context: JQueryExtends<Document>,
  ) {
    const addButton = new AddButton(dom, dispatcher, window, $body);
    const selector = 'button[name="buy"]';
    addButton.registerProductList(selector);
    addButton.registerProductDetail(selector);
    addButton.registerBuyForm($context, naja, "form.buy-form");

    return addButton;
  }
}
