/// <reference path="../global.d.ts"/>

type JQueryCarousel = JQueryExtends;

export default class Carousel {
  private _dom: DOMManipulator;

  constructor(dom: DOMManipulator) {
    this._dom = dom;
  }

  buildCarousel(selector: string): void {
    const $elem = this._dom(selector) as unknown as JQueryCarousel;
    $elem.carousel({
      interval: 8000,
      pause: "hover",
      wrap: true,
    });
  }

  private _appendControl(selector: string): void {
    this._dom(selector).each((i, elem) => {
      const $elem = this._dom(elem);
      let $next = $elem.next();
      if (!$next.length) {
        $next = $elem.siblings(":first");
      }
      $next
        .children(".flex")
        .children(":first-child")
        .clone()
        .appendTo($elem.children(":first-child"));

      for (i = 0; i < 4; i++) {
        $next = $next.next();
        if (!$next.length) {
          $next = $elem.siblings(":first");
        }

        $next
          .children(".flex")
          .children(":first-child")
          .clone()
          .appendTo($elem.children(":first-child"));
      }
    });
  }

  private _hideControlIfNessery(selector: string): void {
    this._dom(selector)
      .filter((i, $elem) => {
        return 1 === this._dom($elem).children("div").length;
      })
      .siblings(
        ".carousel-control-prev, .carousel-control-next, .carousel-indicators",
      )
      .hide();
  }

  apply() {
    this._appendControl(".carousel-multi .carousel-item");
    this._hideControlIfNessery(".carousel-inner");
  }

  static register(dom: DOMManipulator): Carousel {
    const carousel = new Carousel(dom);
    carousel.buildCarousel("#brands-carousel");
    carousel.apply();

    return carousel;
  }
}
