export type ChangeBreakpointEventDetail = {
  width: number;
  breakpoint: string;
  up: (breakpoint: string) => boolean;
};
export type ChangeBreakpointEvent = CustomEvent<ChangeBreakpointEventDetail>;

export type UpdateWidthEventDetail = {
  width: number;
  up: (breakpoint: string) => boolean;
};
export type UpdateWidthEvent = CustomEvent<UpdateWidthEventDetail>;

export type UpdateFragmentEventDetail = Record<string, string>;
export type UpdateFragmentEvent = CustomEvent<UpdateFragmentEventDetail>;

export interface RawLocation {
  push(hash: string): RawLocation;
  hash(): string;
  queryPart(name: string): string;
}

export type PopstateFragmentEventDetail = {
  location: RawLocation;
};
export type PopstateFragmentEvent = CustomEvent<PopstateFragmentEventDetail>;

export default class UtilsEvents {
  static UpdateWidthEvent = "update.width";
  static ChangeBreakpointEvent = "change.breakpoint";
  static UpdateFragmentEvent = "update.fragment";
  static PopstateFragmentEvent = "popstate.fragment";
}
