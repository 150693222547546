/// <reference path="../global.d.ts"/>

import Modal from "../Control/Modal";

import type BuilderFormAjaxRequest from "../Utils/BuilderFormAjaxRequest";
import type GatewayPayment from "./GatewayPayment";

import { BaseGatewayPayment } from "./GatewayPayment";
import Helpers from "../Utils/Helpers";
import Template from "../Utils/Template";

import { _dump } from "../Utils/Dumps";

class ComgateEngine {
  static CONTAINER_TEMPLATE = new Template(`
    <iframe class="comgate-frame w-100" src="{src}" title="{title}"></iframe>
  `);

  private _injected = false;
  private _gatewayPayment: GatewayPayment | null = null;
  private _resolve: ((value: any) => void) | null = null;
  private _reject: ((reason: any) => void) | null = null;

  receiveMessage(data: any) {
    if (
      "action" in data &&
      "status" === data.action &&
      "scope" in data &&
      "comgate-to-eshop" === data.scope
    ) {
      if ("paid" === `${data.value.status}`.toLowerCase()) {
        this._resolve!(data);
      } else {
        this._reject!(data);
      }
    }
  }

  inject(helpers: Helpers, gatewayPayment: GatewayPayment): this {
    if (!this._injected) {
      helpers.addReceiver(this.receiveMessage.bind(this));
      this._gatewayPayment = gatewayPayment;
      this._injected = true;
    }

    return this;
  }

  checkout(url: string): Promise<any> {
    const title = "Comgate Platba";
    if (null !== this._gatewayPayment) {
      const $content = this._gatewayPayment.dom(
        ComgateEngine.CONTAINER_TEMPLATE.apply({
          src: url,
          title: title,
        }),
      );
      this._gatewayPayment.showInfo($content, Modal.T_INFO, title);
    }

    return new Promise((resolve, reject) => {
      this._resolve = (data) => {
        resolve(data);
      };
      this._reject = (reason) => {
        reject(reason);
      };
    });
  }
}

const comgateEngine = new ComgateEngine();

export class ComgateLite {
  private _clickButtonHandler: (event: TrigEvent) => void;
  constructor(
    private _dom: DOMManipulator,
    private _gatewayPayment: GatewayPayment,
    private _engine: ComgateEngine,
  ) {
    this._clickButtonHandler = this._clickButtonCallback.bind(this);
  }

  private _clickButtonCallback(event: TrigEvent) {
    event.preventDefault();
    const $form = this._dom(event.currentTarget).closest("form");
    const url: string = $form.attr("action") as string;
    const checkUrl: string = $form.data("check-url") as string;

    this._engine
      .checkout(url)
      .then(
        (data) => {
          this._gatewayPayment.showInfo("Platba zaplacena", Modal.T_SUCCESS);
        },
        (reason) => {
          this._gatewayPayment.showInfo(
            "Chyba na platební bráně",
            Modal.T_ERROR,
          );
        },
      )
      .finally(() => {
        return this._gatewayPayment.checkPayment(checkUrl);
      });
  }

  registerButton($button: DOMBase): this {
    $button
      .off("click", this._clickButtonHandler)
      .on("click", this._clickButtonHandler);
    return this;
  }

  static register(
    gatewayPayment: GatewayPayment,
    helpers: Helpers,
  ): ComgateLite | null {
    let comgateLite = null;
    const $form = gatewayPayment.dom(
      "#comgate-payment-button",
    ) as unknown as JQueryExtends;
    if ($form.exists()) {
      comgateLite = new ComgateLite(
        gatewayPayment.dom,
        gatewayPayment,
        comgateEngine,
      );
      const $button = $form.find("button");
      comgateLite.registerButton($button);
      comgateEngine.inject(helpers, gatewayPayment);
    }

    return comgateLite;
  }
}

export default class Comgate extends BaseGatewayPayment {
  constructor(
    $orderForm: JQueryExtends,
    formRequestBuilder: BuilderFormAjaxRequest,
    gatewayPayment: GatewayPayment,
    private _engine: ComgateEngine,
  ) {
    super($orderForm, formRequestBuilder, gatewayPayment);
  }

  protected _pay(event: TrigEvent): void {
    this._gatewayPayment.showInfo(
      "Otevírám platební bránu, po jejím otevření jí vyplňte",
      Modal.T_INFO,
    );

    this._formRequestBuilder
      .build(event)
      .then(this._createdPaymentCallback.bind(this))
      .catch(() => {
        this._gatewayPayment.showInfo(
          "Problém se založením platby, opakujte později",
          Modal.T_ERROR,
        );
      });
  }

  private _createdPaymentCallback(data: any): void {
    if (this._gatewayPayment.applyUrlRedirect(data, this._$orderForm)) {
      return;
    }

    if (!("errors" in data) || 0 === data.errors.length) {
      if ("payment" in data) {
        const payment = data.payment as {
          checkUrl: string;
          gatewayUrl: string;
        };
        this._checkUrl = payment.checkUrl;
        const gatewayUrl = payment.gatewayUrl;
        this._engine
          .checkout(gatewayUrl)
          .finally(() => {
            return this._gatewayPayment.checkPayment(this._checkUrl ?? "");
          })
          .then(this._successPayment.bind(this), (reason: any) => {
            this._gatewayPayment.urls.changeTo(gatewayUrl);
          });
      } else {
        this._gatewayPayment.showInfo(
          "Nevalidní data platební brány",
          Modal.T_ERROR,
        );
      }
    } else {
      this._gatewayPayment.showInfo([...data.errors].join(" "), Modal.T_ERROR);
    }
  }

  protected _successPayment(data: object): void {
    const errors = ("errors" in data ? data["errors"] : []) as string[];
    if (0 === errors.length) {
      this._isPaid = true;
      this._gatewayPayment.showInfo("Patba provedena", Modal.T_SUCCESS);
      this._formRequestBuilder.fireLastEvent();
    } else {
      this._gatewayPayment.showInfo([...errors].join(" "), Modal.T_ERROR);
    }
  }

  registerComgatePayment(): this {
    this._$orderForm.off("submit", this._submitPaymentHandler);
    this._$orderForm.on("submit", this._submitPaymentHandler);

    return this;
  }

  static register(
    $form: JQueryExtends,
    requestBuilder: BuilderFormAjaxRequest,
    gatewayPayment: GatewayPayment,
    helpers: Helpers,
  ): Comgate | null {
    let $gatewayPaymentForm = $form.filter('[data-payment="platba-comgate"]');
    if (!$gatewayPaymentForm.exists()) {
      return null;
    }

    const comgate = new Comgate(
      $gatewayPaymentForm,
      requestBuilder,
      gatewayPayment,
      comgateEngine,
    ).registerComgatePayment();

    comgateEngine.inject(helpers, gatewayPayment);

    return comgate;
  }
}
