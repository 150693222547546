/// <reference path="../../global.d.ts"/>

import type AFilter from "./AFilter";
import type State from "../../Utils/State";
import type Dispatcher from "../../Event/Dispatcher";
import type Fragment from "../../Utils/Fragment";
import type {
  DefaultStateEvent,
  UpdateStateEvent,
  MergeStateEvent,
} from "../../Utils/StateEvents";
import type { AfterSendLoaderEvent } from "../../Loader/RequestEvents";
import type { NumberFormat } from "../../Utils/Formats";

import "sticky-kit/dist/sticky-kit.js";
import FilterRange from "./FilterRange";
import FilterCheckable from "./FilterCheckable";
import FilterText from "./FilterText";
import Used from "./Used";
import KEYS from "./SelectionKeys";
import { numberFormat } from "../../Utils/Formats";
import { _dump } from "../../Utils/Dumps";

type JQuerySticky = JQueryExtends;
type JQueryCollapse = JQueryExtends;
interface Components extends Map<string, AFilter> {}

export default class Selection {
  private numberFormat: NumberFormat;
  private _components: Components;
  private _updateStateHandler: (event: UpdateStateEvent) => void;
  private _mergeStateHandler: (event: MergeStateEvent) => void;
  private _submitComponentsHandler: EventHandler;
  private _showFilterShowHandler: EventHandler | null;
  private _showFilterUpdateStateHandler:
    | ((event: UpdateStateEvent) => void)
    | null;
  private _fixedFilterShownHandler: EventHandler;
  private _fixedFilterAfterRequestHandler: (
    event: AfterSendLoaderEvent,
  ) => void;

  constructor(
    private _dom: DOMManipulator,
    private _state: State,
  ) {
    this._components = new Map();
    this.numberFormat = numberFormat({
      mark: ",",
      decimals: 0,
      thousand: " ",
    });
    this._updateStateHandler = this._updateStateCallback.bind(this);
    this._mergeStateHandler = this._mergeStateCallback.bind(this);
    this._submitComponentsHandler = (event: TrigEvent) => {
      event.stopImmediatePropagation();
      event.preventDefault();
    };
    this._showFilterShowHandler = null;
    this._showFilterUpdateStateHandler = null;
    this._fixedFilterShownHandler = (event: TrigEvent): void => {
      this._dom("body").trigger("sticky_kit:recalc");
    };
    this._fixedFilterAfterRequestHandler = (event: AfterSendLoaderEvent) => {
      this._dom("body").trigger("sticky_kit:recalc");
    };
  }

  registerDispatcher(dispatcher: Dispatcher): this {
    dispatcher.removeListener("update.state", this._updateStateHandler);
    dispatcher.addListener("update.state", this._updateStateHandler);
    dispatcher.removeListener("change.state", this._updateStateHandler);
    dispatcher.addListener("change.state", this._updateStateHandler);
    dispatcher.removeListener("merge.state", this._mergeStateHandler);
    dispatcher.addListener("merge.state", this._mergeStateHandler);

    return this;
  }

  private _updateStateCallback(event: DefaultStateEvent): void {
    this.updateState(event.detail.update);
  }

  private _mergeStateCallback(event: MergeStateEvent): void {
    const state = event.detail.update;
    if (!(KEYS.PAGE in state)) {
      state[KEYS.PAGE] = "1";
    }
    if (!(KEYS.MAX_RESULTS in state)) {
      state[KEYS.MAX_RESULTS] = event.detail.state.getValue(
        KEYS.ITEMS_PER_PAGE,
      );
    }
  }

  updateState(state: object): this {
    _dump("(selection) update state", state);
    for (const [k, v] of Object.entries(state)) {
      const component = this._components.get(k);
      if (component) {
        component.setState(v);
      }
    }

    return this;
  }

  getComponents(): Components {
    return this._components;
  }

  createComponent($elem: JQueryExtends): AFilter {
    let component: AFilter | null = null;
    if ($elem.is(".range")) {
      component = new FilterRange(this._dom, $elem, this.numberFormat);
    } else if ($elem.is(".check")) {
      component = new FilterCheckable(this._dom, $elem);
    } else if ($elem.is(".text")) {
      component = new FilterText(this._dom, $elem);
    } else {
      _dump("Unknow filter: ", $elem);
    }

    if (null === component) {
      throw new Error("uknow type of filter");
    }

    component.registerVisitor(this._state);
    return component;
  }

  cleanComponent(name: string): void {
    const component = this._components.get(name);
    if (component) {
      this._state.notify({
        [name]: null,
      });
    } else {
      throw new Error("Unregistred component");
    }
  }

  cleanAll(): this {
    const state: Record<string, string | null> = {};
    for (const [name, component] of this._components) {
      if (component.isUsed()) {
        state[name] = null;
      }
    }
    this._state.notify(state);

    return this;
  }

  registerComponents($container: DOMBase, selector: string): Components {
    $container
      .find("form")
      .addBack()
      .on("submit", this._submitComponentsHandler);
    $container.find(selector).each((index: number, elem: HTMLElement) => {
      const $elem = this._dom(elem) as unknown as JQueryExtends;
      const component = this.createComponent($elem);
      if (component) {
        this._components.set(component.getAttribute(), component);
      }
    });

    return this._components;
  }

  registerFragment(fragment: Fragment): this {
    this._components.forEach((component, name) => {
      fragment.addToFragmentMap(component.getAttribute(), component.getTitle());
    });
    fragment.addToFragmentMap(KEYS.SHOW_FILTER, "zobrazit-filtraci");

    return this;
  }

  registeUsed($container: DOMBase, dispatcher: Dispatcher): Used {
    let used = new Used(this._dom, this, $container);
    used.registerDispatcher(dispatcher);

    return used;
  }

  registerShowFilter($container: DOMBase, dispatcher: Dispatcher) {
    const $o = $container as unknown as JQueryCollapse;

    if (null !== this._showFilterShowHandler) {
      $container.off(
        "show.bs.collapse hide.bs.collapse",
        this._showFilterShowHandler,
      );
    }

    this._showFilterShowHandler = (event: TrigEvent) => {
      if ($o.is(event.target)) {
        this._state.mergeState({
          [KEYS.SHOW_FILTER]: event.type === "show" ? "ano" : null,
        });
      }
    };
    $container.on(
      "show.bs.collapse hide.bs.collapse",
      this._showFilterShowHandler,
    );

    if (null !== this._showFilterUpdateStateHandler) {
      dispatcher.removeListener(
        "update.state",
        this._showFilterUpdateStateHandler,
      );
    }

    this._showFilterUpdateStateHandler = (event: UpdateStateEvent) => {
      if (this._state.getValue(KEYS.SHOW_FILTER)) {
        $o.collapse("show");
      }
    };
    dispatcher.addListener("update.state", this._showFilterUpdateStateHandler);

    return $container;
  }

  registerFixedFilter($container: DOMBase, dispatcher: Dispatcher): DOMBase {
    let $context = $container.closest(".sidebar-group");

    $container.off(
      "shown.bs.collapse hidden.bs.collapse",
      ".filter-dropdown",
      this._fixedFilterShownHandler,
    );
    $container.on(
      "shown.bs.collapse hidden.bs.collapse",
      ".filter-dropdown",
      this._fixedFilterShownHandler,
    );

    const sidebar = ($context as unknown as JQuerySticky).stick_in_parent();

    dispatcher.removeListener(
      "after.send.loader",
      this._fixedFilterAfterRequestHandler,
    );
    dispatcher.addListener(
      "after.send.loader",
      this._fixedFilterAfterRequestHandler,
    );

    return sidebar;
  }

  static register(
    dom: DOMManipulator,
    dispatcher: Dispatcher,
    $filter: DOMBase,
    $used: DOMBase,
    state: State,
    fragment: Fragment,
  ) {
    let selection = new Selection(dom, state);
    selection.registerDispatcher(dispatcher);
    selection.registerComponents($filter, ".filter-dropdown");
    selection.registerFragment(fragment);
    selection.registerShowFilter($filter, dispatcher);
    selection.registeUsed($used, dispatcher);

    return selection;
  }
}
