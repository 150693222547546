/// <reference path="../global.d.ts"/>
import Dispatcher from "../Event/Dispatcher";
import type {
  ChangeBreakpointEventDetail,
  UpdateWidthEventDetail,
} from "./UtilsEvents";

import UtilsEvents from "./UtilsEvents";

export default class Breakpoints {
  public static LowerBreakpoint = "";

  private _upHandler: (breakpoint: string) => boolean;
  private _lastWidth: number;
  private _lastBreakpoint: string;

  constructor(
    private _bp: Record<string, number>,
    private _dispatcher: Dispatcher,
  ) {
    this._lastWidth = 0;
    this._lastBreakpoint = "unknow";
    this._upHandler = this.up.bind(this);
  }

  getBoundWidth(name: string): number {
    if (name === Breakpoints.LowerBreakpoint) {
      return 0;
    }

    if (name in this._bp) {
      return this._bp[name];
    }

    throw new Error("Unknow breakpoints");
  }

  getLastWidth(): number {
    return this._lastWidth;
  }

  getLastBreakpoint(): string {
    return this._lastBreakpoint;
  }

  getDispatcher(): Dispatcher {
    return this._dispatcher;
  }

  private _update(currentWidth: number): void {
    if (currentWidth !== this._lastWidth) {
      this._lastWidth = currentWidth;
      const currentBreakpoint = this._selectBreakpoint();
      if (currentBreakpoint !== this._lastBreakpoint) {
        this._lastBreakpoint = currentBreakpoint;
        this._dispatcher
          .createEvent(UtilsEvents.ChangeBreakpointEvent, {
            width: this._lastWidth,
            breakpoint: this._lastBreakpoint,
            up: this._upHandler,
          } as ChangeBreakpointEventDetail)
          .dispatch();
      }
      this._dispatcher
        .createEvent(UtilsEvents.ChangeBreakpointEvent, {
          width: this._lastWidth,
          up: this._upHandler,
        } as UpdateWidthEventDetail)
        .dispatch();
    }
  }

  up(breakpoint: string): boolean {
    return this.getBoundWidth(breakpoint) <= this._lastWidth;
  }

  private _selectBreakpoint(): string {
    const breakpoints = Object.entries(this._bp).sort(function (l, r): number {
      return l[1] - r[1];
    });

    let breakpoint: [string, number] = [Breakpoints.LowerBreakpoint, 0];
    const maxIndexBreakpoints = breakpoints.length - 1;
    for (let i = 0; i <= maxIndexBreakpoints; ++i) {
      if (
        breakpoint[1] <= this._lastWidth &&
        breakpoints[i][1] > this._lastWidth
      ) {
        return breakpoint[0];
      }
      breakpoint = breakpoints[i];
    }

    return breakpoints[maxIndexBreakpoints][0];
  }

  registerWindow($window: DOMBase<Window>): this {
    $window.on("resize", (event: TrigEvent) => {
      this._triggerUpdate($window);
    });

    return this._triggerUpdate($window);
  }

  _triggerUpdate($window: DOMBase<Window>): this {
    this._update($window.width() ?? 0);

    return this;
  }

  static register(
    bps: Record<string, number>,
    dispatcher: Dispatcher,
  ): Breakpoints {
    const breakponts = new Breakpoints(bps, dispatcher);

    return breakponts;
  }
}
