export default class WebUI {
  private _dom: DOMManipulator;

  constructor(dom: DOMManipulator) {
    this._dom = dom;
  }

  withFragment(): this {
    this.useFragment(true);

    return this;
  }

  withoutFragment(): this {
    this.useFragment(false);

    return this;
  }

  useFragment(on: boolean): this{
    this._dom("body").toggleClass('fragment', on);
    return this;
  }

  startLoading(): this {
    this.useLoading(true);

    return this;
  }

  stopLoading(): this {
    this.useLoading(false);
    return this;
  }

  useLoading(on: boolean): this{
    this._dom("body").toggleClass("loading", on);
    return this;
  }
}
