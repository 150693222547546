type ReadyCallable = (d: Document) => void;

export default class DOMMan {
  static showDisplayMap: Record<string, string> = {
    TABLE: "table",
  };

  private __match: ((query: string) => boolean) | null;
  private _w: WindowLoader;

  constructor(w: Window) {
    this.__match = null;
    this._w = w as WindowLoader;
    (w as WindowsStorageAny)["domMan"] = this;
  }

  isEmpty(context: any | null): context is null | undefined {
    return null === context || "undefined" === typeof context;
  }

  addClass($element: HTMLElement | null, name: string): this {
    if (!$element || this.isEmpty($element)) {
      return this;
    }
    $element.classList.add(name);

    return this;
  }

  removeClass($element: HTMLElement | null, name: string): this {
    if (!$element || this.isEmpty($element)) {
      return this;
    }
    $element.classList.remove(name);

    return this;
  }

  toogleClass($element: HTMLElement | null, name: string): this {
    if (!$element || this.isEmpty($element)) {
      return this;
    }
    $element.classList.toggle(name);

    return this;
  }

  containsClass($element: HTMLElement | null, name: string): boolean {
    if (!$element) {
      return false;
    }
    if ($element.classList) {
      return $element.classList.contains(name);
    } else {
      return new RegExp("(^| )" + name + "( |$)", "gi").test(
        $element.className,
      );
    }
  }

  hide($element: HTMLElement | null): this {
    if (this.isEmpty($element)) {
      return this;
    }

    $element.style.display = "none";

    return this;
  }

  unhide($element: HTMLElement | null): this {
    if (this.isEmpty($element)) {
      return this;
    }

    const display = $element.style?.display;
    if ("none" === display) {
      ($element.style.display as string | null) = null;
    }

    return this;
  }

  show($element: HTMLElement | null, display: string | null = null): this {
    if (this.isEmpty($element)) {
      return this;
    }

    if (!display) {
      try {
        display = $element.dataset["hideShow"] ?? null;
      } catch {}
    }
    if (!display) {
      try {
        display = DOMMan.showDisplayMap[$element.tagName];
      } catch {}
    }

    $element.style.display = display ?? "block";

    return this;
  }

  visible($element: HTMLElement): boolean {
    return $element.offsetHeight > 0 || $element.offsetWidth > 0;
  }

  style($element: HTMLElement): CSSStyleDeclaration {
    return getComputedStyle($element);
  }

  build(template: string): HTMLElement {
    const element = this._w.document.createElement("template");
    element.innerHTML = template.trim();

    return element.content.firstElementChild as HTMLElement;
  }

  get(id: string): Element {
    const $el = this._w.document.getElementById(id);
    if (!$el) {
      throw new Error("Element not found");
    }
    return $el;
  }

  getHE(id: string): HTMLElement {
    return this.get(id) as HTMLElement;
  }

  find(query: string, $context?: HTMLElement | null): Element[] {
    if (!$context || this.isEmpty($context)) {
      $context = this._w.document.body;
    }

    return Array.from($context.querySelectorAll(query));
  }

  first(query: string, $context?: HTMLElement | null): Element {
    if (!$context || this.isEmpty($context)) {
      $context = this._w.document.body;
    }

    const $el = $context.querySelector(query);
    if (!$el) {
      throw new Error("Element not found");
    }

    return $el;
  }

  closest(query: string, $context?: HTMLElement | null): HTMLElement {
    if (this.isEmpty($context)) {
      $context = this._w.document.body;
    }

    const $el = $context.closest(query) as HTMLElement;
    if (!$el) {
      throw new Error("Element not found");
    }

    return $el;
  }

  prev(query: string, $context: HTMLElement): HTMLElement | null {
    let $tmp = $context.previousSibling;
    if (!this.isEmpty(query)) {
      while (null !== $tmp && !this.matches($tmp, query)) {
        $tmp = $tmp.previousSibling;
      }
    }

    return $tmp as HTMLElement;
  }

  next(query: string, $context: HTMLElement): HTMLElement | null {
    let $tmp = $context.nextSibling;
    if (!this.isEmpty(query)) {
      while (null !== $tmp && !this.matches($tmp, query)) {
        $tmp = $tmp.nextSibling;
      }
    }

    return $tmp as HTMLElement;
  }

  parent($context?: HTMLElement | null): HTMLElement | null {
    if (this.isEmpty($context)) {
      return null;
    }

    return $context.parentElement;
  }

  children(query: string, $context?: HTMLElement | null): Element[] {
    if (this.isEmpty($context)) {
      $context = this._w.document.body;
    }

    let children = Array.from($context.childNodes) as Element[];
    if (query) {
      children = children.filter((v, i): boolean => {
        return this.matches(v, query);
      });
    }

    return children;
  }

  append($el: Element, $context?: HTMLElement | null): this {
    if (this.isEmpty($context)) {
      $context = this._w.document.body;
    }

    $context.appendChild($el);

    return this;
  }

  remove($el?: HTMLElement): this {
    if (!$el) {
      return this;
    }
    $el.parentNode?.removeChild($el);

    return this;
  }

  clear($el?: HTMLElement): this {
    if (!$el) {
      return this;
    }

    const $children = $el.childNodes;
    for (let i = $children.length - 1; 0 <= i; i--) {
      $el.removeChild($children[i]);
    }

    return this;
  }

  on(
    event: string,
    callback: EventListenerOrEventListenerObject,
    $context?: EventTarget,
  ): this {
    if (this.isEmpty($context)) {
      $context = this._w.document;
    }
    $context.addEventListener(event, callback);

    return this;
  }

  off(
    event: string,
    callback: EventListenerOrEventListenerObject,
    $context?: EventTarget,
  ): this {
    if (this.isEmpty($context)) {
      $context = this._w.document;
    }
    $context.removeEventListener(event, callback);

    return this;
  }

  delegate(
    event: string,
    selector: string,
    handler: (el: HTMLElement, ev: Event) => void,
    $context?: EventTarget,
  ): EventListener {
    if (this.isEmpty($context)) {
      $context = this._w.document;
    }
    const listener = (event: Event) => {
      const $source = (event.target as Element).closest(selector);
      if (!this.isEmpty($source)) {
        handler.call($source, $source as HTMLElement, event);
      }
    };
    $context.addEventListener(event, listener, false);

    return listener;
  }

  trigger<T>(
    name: string,
    detail: T,
    dispatcher?: EventTarget,
  ): CustomEvent<T> {
    if (this.isEmpty(dispatcher)) {
      dispatcher = this._w.document;
    }
    const event = new CustomEvent(name, {
      detail,
    });

    dispatcher.dispatchEvent(event);

    return event;
  }

  matches(el: Node | any, selector: string): boolean {
    if (this.isEmpty(this.__match)) {
      this.__match =
        el.matches ||
        el.matchesSelector ||
        el.msMatchesSelector ||
        el.mozMatchesSelector ||
        el.webkitMatchesSelector ||
        el.oMatchesSelector ||
        function (s: string) {
          return false;
        };
    }
    const matcher = this.__match as (query: string) => boolean;

    try {
      return matcher.call(el, selector) as boolean;
    } catch {
      return false;
    }
  }

  selfOrClosest($el: HTMLElement, selector: string): Element {
    if (!this.matches($el, selector)) {
      $el = this.closest(selector, $el);
    }

    return $el;
  }

  ready(cb: ReadyCallable): this {
    this._w.arival.loader.run(
      ["loaded-document", "loaded-main-script"],
      function (containers, d) {
        cb(d);
      },
    );

    return this;
  }
}
