import DefaultState from "../../Utils/DefaultState";
import KEYS from "./SelectionKeys";

export default class ListDefaultState extends DefaultState {
  static ITEM_PER_PAGE = 12;
  static MAX_PAGE = "maxPage";
  static MIN_PAGE = "minPage";

  getStateOrDefault(name: string): string {
    let result: string;
    if (KEYS.PAGE === name) {
      result = this._parsePage();
    } else {
      result = super.getStateOrDefault(name);
    }
    return result;
  }

  private _parsePage(): string {
    let page = super.getStateOrDefault(KEYS.PAGE);
    let tmp = page.split("-");
    let max = super.getDefault(ListDefaultState.MAX_PAGE);
    let min = super.getDefault(ListDefaultState.MIN_PAGE);
    if (tmp[1]) {
      page = `${Math.max(min, tmp[0])}-${Math.min(max, tmp[1])}`;
    } else {
      page = `${Math.min(Math.max(min, +page), max)}`;
    }
    return page;
  }
}
