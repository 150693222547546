/// <reference path="../global.d.ts"/>

import type { Naja, Options, CompleteEvent } from "naja/dist/Naja";
import type { BeforeUpdateEvent } from "naja/dist/core/SnippetHandler";
import type { SnippetLoaderSettins } from "./ASnippetLoader";
import type SnippetHandler from "../Utils/SnippetHandler";
import type Dispatcher from "../Event/Dispatcher";

import ASnippetLoader from "./ASnippetLoader";
import { _dump } from "../Utils/Dumps";

type NajaLoaderOptions = Options & {
  settings: SnippetLoaderSettins;
};

export default class NajaSnippetLoader<
  S extends SnippetLoaderSettins,
> extends ASnippetLoader<S> {
  private _http: Naja;
  private _allowedSnippetIds: Set<string>;

  constructor(
    dispatcher: Dispatcher,
    http: Naja,
    snippetHandler: SnippetHandler,
  ) {
    super(dispatcher);
    this._http = http;
    this._allowedSnippetIds = new Set();
    snippetHandler
      .for((event: BeforeUpdateEvent): boolean => {
        const detail = event.detail;
        const snippet = detail.snippet;
        if (!this._allowedSnippetIds.has(snippet.id)) {
          return false;
        }

        const settings = (detail.options as NajaLoaderOptions).settings;
        if (settings.appendSnippets?.includes(snippet.id)) {
          detail.changeOperation(this._http.snippetHandler.op.append);
        }

        return true;
      })
      .then((e: CompleteEvent) => {
        const options = e.detail.options as NajaLoaderOptions;
        _dump("(naja loader) complete", options);
        const settings = options.settings as S;
        this._afterLoad(settings, e.detail.payload ?? {});
      })
      .catch((e: CompleteEvent) => {
        const options = e.detail.options as NajaLoaderOptions;
        _dump("(naja loader) error", options);
        const settings = options.settings as S;
        this._onError(settings, e.detail.error ?? {});
      });
  }

  allowSnippet(snippetId: string): this {
    this._allowedSnippetIds.add(snippetId);

    return this;
  }

  protected _send(settings: S): void {
    const options: NajaLoaderOptions = {
      abort: false,
      unique: false,
      history: false,
      snippetCache: false,
      settings,
    };

    this._http.makeRequest("get", this._lastRequestUri ?? "", null, options);
  }
}
