import type { Listener, NativeListener } from "./Dispatcher";

import Dispatcher from "./Dispatcher";
import Box from "./Box";

export default class DefaultDispatcher implements Dispatcher {
  constructor(
    private postfixEvent: string,
    private _inner: EventTarget,
  ) {}

  private decorateEventName(eventName: string): string {
    return `${eventName}.${this.postfixEvent}`;
  }

  refreshListener(eventName: string, cb: Listener): this {
    eventName = this.decorateEventName(eventName);
    this._inner.removeEventListener(eventName, cb as NativeListener);
    this._inner.addEventListener(eventName, cb as NativeListener);

    return this;
  }

  addListener(eventName: string, cb: Listener): this {
    eventName = this.decorateEventName(eventName);
    this._inner.addEventListener(eventName, cb as NativeListener);

    return this;
  }

  removeListener(eventName: string, cb: Listener): this {
    eventName = this.decorateEventName(eventName);
    this._inner.removeEventListener(eventName, cb as NativeListener);

    return this;
  }

  createEvent<T>(eventName: string, detail: T): Box<T> {
    eventName = this.decorateEventName(eventName);
    return new Box(
      new CustomEvent(eventName, {
        bubbles: false,
        detail: detail,
      }),
      this,
    );
  }

  dispatch(event: CustomEvent | Box<any>): this {
    if (event instanceof Box) {
      event.dispatch(this);
    } else {
      this._inner.dispatchEvent(event);
    }

    return this;
  }
}
