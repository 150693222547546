/// <reference path="../global.d.ts"/>

import type SnippetHandler from "../Utils/SnippetHandler";
import type { BeforeUpdateEvent } from "../Utils/SnippetHandler";
import { _dump } from "./Dumps";

export default class Helpers {
  static _instance: Helpers;
  private _dom: DOMManipulator;
  private _window: WindowsStorageAny;
  private _receivers: Function[];
  private _closeByClassHandler: EventHandler | null;
  private _printByClassHandler: EventHandler | null;
  private _reclickEventHandler: EventHandler | null;
  private _copyHandler: EventHandler | null;

  constructor(dom: DOMManipulator, window: Window) {
    this._dom = dom;
    this._window = window;
    this._receivers = [];
    this._closeByClassHandler = null;
    this._printByClassHandler = null;
    this._reclickEventHandler = null;
    this._copyHandler = null;
  }

  isMobile(): boolean {
    return /iphone|ipad|ipod|android|webos|blackberry|windows phone/i.test(
      this._window.navigator.userAgent || this._window.navigator.vendor,
    );
  }

  closeCurrentWindow(): this {
    let timer = setTimeout(() => {
      clearTimeout(timer);
      if (!this._window["persistWindowByClose"] as boolean) {
        this._window.close();
      }
    });

    return this;
  }

  printCurrentWindow(): this {
    let timer = setTimeout(() => {
      clearTimeout(timer);
      this._window.print();
    });

    return this;
  }

  sendMessage(window: Window, message: Object): this {
    window.postMessage(message, "/");

    return this;
  }

  reloadOpener(): this {
    this.sendMessage(this._window.opener, {
      type: "reload",
    });

    return this;
  }

  registerMobileNumber(selector: string): this {
    if (!this.isMobile()) {
      return this;
    }
    this._dom(selector).each((i, e) => {
      const input = e as HTMLInputElement;
      if ("number" !== input.type) {
        input.type = "number";
      }
    });
    return this;
  }

  registerCloseByClass(selector: string): this {
    this._closeByClassHandler = (event: TrigEvent) => {
      this.closeCurrentWindow();
      event.preventDefault();
    };
    this._dom(selector).on("click", this._closeByClassHandler);

    return this;
  }

  unregisterCloseByClass(selector: string): this {
    if (null !== this._closeByClassHandler) {
      this._dom(selector).off("click", this._closeByClassHandler);
      this._closeByClassHandler = null;
    }

    return this;
  }

  registerCloseByID(selector: string): this {
    const $elements = this._dom(selector) as unknown as JQueryExtends;
    if ($elements.exists()) {
      this.closeCurrentWindow();
    }

    return this;
  }

  registerPrintByClass(selector: string): this {
    this._printByClassHandler = (event: TrigEvent) => {
      this.printCurrentWindow();
      event.preventDefault();
    };
    this._dom(selector).on("click", this._printByClassHandler);

    return this;
  }

  registerCopy(selector: string): this {
    this._copyHandler = async (event: TrigEvent): Promise<boolean> => {
      event.preventDefault();
      const sourceSelector = (event.currentTarget as HTMLElement).dataset[
        "clipboardCopySource"
      ];
      if ("undefined" === typeof sourceSelector || null === sourceSelector) {
        return true;
      }

      const source = this._dom(sourceSelector).text().trim();

      return await (
        this._window.navigator.clipboard?.writeText(source) ??
        this._fallbackCopy(source)
      ).then(
        function () {
          return false;
        },
        function () {
          return true;
        },
      );
    };
    this._dom(this._window.document).on("click", selector, this._copyHandler);

    return this;
  }

  private _fallbackCopy(source: string): Promise<boolean> {
    const d = this._window.document as Document;
    var ta = d.createElement("textarea");
    ta.innerText = source;

    d.body.appendChild(ta);
    ta.focus();
    ta.select();

    try {
      d.execCommand("copy");
      return Promise.resolve(true);
    } catch {
      return Promise.reject('execute command "copy" not support');
    }
  }

  unregisterPrintByClass(selector: string): this {
    if (null !== this._printByClassHandler) {
      this._dom(selector).off("click", this._printByClassHandler);
      this._printByClassHandler = null;
    }

    return this;
  }

  clickOn(selector: string): void {
    this._dom(selector).trigger("click");
  }

  registerReclick(selector: string): this {
    this._reclickEventHandler = (event: TrigEvent) => {
      const dataset = event.currentTarget.dataset as {
        clickButton: string | number;
      };
      this.clickOn(dataset.clickButton as string);
      event.preventDefault();
    };
    this._dom(selector).on("click", this._reclickEventHandler);

    return this;
  }

  unregisterReclick(selector: string): this {
    if (null !== this._reclickEventHandler) {
      this._dom(selector).off("click", this._reclickEventHandler);
      this._reclickEventHandler = null;
    }

    return this;
  }

  registerPrintByID(selector: string): this {
    const $elements = this._dom(selector) as unknown as JQueryExtends;
    if ($elements.exists()) {
      this.printCurrentWindow();
    }

    return this;
  }

  registerReloadOpenerBeforeUnloadByID(selector: string): this {
    const $elements = this._dom(selector) as unknown as JQueryExtends;
    if ($elements.exists()) {
      this._dom(this._window).on("unload", (event: TrigEvent) => {
        this.reloadOpener();
      });
    }

    return this;
  }

  registerReceiveMessage(): this {
    this._dom(this._window).on(
      "message onmessage",
      (event: TrigEvent): void => {
        const data = (event.originalEvent as MessageEvent).data;
        if ("object" === typeof data) {
          for (var cb of this._receivers) {
            cb(data);
          }
        }
      },
    );

    return this;
  }

  registerReloadMessage(): this {
    this.addReceiver((data: any) => {
      if ("type" in data && "reload" === data["type"]) {
        this._window.location.reload();
      }
    });

    return this;
  }

  addReceiverOne(cb: Function): this {
    const proxy = (data: any) => {
      cb(data);
      this.removeReceiver(proxy);
    };
    this._receivers.push(proxy);

    return this;
  }

  addReceiver(cb: Function): this {
    this._receivers.push(cb);

    return this;
  }

  removeReceiver(cb: Function): this {
    const index = this._receivers.indexOf(cb);
    if (0 >= index) {
      this._receivers.splice(index, 1);
    }

    return this;
  }

  static register(dom: DOMManipulator, window: Window): Helpers {
    const helpers = new Helpers(dom, window);
    Helpers._instance = helpers;

    helpers
      .registerCloseByClass(".close-current-window")
      .registerCloseByID("#closeWindow")
      .registerPrintByClass(".print-current-window, .action-print")
      .registerPrintByID("#printWindow")
      .registerMobileNumber("input.mobile-number")
      .registerReloadOpenerBeforeUnloadByID("#reloadOpener")
      .registerCopy("[data-clipboard-copy-source]")
      .registerReceiveMessage()
      .registerReloadMessage();

    return helpers;
  }

  static registerSnippetHandler(
    dom: DOMManipulator,
    window: Window,
    snippetHandler: SnippetHandler,
  ): Helpers {
    const helpers = new Helpers(dom, window);
    Helpers._instance = helpers;

    helpers
      .registerReloadOpenerBeforeUnloadByID("#reloadOpener")
      .registerReceiveMessage()
      .registerReloadMessage();

    snippetHandler.always().then(function (e) {
      const payload = e.detail.payload ?? {};
      if (
        "closeCurrentWindow" in payload &&
        (payload.closeCurrentWindow as boolean)
      ) {
        helpers.closeCurrentWindow();
      }
      if (
        "printCurrentWindow" in payload &&
        (payload.printCurrentWindow as boolean)
      ) {
        helpers.printCurrentWindow();
      }
    });

    snippetHandler
      .always(function (e: BeforeUpdateEvent) {
        helpers
          .unregisterCloseByClass(".close-current-window")
          .unregisterPrintByClass(".print-current-window, .action-print")
          .unregisterReclick("[data-click-button]");
      })
      .thenInit(function () {
        helpers
          .registerCloseByClass(".close-current-window")
          .registerCloseByID("#closeWindow")
          .registerPrintByClass(".print-current-window, .action-print")
          .registerPrintByID("#printWindow")
          .registerReclick("[data-click-button]")
          .registerMobileNumber("input.mobile-number")
          .registerCopy("[data-clipboard-copy-source]");
      });

    return helpers;
  }

  static get(): Helpers {
    if (!Helpers._instance) {
      throw new Error("Class must by registred");
    }

    return Helpers._instance;
  }
}
