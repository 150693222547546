/// <reference path="../global.d.ts"/>

import Template from "../Utils/Template";

export default class EmailShield {
  static TEMPLATE_LINK: Template = new Template(
    `<a href="mailto:{email}" class="{class}"></a>`
  );
  private _dom: DOMManipulator;

  constructor(dom: DOMManipulator) {
    this._dom = dom;
  }

  demaskEmail($input: DOMBaseAny) {
    var wrap = EmailShield.TEMPLATE_LINK.apply({
      email: $input.html().trim().split("").reverse().join(""),
      class: $input.attr("class") as string,
    });
    $input.removeClass();
    $input.wrap(wrap);
  }

  apply(selector: string): void {
    this._dom(selector).each((i, el) => {
      const $input = this._dom(el);
      this.demaskEmail($input);
    });
  }

  static register(dom: DOMManipulator): EmailShield {
    const spam = new EmailShield(dom);
    spam.apply(".email-shield");

    return spam;
  }
}
