/// <reference path="../global.d.ts"/>
import type { Naja } from "naja/dist/Naja";

import BuilderFormAjaxRequest from "./BuilderFormAjaxRequest";

export default class BuilderFactory {
  constructor(
    private _dom: DOMManipulator,
    private _naja: Naja,
  ) {}

  modifier(): DOMManipulator {
    return this._dom;
  }

  http(): Naja {
    return this._naja;
  }

  create(): BuilderFormAjaxRequest {
    return new BuilderFormAjaxRequest(this._dom, this._naja);
  }

  static register(dom: DOMManipulator, naja: Naja): BuilderFactory {
    return new BuilderFactory(dom, naja);
  }
}
