/// <reference path="../global.d.ts"/>

import type { Listener } from "./Dispatcher";

import Dispatcher from "./Dispatcher";
import Box from "./Box";
import DefaultDispatcher from "./DefaultDispatcher";

export default class LazyDispatcher implements Dispatcher {
  private _dispatcher: Dispatcher | null;

  constructor(
    private _postfixEvent: string,
    private _dom: DOMManipulator,
    private _selector: string,
  ) {
    this._dispatcher = null;
  }

  private _getDispatcher(): Dispatcher {
    if (null === this._dispatcher) {
      const inner = this._dom(this._selector).get(0) as EventTarget;
      this._dispatcher = new DefaultDispatcher(this._postfixEvent, inner);
    }

    return this._dispatcher;
  }

  refreshListener(eventName: string, cb: Listener): this {
    this._getDispatcher().refreshListener(eventName, cb);

    return this;
  }

  addListener(eventName: string, cb: Listener): this {
    this._getDispatcher().addListener(eventName, cb);

    return this;
  }

  removeListener(eventName: string, cb: Listener): this {
    this._getDispatcher().removeListener(eventName, cb);

    return this;
  }

  createEvent<T>(eventName: string, detail: T): Box<T> {
    return this._getDispatcher().createEvent(eventName, detail);
  }

  dispatch(event: CustomEvent | Box<any>): this {
    this._getDispatcher().dispatch(event);

    return this;
  }
}
