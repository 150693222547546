import type Dispatcher from "./Dispatcher";

export default class Box<T> {
  constructor(
    public event: CustomEvent<T>,
    private dispatcher: Dispatcher,
  ) {}

  dispatch(dispatcher: Dispatcher | null = null): void {
    dispatcher = dispatcher ?? this.dispatcher;
    dispatcher.dispatch(this.event);
  }
}
