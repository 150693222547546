/// <reference path="../global.d.ts"/>

import type Dispatcher from "../Event/Dispatcher";
import type {
  RawLocation,
  PopstateFragmentEventDetail,
  UpdateFragmentEventDetail,
} from "./UtilsEvents";

import { Nette } from "live-form-validation";
import { _dump } from "./Dumps";
import UtilsEvents from "./UtilsEvents";

class VoidLocation implements RawLocation {
  push(hash: string): RawLocation {
    return this;
  }

  hash(): string {
    return "";
  }

  queryPart(name: unknown): string {
    return "";
  }
}

class LocationLocation implements RawLocation {
  constructor(private _location: Location) {}

  push(hash: string): RawLocation {
    if (history.pushState) {
      history.pushState(null, "", `#${hash}`);
    } else {
      this._location.hash = hash;
    }

    return this;
  }

  hash(): string {
    return this._location.hash.substring(1) ?? "";
  }

  queryPart(name: string): string {
    const matches = this._location.search.match(new RegExp(`${name}=([^#&]+)`));
    if (!matches || matches.length < 2) {
      throw new Error("Empty query part");
    }

    return matches[1] as string;
  }
}

class FragmentPart {
  constructor(
    public key: string,
    public value: string,
  ) {}
}

export default class Fragment {
  static QUERY_PARTS = ["page"];
  private _hash: string;
  private _delimiter: string;
  private _assignSymbol: string;
  private _state: Record<string, string> | null;
  private _fragmentMap: Map<string, string>;
  private _rawLocation: RawLocation;

  constructor(
    private utils: Utils,
    private dispatcher: Dispatcher,
  ) {
    this._hash = "";
    this._delimiter = "&";
    this._assignSymbol = "=";
    this._state = null;
    this._rawLocation = new VoidLocation();
    this._fragmentMap = new Map();
  }

  setLocation(location: RawLocation): this {
    this._rawLocation = location;

    return this;
  }

  addToFragmentMap(name: string, value: string): this {
    const normalized = Nette.webalize(value);
    this._fragmentMap.set(name, normalized);

    return this;
  }

  clearFragmentMap(): this {
    this._fragmentMap.clear();

    return this;
  }

  private _getAttributeText(item: string): string {
    for (const [k, v] of this._fragmentMap) {
      if (item === v) {
        return k;
      }
    }
    return item;
  }

  private _getFragmentText(item: string): string {
    return this._fragmentMap.has(item)
      ? (this._fragmentMap.get(item) ?? "")
      : item;
  }

  read(key: string, tryQuery: boolean) {
    let obj = this.toObject();
    return obj.hasOwnProperty(key)
      ? obj[key]
      : tryQuery
        ? this._readQuery(key)
        : null;
  }

  private _readQuery(key: string): any {
    let value: string;
    try {
      value = this._rawLocation?.queryPart(this._getFragmentText(key)) ?? "";
    } catch {
      return null;
    }

    return this._getAttributeText(value);
  }

  write(key: string, val: string): this {
    this._write(key, val);
    this._applyFragment();
    return this;
  }

  private _write(key: string, val: string | null): void {
    let obj = this.toObject();
    if (null === val) {
      delete obj[key];
    } else {
      obj[key] = val;
    }
  }

  applyState(state: Record<string, string>) {
    for (const [k, v] of Object.entries(state)) {
      this._write(k, v);
    }
    this._applyFragment();
    return this;
  }

  private _applyFragment(): void {
    let state = this.toObject();
    let hash = Object.entries(state)
      .sort((l, r) => (l < r ? -1 : +(l > r)))
      .map((pair) =>
        pair.map(this._getFragmentText.bind(this)).join(this._assignSymbol),
      )
      .join(this._delimiter);
    hash = encodeURI(hash);
    this._rawLocation?.push(hash);
  }

  public pushRawHash(hash: string): this {
    this._state = null;
    this._rawLocation?.push(hash);

    return this;
  }

  public getRawHash(): string {
    return this._rawLocation?.hash() ?? "";
  }

  private _trigUpdate(): void {
    const state = this.toObject() as UpdateFragmentEventDetail;
    this.dispatcher
      .createEvent(UtilsEvents.UpdateFragmentEvent, state)
      .dispatch();
  }

  toObject(): Record<string, string> {
    if (!this._state) {
      this._hash = this._rawLocation?.hash() ?? "";
      const entries: Iterable<[string, string]> = this._hash
        .split(this._delimiter)
        .map((pair): FragmentPart => {
          const parts = pair
            .split(this._assignSymbol)
            .map((item): string => this._getAttributeText(item));
          return new FragmentPart(parts[0], parts[1]);
        })
        .filter((fp): boolean => !!fp.value)
        .map((fp) => [fp.key, decodeURI(fp.value)]);
      this._state = Object.fromEntries(entries);
      this._parseQueryPart();
    }
    return this._state;
  }

  private _parseQueryPart() {
    this._state = Fragment.QUERY_PARTS.map(
      (part) => new FragmentPart(part, this._readQuery(part)),
    )
      .filter((fp) => !!fp.value && !this._state?.[fp.key])
      .reduce(
        (accObj, fp) => ((accObj[fp.key] = fp.value), accObj),
        this._state ?? {},
      );
  }

  triggerUpdate(): void {
    const t = setTimeout(() => {
      clearTimeout(t);
      let state = this.toObject();
      if (!this.utils.isEmpty(state)) {
        this._trigUpdate();
      }
    }, 0);
  }

  private _popstateHandler(event: TrigEvent): void {
    this._state = null;
    this._trigUpdate();
    _dump("(fragment) popstate");

    this.dispatcher
      .createEvent(UtilsEvents.PopstateFragmentEvent, {
        location: this._rawLocation,
      } as PopstateFragmentEventDetail)
      .dispatch();
  }

  registerChangeHash(dom: DOMManipulator, window: Window) {
    const rawLocation = new LocationLocation(window.location);
    this.setLocation(rawLocation);
    dom(window).on("popstate", this._popstateHandler.bind(this));

    return this;
  }

  static register(
    dispatcher: Dispatcher,
    dom: DOMManipulator,
    window: Window,
  ): Fragment {
    const fragment = new Fragment(
      (window as WindowLoader).arival.utils,
      dispatcher,
    );
    fragment.registerChangeHash(dom, window);

    return fragment;
  }
}
