/// <reference path="../global.d.ts"/>

import type SnippetHandler from "../Utils/SnippetHandler";
import type { BeforeUpdateEvent } from "../Utils/SnippetHandler";

import "ddslick/dist/jquery.ddslick.min";
import BuilderFactory from "../Utils/BuilderFactory";
import BuilderFormAjaxRequest from "../Utils/BuilderFormAjaxRequest";
import { forSnippet } from "../Utils/NameSnippets";
import TypeaheadBuilder from "../Utils/TypeaheadBuilder";
import OrderForm from "./OrderForm";
import { _dump } from "../Utils/Dumps";

type JQueryDdSlick = JQueryExtends;

interface Place {
  zip: string;
  name: string;
  address: string;
}

class ChangeTransfer {
  private _country: HTMLElement | null;
  private _shipping: HTMLElement | null;
  private _payment: HTMLElement | null;
  private _lock: number;
  private _namespace: string = "transfer";

  constructor(
    private _dom: DOMManipulator,
    private _$source: JQueryExtends,
  ) {
    this._country = null;
    this._shipping = null;
    this._payment = null;
    this._lock = 0;
  }

  lock() {
    ++this._lock;

    return this;
  }

  setCountry(el: HTMLElement): this {
    this._country = el;

    return this;
  }

  fireCountry(el: HTMLElement): this {
    this.setCountry(el);
    this._fireEvent(`selectCountry`, this._country);

    return this;
  }

  setShipping(el: HTMLElement): this {
    this._shipping = el;

    return this;
  }

  fireShipping(el: HTMLElement): this {
    this.setShipping(el);
    this._fireEvent(`selectShipping`, this._shipping);

    return this;
  }

  setPayment(el: HTMLElement): this {
    this._payment = el;

    return this;
  }

  firePayment(el: HTMLElement): this {
    this.setPayment(el);
    this._fireEvent(`selectPayment`, this._payment);

    return this;
  }

  private _fireEvent<T>(name: string, source: T): void {
    const event = this._dom.Event(`${name}.${this._namespace}`, {
      detail: source,
    });
    event.bubbles = false;

    this._$source.trigger(event);
  }

  unlock(): this {
    if (0 === --this._lock) {
      this._fireEvent(`changeTransfer`, {
        country: this._country,
        shipping: this._shipping,
        payment: this._payment,
      });
    }

    return this;
  }

  static register(dom: DOMManipulator, $source: JQueryExtends): ChangeTransfer {
    const changeTransfer = new ChangeTransfer(dom, $source);

    return changeTransfer;
  }
}

export default class SelectShippingPayment extends OrderForm {
  static HiddenClass = "init-hidden";

  private _placeSelector: string | null;
  private _defaultPayment: string;
  private _$currentPayment: DOMBase | null;
  private _selectCountryHandler: (data: any) => void;
  private _changeShippingHandler: (event: TrigEvent) => void;
  private _changePaymentHandler: (event: TrigEvent) => void;
  private _selectPlaceHandler: (event: TrigEvent) => void;
  private _focusPlaceHandler: (event: TrigEvent) => void;
  private _changeStateHandler: (event: TrigEvent) => void;
  private _clickTransferItemHandler: (event: TrigEvent) => void;
  private _changeTransferItemHandler: (event: TrigEvent) => void;

  constructor(
    $orderForm: JQueryExtends,
    formRequestBuilder: BuilderFormAjaxRequest,
    private _dom: DOMManipulator,
    private _changeTranfer: ChangeTransfer,
  ) {
    super($orderForm, formRequestBuilder);
    this._placeSelector = null;
    this._defaultPayment = "0";
    this._$currentPayment = null;
    this._selectCountryHandler = this._selectCountryCallback.bind(this);
    this._changeShippingHandler = this._changeShippingCallback.bind(this);
    this._changePaymentHandler = this._changePaymentCallback.bind(this);
    this._selectPlaceHandler = this._selectPlaceCallback.bind(this);
    this._focusPlaceHandler = this._focusPlaceCallback.bind(this);
    this._changeStateHandler = this._changeStateCallback.bind(this);
    this._clickTransferItemHandler = this._clickTransferItemCallback.bind(this);
    this._changeTransferItemHandler =
      this._changeTransferItemCallback.bind(this);
  }

  selectCountry($el: DOMBase) {
    const $possibleShippigns = this._$orderForm.find(
      ".cart-shipping-list .cart-shipping-payment-item",
    );
    const $showedShippings = $possibleShippigns
      .addClass(SelectShippingPayment.HiddenClass)
      .siblings(`[data-country="${$el.val()}"]`)
      .removeClass(SelectShippingPayment.HiddenClass);

    return $showedShippings;
  }

  selectShipping($el: DOMBase) {
    const $possiblePayments = this._$orderForm.find(
      ".cart-payment-list .cart-shipping-payment-item",
    );

    const allowPayment = `${$el.data("possibly-payments")}`.split(",");
    this._defaultPayment = `${$el.data("default-payment")}`;

    const $showedPayments = $possiblePayments
      .addClass(SelectShippingPayment.HiddenClass)
      .filter((i: number, elem: HTMLElement): boolean => {
        let $elem = this._dom(elem);

        if ($elem.find('input[name$="[payment]"]').is(":checked")) {
          this._$currentPayment = $elem;
        }
        return this._inCollectionWithDataKey(allowPayment, $elem, "payment");
      })
      .removeClass(SelectShippingPayment.HiddenClass);
    $showedPayments
      .find(".item-price p.price")
      .each((i: number, elem: HTMLElement): void => {
        let $element = this._dom(elem);
        let $content = !$el.data("free-shipping")
          ? $element.data("price-non-free")
          : $element.data("price-free");
        $element.html($content);
      });

    return $showedPayments;
  }

  private _selectDefaultIfCurrentNotShow($showedPayments: DOMBase): void {
    if (
      null === this._$currentPayment ||
      this._$currentPayment.hasClass(SelectShippingPayment.HiddenClass)
    ) {
      const $selectedPayment = $showedPayments
        .filter(`[data-payment="${this._defaultPayment}"]`)
        .find("input[type='radio']");
      $selectedPayment.trigger("click");
    }
  }

  private _inCollectionWithDataKey(
    collection: Array<string>,
    $elem: DOMBase,
    key: string,
  ): boolean {
    return 0 <= collection.indexOf(`${$elem.data(key)}`);
  }

  private _selectCountryCallback(data: any): void {
    const $source = data.original;
    const source = $source.get(0) as HTMLElement;
    this._changeTranfer.lock();
    this.selectCountry($source).first().trigger("click");
    this._changeTranfer.fireCountry(source).unlock();
  }

  registerCountry(selector: string): this {
    const $country = this._$orderForm.find(selector);
    ($country as unknown as JQueryDdSlick).ddslick({
      width: "100%",
      embedCSS: true,
      background: "inherit",
      onSelected: this._selectCountryHandler,
    });

    this.selectCountry($country);
    this._changeTranfer.setCountry($country.get(0) as HTMLElement);

    return this;
  }

  private _changeShippingCallback(event: TrigEvent): void {
    const source = event.currentTarget;
    const $source = this._dom(source);
    this._changeTranfer.lock();
    const $showedPayments = this.selectShipping($source);
    this._selectDefaultIfCurrentNotShow($showedPayments);

    this._changeTranfer.fireShipping(source).unlock();
  }

  registerShipping(selector: string): this {
    this._$orderForm.off("change", selector, this._changeShippingHandler);
    this._$orderForm.on("change", selector, this._changeShippingHandler);

    const $checkedShipping = this._$orderForm.find(selector).filter(":checked");
    this.selectShipping($checkedShipping);
    this._changeTranfer.setShipping($checkedShipping.get(0) as HTMLElement);

    return this;
  }

  private _changePaymentCallback(event: TrigEvent): void {
    const source = event.currentTarget;
    this._changeTranfer.lock().firePayment(source).unlock();
  }

  registerPayment(selector: string): this {
    this._$orderForm.off("change", selector, this._changePaymentHandler);
    this._$orderForm.on("change", selector, this._changePaymentHandler);

    const $checkedPayment = this._$orderForm.find(selector).filter(":checked");
    this._changeTranfer.setPayment($checkedPayment.get(0) as HTMLElement);

    return this;
  }

  private _selectPlaceCallback(event: TrigEvent): void {
    const $target = this._dom(event.target);
    const $inputGroup = $target.closest(".input-group");
    $inputGroup.find("div.select-place").remove();
    $target.trigger("change");
  }

  private _focusPlaceCallback(event: TrigEvent): void {
    const $target = this._dom(event.currentTarget);
    $target.val("");
  }

  registerPlace(selector: string) {
    this._placeSelector = `${selector}[name$="[label]"]`;
    this._$orderForm.on(
      "typeahead:select",
      this._placeSelector,
      this._selectPlaceHandler,
    );

    this._$orderForm.on("focus", selector, this._focusPlaceHandler);
    return this;
  }

  _buildPlace() {
    if (null === this._placeSelector) {
      return;
    }

    const places = this._$orderForm
      .find(this._placeSelector)
      .filter((intex: number, element: HTMLElement) => {
        const $element = this._dom(element);

        return "true" !== $element.data("typeahead-build");
      }) as unknown as JQueryExtends;

    if (!places.exists()) {
      return;
    }

    const typeaheadBuilder = TypeaheadBuilder.create(
      this._dom,
    ) as any as TypeaheadBuilder<Place>;
    typeaheadBuilder
      .setDisplay(function (place: Place) {
        return `${place.name}: ${place.address}`;
      })
      .setNotFound(
        "<div>Vámi hledaná pobočka zřejmě nepodporuje tuto službu</div>",
      )
      .setSuggestion(function (place: Place) {
        return `<div>${place.name}: ${place.address}</div>`;
      })
      .useIdSelected(true);
    places.each((index: number, element: HTMLElement) => {
      const $element = this._dom(element);
      typeaheadBuilder.build($element);
      $element.data("typeahead-build", "true");
    });
  }

  registerChangeState(selector: string): this {
    this._$orderForm.off("change", selector, this._changeStateHandler);
    this._$orderForm.on("change", selector, this._changeStateHandler);

    return this;
  }

  private _changeStateCallback(event: TrigEvent): void {
    const fieldSet = this.findInput("fieldset");
    const idFieldset = this._dom(event.currentTarget)
      .closest("fieldset")
      .attr("id");
    fieldSet.val(`${idFieldset}`);
    this._formRequestBuilder.build(event);
  }

  private _clickTransferItemCallback(event: TrigEvent): void {
    const type = event.target.type;
    if (
      "undefined" === typeof type ||
      null === type ||
      type.toLowerCase() !== "radio"
    ) {
      event.stopPropagation();
      event.stopImmediatePropagation();
      const $target = this._dom(event.currentTarget);
      $target.find(".control-radio input[type=radio]").trigger("click");
    }
  }

  private _changeTransferItemCallback(event: TrigEvent): void {
    const $target = this._dom(event.currentTarget);
    const $current = $target.closest(".cart-shipping-payment-item");
    $current.siblings(".cart-shipping-payment-item").removeClass("active");
    $current.addClass("active");
  }

  registerSelectTransferItem(selector: string): this {
    const radioSelector = `${selector} .control-radio input[type="radio"]`;
    this._$orderForm
      .off("click", selector, this._clickTransferItemHandler)
      .off("change", radioSelector, this._changeTransferItemHandler);
    this._$orderForm
      .on("click", selector, this._clickTransferItemHandler)
      .on("change", radioSelector, this._changeTransferItemHandler);

    return this;
  }

  registerSnippetHandler(snippetHandler: SnippetHandler): this {
    snippetHandler
      .for((event: BeforeUpdateEvent) => {
        return forSnippet(event, /^snippet-+selectShippingPayment/);
      })
      .thenInit(() => {
        this._buildPlace();
      });

    return this;
  }

  static register(
    builderFactory: BuilderFactory,
    snippetHandler: SnippetHandler,
  ): SelectShippingPayment | null {
    const dom = builderFactory.modifier();
    const $selectShippingPaymentForm = dom(
      ".select-shipping-payment form.order-form",
    ) as unknown as JQueryExtends;

    if (!$selectShippingPaymentForm.exists()) {
      return null;
    }

    const formRequestBuilder = builderFactory.create();
    super.defaultRequestBuilder(formRequestBuilder);

    const changeTransfer = ChangeTransfer.register(
      dom,
      $selectShippingPaymentForm,
    );

    const delivery = new SelectShippingPayment(
      $selectShippingPaymentForm,
      formRequestBuilder,
      dom,
      changeTransfer,
    )
      .registerCountry('select[name="countries[country]"]')
      .registerShipping('input[name="shippings[shipping]"]')
      .registerPayment('input[name="payments[payment]"]')
      .registerSelectTransferItem(".cart-shipping-payment-item")
      .registerChangeState("select, .tt-input, .control-radio input")
      .registerPlace('input[name^="shippings[places]"]')
      .registerSnippetHandler(snippetHandler);

    return delivery;
  }
}
