/// <reference path="../global.d.ts"/>

import type SnippetHandler from "../Utils/SnippetHandler";
import type { BeforeUpdateEvent, CompleteEvent } from "../Utils/SnippetHandler";

type JQueryTooltip = JQueryExtends;

export default class Tooltip {
  private _dom: DOMManipulator;
  private _selector: string;

  constructor(dom: DOMManipulator, selector: string) {
    this._dom = dom;
    this._selector = selector;
  }

  apply($context: JQueryExtends<Document>) {
    this._dom($context)
      .find(this._selector)
      .each((i, e) => {
        (this._dom(e) as unknown as JQueryTooltip).tooltip();
      });
  }

  registerSnippetHandler(
    shippetHandler: SnippetHandler,
    $context: JQueryExtends<Document>,
  ): this {
    shippetHandler.always().thenInit(() => {
      this.apply($context);
    });

    return this;
  }

  static register(dom: DOMManipulator): Tooltip {
    const tooltip = new Tooltip(
      dom,
      '[data-toggle="tooltip"], [data-bs-toggle="tooltip"]',
    );

    return tooltip;
  }
}
