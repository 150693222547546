/// <reference path="../../global.d.ts"/>
import Dispatcher from "../../Event/Dispatcher";

import type {
  ChangeBreakpointEvent,
  UpdateWidthEvent,
} from "../../Utils/UtilsEvents";
import type { MenuEventDetail } from "../ControlEvents";

import Breakpoints from "../../Utils/Breakpoints";
import UtilsEvents from "../../Utils/UtilsEvents";
import ControlEvents from "../ControlEvents";

export type ExpandMobileDetail = {
  source: JQueryExtends<HTMLAnchorElement>;
};

export default class AdaptiveMenu {
  static LargeMode = "large";
  static SmallMode = "small";
  static BoundBreakpoint = "lg";

  width: number;
  private _openMenuHandler: EventHandler;
  private _closedMenuHandler: EventHandler;
  private _clickDropdownHandler: EventHandler;
  private _clickBackHandler: EventHandler;
  private _changeBreakpointHandler: CustomHandler<ChangeBreakpointEvent>;
  private _updateWidthHandler: CustomHandler<UpdateWidthEvent>;

  private _mode: string;

  constructor(
    private _dom: DOMManipulator,
    private _dispatcher: Dispatcher,
  ) {
    this.width = 0;
    this._mode = "";
    this._openMenuHandler = (event: TrigEvent) => {
      const $roots = this.getRoots() as unknown as DOMBase;
      this._dispatcher
        .createEvent(ControlEvents.OpenedMenuEvent, {
          source: $roots,
        } as MenuEventDetail)
        .dispatch();
    };
    this._closedMenuHandler = (event: TrigEvent) => {
      const $roots = this.getRoots() as unknown as DOMBase;
      this._dispatcher
        .createEvent(ControlEvents.ClosedMenuEvent, {
          source: $roots,
        } as MenuEventDetail)
        .dispatch();
    };

    this._changeBreakpointHandler = (event: ChangeBreakpointEvent): void => {
      this._selectMode(event.detail.up(AdaptiveMenu.BoundBreakpoint));
    };
    this._updateWidthHandler = (event: UpdateWidthEvent) => {
      this._updateLargeDeviceWrap(event.detail.width);
    };
    this._clickDropdownHandler = this._clickDropdownEvent.bind(this);
    this._clickBackHandler = this._clickBackEvent.bind(this);
  }

  private _selectMode(isBoundBreakpoint: boolean): void {
    if (isBoundBreakpoint) {
      if (AdaptiveMenu.LargeMode !== this._mode) {
        this._mode = AdaptiveMenu.LargeMode;
        this._setLargeDevice();
      }
    } else {
      if (AdaptiveMenu.SmallMode !== this._mode) {
        this._mode = AdaptiveMenu.SmallMode;
        this._setSmallDevice();
      }
    }
  }

  getRoots(): JQueryExtends {
    return this._dom(".rmm") as unknown as JQueryExtends;
  }

  private _setLargeDevice() {
    // Toggle to desktop version
    const $roots = this.getRoots();
    $roots.find(".rmm-menu").removeClass("rmm-mobile").addClass("rmm-desktop");
    this._unregisterCollapse($roots);

    //remove all classes from mobile verion
    $roots.find(".rmm-menu ul").removeClass("rmm-subview");
    $roots
      .find(".rmm-menu li")
      .removeClass("rmm-subover-hidden")
      .removeClass("rmm-subover-visible");
    $roots.find(".rmm-menu a").removeClass("rmm-subover-header");
    $roots.find(".rmm-toggle").hide();
  }

  private _updateLargeDeviceWrap(width: number) {
    if (AdaptiveMenu.LargeMode !== this._mode) {
      return;
    }

    const $roots = this.getRoots();
    $roots.each((i, elem) => {
      const $elem = this._dom(elem);
      const $wrap = $elem.find(".rmm-submenu-wrap");
      const side = (width - ($wrap.width() ?? 0)) / 2;
      $wrap.css({
        "padding-left": side,
        "padding-right": side,
        "margin-left": -side,
        "margin-right": -side,
      });
      // Remove border for top submenu items
      $elem
        .find(".rmm-menu > li > .rmm-submenu-wrap > .rmm-submenu")
        .each((j, elem2) => {
          const $elem2 = this._dom(elem2);
          let $children = $elem2.children();
          var count = $children.length;
          // now not need change step, for change value not show desktop render
          let step = Math.ceil((count - 1) / 3);
          $children.removeAttr("style");
          for (var index = 2; index <= count; index += step) {
            $elem2
              .children(":nth-child(" + index + ")")
              .css({ "border-color": "transparent" });
          }
        });
    });
  }

  private _setSmallDevice() {
    // Toggle to mobile version
    const $roots = this.getRoots();
    $roots.find(".rmm-menu").addClass("rmm-mobile").removeClass("rmm-desktop");
    $roots.find(".rmm-toggle").show();
    this._registerCollapse($roots);
    $roots.find(".rmm-submenu").children().removeAttr("style");
    $roots.find(".rmm-submenu-wrap").removeAttr("style");
    //$('.rmm-toggled').removeClass("rmm-closed");
  }

  private _registerCollapse($roots: JQueryExtends) {
    const $mainMenu = $roots.find("#main-menu");
    $mainMenu.addClass("collapse");
    $mainMenu
      .on("shown.bs.collapse", this._openMenuHandler)
      .on("hidden.bs.collapse", this._closedMenuHandler);
  }

  private _unregisterCollapse($roots: JQueryExtends) {
    const $mainMenu = $roots.find("#main-menu");
    $mainMenu.removeClass("collapse");
    $mainMenu
      .off("shown.bs.collapse", this._openMenuHandler)
      .off("hidden.bs.collapse", this._closedMenuHandler);
  }

  responsiveMultiMenu() {
    this.getRoots().each((i, elem) => {
      // create mobile menu classes here to light up HTML
      const $elem = this._dom(elem);
      $elem.find("ul").addClass("rmm-submenu");
      $elem.find("ul:first").addClass("rmm-menu").removeClass("rmm-submenu");
      $elem.find(".menu-right ul").removeClass("rmm-submenu");
      $elem.find(".rmm-menu").removeClass("rmm-main-menu");
      $elem.find(".rmm-submenu").each((i, elem2) => {
        const $elem2 = this._dom(elem2);
        let $labelLi: JQueryExtends<HTMLLIElement> = $elem2.closest(
          "li",
        ) as unknown as JQueryExtends<HTMLLIElement>;
        const $parentLabelLi = $labelLi
          .parent()
          .closest("li") as unknown as JQueryExtends<HTMLLIElement>;
        if ($parentLabelLi.exists()) {
          $labelLi = $parentLabelLi;
        } else {
          $labelLi = $labelLi
            .parent()
            .children("li.first") as unknown as JQueryExtends<HTMLLIElement>;
        }
        $elem2.prepend(
          '<li class="rmm-back"><a href="#">' +
            $labelLi.children("a").html() +
            "</a></li>",
        );
      });
      $elem.find(".rmm-submenu").prevAll("a").addClass("rmm-dropdown");
      $elem.find(".rmm-submenu").each((i, elem2) => {
        const $elem2 = this._dom(elem2);
        $elem2
          .parent()
          .children()
          .not("a, .link-detail")
          .wrapAll('<div class="rmm-submenu-wrap"></div>');
      });
    });
    // click interacts in mobile wersion
    this.getRoots()
      .find(".rmm-dropdown")
      .on("click", this._clickDropdownHandler);
    // click back interacts in mobile version
    this.getRoots().find(".rmm-back a").on("click", this._clickBackHandler);
  }

  private _clickDropdownEvent(event: TrigEvent): void {
    let $target = this._dom(event.currentTarget);
    if ($target.parents(".rmm-menu").hasClass("rmm-mobile")) {
      event.preventDefault();
      event.stopPropagation();
      $target
        .nextAll(".rmm-submenu-wrap")
        .addClass("rmm-subview")
        .children("ul")
        .addClass("rmm-subview");
      $target
        .addClass("rmm-subover-header")
        .parent()
        .removeClass("rmm-subover-hidden")
        .addClass("rmm-subover-visible")
        .siblings("li")
        .removeClass("rmm-subover-visible")
        .addClass("rmm-subover-hidden");

      this._dispatcher
        .createEvent(ControlEvents.ExpandMenuEvent, {
          source: $target,
        } as MenuEventDetail)
        .dispatch();
    }
  }

  private _clickBackEvent(event: TrigEvent): void {
    let $target = this._dom(event.currentTarget);
    if ($target.parents(".rmm-menu").hasClass("rmm-mobile")) {
      event.preventDefault();
      event.stopPropagation();
      const $obj = $target.parent().parent();
      const $wrapper = $obj.parent();
      $wrapper.prevAll("a").removeClass("rmm-subover-header");
      $obj
        .removeClass("rmm-subview")
        .parent()
        .removeClass("rmm-subview")
        .parent()
        .removeClass("rmm-subover-visible")
        .parent()
        .find("li")
        .removeClass("rmm-subover-hidden");

      const $expanded = $wrapper
        .parent() // skip self
        .closest(".rmm-submenu-wrap")
        .prevAll("a");

      this._dispatcher
        .createEvent(ControlEvents.ExpandMenuEvent, {
          source: $expanded,
        } as MenuEventDetail)
        .dispatch();
    }
  }

  transition() {
    const $menu = this.getRoots().find("ul.rmm-menu");
    const selector = "li";
    const changeClass = "transition";
    $menu.on("mouseenter", selector, (event: TrigEvent) => {
      if ($menu.children(selector).has(event.currentTarget)) {
        const $target = this._dom(event.currentTarget);
        $target.addClass(changeClass);
      }
    });
    $menu.on("mouseleave", selector, (event: TrigEvent) => {
      if ($menu.children(selector).has(event.currentTarget)) {
        const $target = this._dom(event.currentTarget);
        $target.removeClass(changeClass);
      }
    });
  }

  registerBreakpoints(breakpoints: Breakpoints): this {
    breakpoints
      .getDispatcher()
      .refreshListener(UtilsEvents.UpdateWidthEvent, this._updateWidthHandler)
      .refreshListener(
        UtilsEvents.ChangeBreakpointEvent,
        this._changeBreakpointHandler,
      );
    this._selectMode(breakpoints.up(AdaptiveMenu.BoundBreakpoint));
    this._updateLargeDeviceWrap(breakpoints.getLastWidth());

    return this;
  }

  static register(dom: DOMManipulator, dispatcher: Dispatcher) {
    let adaptiveMenu = new AdaptiveMenu(dom, dispatcher);
    adaptiveMenu.responsiveMultiMenu();
    adaptiveMenu.transition();

    return adaptiveMenu;
  }
}
