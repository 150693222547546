/// <reference path="../global.d.ts"/>

import type { RemoteOptions } from "bloodhound";

import "typeahead";
import Bloodhound from "bloodhound";
import Template from "./Template";

export type TypeaheadConfig<T> = Bloodhound.RemoteOptions<T>;
export type PrepareCallback = (
  query: string,
  config: JQueryAjaxSettings,
) => void;
export type PrepareConfig = JQueryAjaxSettings;
export type SuggestCallback<T> = (obj: T) => string;
export type DisplayFunction<T> = (obj: T) => string;

export default class TypeaheadBuilder<T> {
  private _dom: DOMManipulator;
  private _hint: boolean;
  private _highlight: boolean;
  private _minLength: number;
  private _display: string | DisplayFunction<T>;
  private _notFound: string | undefined;
  private _useIdSelected: boolean;
  private _wildcard: string;
  private _suggest: SuggestCallback<T>;

  constructor(dom: DOMManipulator) {
    this._dom = dom;
    this._hint = true;
    this._highlight = true;
    this._minLength = 2;
    this._display = "name";
    this._notFound = "<div>Nenalezena žádná data</div>";
    this._useIdSelected = false;
    this._wildcard = "__QUERY_PLACEHOLDER__";
    this._suggest = function (data: T): string {
      return data as string;
    };
  }

  public static create(dom: DOMManipulator): TypeaheadBuilder<void> {
    return new TypeaheadBuilder<void>(dom);
  }

  setHint(on: boolean): this {
    this._hint = on;
    return this;
  }

  setHighlight(on: boolean): this {
    this._highlight = on;
    return this;
  }

  setMinLength(minLength: number): this {
    this._minLength = minLength;
    return this;
  }

  setDisplay(display: string | DisplayFunction<T>): this {
    this._display = display;
    return this;
  }

  setNotFound(notFound: string | undefined): this {
    this._notFound = notFound;
    return this;
  }

  setSuggestion(callback: SuggestCallback<T>): this {
    this._suggest = callback;

    return this;
  }

  useIdSelected(on: boolean): this {
    this._useIdSelected = on;

    return this;
  }

  private _createRemote(url: string, prepare?: PrepareCallback): Bloodhound<T> {
    let config: TypeaheadConfig<T> = {
      url: url,
    };
    if (prepare) {
      config.prepare = this._createPrepare(prepare).bind(this);
    } else {
      config.wildcard = this._wildcard;
    }
    let remote = new Bloodhound({
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      datumTokenizer: Bloodhound.tokenizers.whitespace as (
        obj: any,
      ) => string[],
      remote: config,
    });

    return remote;
  }

  private _createPrepare(prepare: PrepareCallback) {
    return function (
      this: TypeaheadBuilder<T>,
      query: string,
      settings: JQueryAjaxSettings,
    ) {
      prepare(query, settings);
      settings.url = settings.url?.replace(
        this._wildcard,
        encodeURIComponent(query),
      );
      return settings;
    };
  }

  private _hackLimit(dataLimit: number): number {
    return dataLimit ? 2 * dataLimit : 20;
  }

  build($element: DOMBase, prepare?: PrepareCallback) {
    let url = $element.data("autocompleteUrl");
    let remote = this._createRemote(url, prepare);
    let limit = this._hackLimit($element.data("itemsCount"));
    const config: Twitter.Typeahead.Options = {
      hint: this._hint,
      highlight: this._highlight,
      minLength: this._minLength,
    };

    const dataset: Twitter.Typeahead.Dataset<T> = {
      limit: limit,
      source: remote,
      display: this._display,
      templates: {
        notFound: this._notFound,
        suggestion: this._buildSuggestion($element),
      },
    };
    let $typeahead = $element.typeahead(config, dataset);

    this._normalizeUse($element);
    this._setSetIdSelected($element);
    this._setClearOnChange($element, remote);

    return $typeahead;
  }

  private _buildSuggestion($element: DOMBase): SuggestCallback<T> {
    const source = $element.data("suggest-template");
    let cb: SuggestCallback<T>;
    if ("undefined" !== typeof source && null !== source) {
      const template = new Template(source);
      cb = function (obj: T): string {
        return template.apply(obj as Record<string, string>);
      };
    } else {
      cb = this._suggest;
    }
    return cb;
  }

  private _normalizeUse($element: DOMBase): void {
    $element.siblings(".tt-hint").removeAttr("data-nette-rules");
  }

  private _setSetIdSelected($element: DOMBase): void {
    if (this._useIdSelected) {
      $element.on("typeahead:select", (event: TrigEvent, data: any) => {
        let $target = this._dom(event.currentTarget);
        this._getInputId($target).val(data.id);
      });
    }
  }

  private _setClearOnChange($element: DOMBase, remote: Bloodhound<T>) {
    let selectorClearChange = $element.data("clear-on-change");
    const $typeahead = $element;
    if ("undefined" !== typeof selectorClearChange) {
      $element
        .closest("form")
        .find(selectorClearChange)
        .on("change", (event: TrigEvent) => {
          remote.clear().clearRemoteCache();
          let val = $typeahead.typeahead("val");
          if (val) {
            $typeahead.typeahead("close");
            $typeahead.typeahead("val", "");
            $typeahead.typeahead("val", val);
            $typeahead.typeahead("open");
          }

          this._getInputId($element).val("");
        });
    }
  }

  private _getInputId($element: DOMBase) {
    const elName = $element.attr("name") ?? "";
    const filter = elName.replace(/\[[^\[]+\]$/, "[id]");

    return $element
      .closest("form")
      .find(`input[type=hidden][name="${filter}"]`);
  }
}
