/// <reference path="../global.d.ts"/>

import Modal from "../Control/Modal";
import { BaseGatewayPayment } from "./GatewayPayment";

import type BuilderFormAjaxRequest from "../Utils/BuilderFormAjaxRequest";
import type GatewayPayment from "./GatewayPayment";

import { _dump } from "../Utils/Dumps";

class GoPayEngine {
  private _window: Window | null = null;

  inject(window: Window): this {
    this._window = window;

    return this;
  }

  checkout(url: string): Promise<any> {
    const gopay = (this._window as WindowsStorageAny)._gopay;
    return new Promise((resolve, reject) => {
      if (gopay) {
        gopay.checkout(
          {
            gatewayUrl: url,
            inline: true,
          },
          resolve,
        );
      } else {
        reject(new Error("not support ajax"));
      }
    });
  }
}

const gopayEngine = new GoPayEngine();

export class GoPayLite {
  private _clickButtonHandler: (event: TrigEvent) => void;
  constructor(
    private _dom: DOMManipulator,
    private _gatewayPayment: GatewayPayment,
    private _engine: GoPayEngine,
  ) {
    this._clickButtonHandler = this._clickButtonCallback.bind(this);
  }

  private _clickButtonCallback(event: TrigEvent) {
    event.preventDefault();

    const $form = this._dom(event.currentTarget).closest("form");
    const url: string = $form.attr("action") as string;
    const checkUrl: string = $form.data("check-url") as string;

    this._engine
      .checkout(url)
      .finally(() => {
        return this._gatewayPayment.checkPayment(checkUrl);
      })
      .then(
        (data) => {
          this._gatewayPayment.showInfo("Platba zaplacena", Modal.T_SUCCESS);
        },
        (reason) => {
          this._gatewayPayment.showInfo(
            "Chyba na platební bráně",
            Modal.T_ERROR,
          );
        },
      );
  }

  registerButton($button: DOMBase): this {
    $button
      .off("click", this._clickButtonHandler)
      .on("click", this._clickButtonHandler);
    return this;
  }

  static register(
    gatewayPayment: GatewayPayment,
    window: Window,
  ): GoPayLite | null {
    let gopayLite = null;
    const $form = gatewayPayment.dom(
      "#gopay-payment-button",
    ) as unknown as JQueryExtends;
    if ($form.exists()) {
      gopayLite = new GoPayLite(
        gatewayPayment.dom,
        gatewayPayment,
        gopayEngine,
      );
      const button = $form.find("button");
      gopayLite.registerButton(button);
      gopayEngine.inject(window);
    }
    return gopayLite;
  }
}

export default class GoPay extends BaseGatewayPayment {
  constructor(
    $orderForm: JQueryExtends,
    formRequestBuilder: BuilderFormAjaxRequest,
    gatewayPayment: GatewayPayment,
    private _engine: GoPayEngine,
  ) {
    super($orderForm, formRequestBuilder, gatewayPayment);
  }

  protected _pay(event: TrigEvent): void {
    this._gatewayPayment.showInfo(
      "Otevírám platební bránu, po jejím otevření jí vyplňte",
      Modal.T_INFO,
    );
    this._formRequestBuilder
      .build(event)
      .then(this._createdPaymentCallback.bind(this), (reason: any) => {
        this._gatewayPayment.showInfo(
          "Problém se založením platby, opakujte později",
          Modal.T_ERROR,
        );
      });
  }

  private _createdPaymentCallback(data: any): void {
    if (this._gatewayPayment.applyUrlRedirect(data, this._$orderForm)) {
      return;
    }

    if (!("errors" in data) || 0 === data.errors.length) {
      if ("payment" in data) {
        const payment = data.payment as {
          checkUrl: string;
          gatewayUrl: string;
        };
        this._checkUrl = payment.checkUrl;
        const gatewayUrl = payment.gatewayUrl;
        this._gatewayPayment.hideInfo();
        this._engine
          .checkout(gatewayUrl)
          .finally(() => {
            return this._gatewayPayment.checkPayment(this._checkUrl ?? "");
          })
          .then(this._successPayment.bind(this), (reason: any) => {
            this._gatewayPayment.urls.changeTo(gatewayUrl);
          });
      } else {
        this._gatewayPayment.showInfo(
          "Nevalidní data platební brány",
          Modal.T_ERROR,
        );
      }
    } else {
      this._gatewayPayment.showInfo([...data.errors].join(" "), Modal.T_ERROR);
    }
  }

  protected _successPayment(data: object) {
    const errors = ("errors" in data ? data["errors"] : []) as string[];
    if (0 === errors.length) {
      this._isPaid = true;
      this._gatewayPayment.showInfo("Patba provedena", Modal.T_SUCCESS);
      this._formRequestBuilder.fireLastEvent();
    } else {
      this._gatewayPayment.showInfo([...errors].join(" "), Modal.T_ERROR);
    }
  }

  registerGoPayPayment(): this {
    this._$orderForm.off("submit", this._submitPaymentHandler);
    this._$orderForm.on("submit", this._submitPaymentHandler);

    return this;
  }

  static register(
    $form: JQueryExtends,
    requestBuilder: BuilderFormAjaxRequest,
    gatewayPayment: GatewayPayment,
    window: Window,
  ): GoPay | null {
    let $gatewayPaymentForm = $form.filter('[data-payment="platba-gopay"]');
    if (!$gatewayPaymentForm.exists()) {
      return null;
    }

    const goPay = new GoPay(
      $gatewayPaymentForm,
      requestBuilder,
      gatewayPayment,
      gopayEngine,
    ).registerGoPayPayment();

    gopayEngine.inject(window);

    return goPay;
  }
}
