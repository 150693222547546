/// <reference path="../global.d.ts"/>

import UrlFactory from "./UrlFactory";

export default class Urls {
  constructor(
    private _window: Window,
    public factory: UrlFactory,
  ) {}

  changeTo(url: string | URL): this {
    this._window.location.href = this._urlToString(url);
    return this;
  }

  replaceTo(url: string | URL, title: string): this {
    this._window.history.replaceState({}, title, this._urlToString(url));
    return this;
  }

  private _urlToString(url: string | URL): string {
    return "string" === typeof url ? url : url.toString();
  }

  static register(window: Window, urlFactory: UrlFactory): Urls {
    const urls = new Urls(window, urlFactory);

    return urls;
  }
}
