/// <reference path="../global.d.ts"/>

import * as jQuery from "jquery";

(window as WindowsStorageAny)["$"] = (window as WindowsStorageAny)["jQuery"] =
  jQuery;

const FN = jQuery.fn as unknown as JQueryExtends;
FN.exists = function (): Boolean | JQueryExtends {
  return "undefined" !== typeof this && this.length > 0 ? this : false;
};

export default jQuery as unknown as JQueryExtends;
