/// <reference path="../global.d.ts"/>
import type { ChangeBreakpointEvent } from "../Utils/UtilsEvents";
import type { ClosedMenuEvent, OpenedMenuEvent } from "./ControlEvents";

import Dispatcher from "../Event/Dispatcher";
import UtilsEvents from "../Utils/UtilsEvents";
import ControlEvents from "./ControlEvents";
import Breakpoints from "../Utils/Breakpoints";

export default class HiddenPartsBreakpoints {
  private _isBoundBreakpoint: boolean;
  private _isVisible: boolean;

  constructor(
    private _dom: DOMManipulator,
    private _selector: string,
    private _breakpoint: string,
  ) {
    this._isBoundBreakpoint = true;
    this._isVisible = true;
  }

  public hideContent() {
    const $content = this._dom(this._selector);
    $content.addClass("d-none");
  }

  public showContent() {
    const $content = this._dom(this._selector);
    $content.removeClass("d-none");
  }

  private _updateVisibility(): void {
    if (this._isBoundBreakpoint) {
      if (this._isVisible) {
        this.showContent();
      } else {
        this.hideContent();
      }
    } else {
      this.showContent();
    }
  }

  registerBreakpoins(breakpoints: Breakpoints): this {
    breakpoints
      .getDispatcher()
      .addListener(
        UtilsEvents.ChangeBreakpointEvent,
        (event: ChangeBreakpointEvent) => {
          this._changeBreakpoints(!event.detail.up(this._breakpoint));
        },
      );
    this._changeBreakpoints(!breakpoints.up(this._breakpoint));

    return this;
  }

  private _changeBreakpoints(isBoundBreakpoint: boolean): void {
    this._isBoundBreakpoint = isBoundBreakpoint;
    this._updateVisibility();
  }

  registerMenuDispatcher(dispatcher: Dispatcher): this {
    dispatcher
      .addListener(ControlEvents.OpenedMenuEvent, (event: OpenedMenuEvent) => {
        this._isVisible = false;
        this._updateVisibility();
      })
      .addListener(ControlEvents.ClosedMenuEvent, (event: ClosedMenuEvent) => {
        this._isVisible = true;
        this._updateVisibility();
      });

    return this;
  }

  static register(dom: DOMManipulator): HiddenPartsBreakpoints {
    const hiddenContent = new HiddenPartsBreakpoints(dom, "main", "lg");
    return hiddenContent;
  }
}
