/// <reference path="../../global.d.ts"/>
/// <reference path="../../Utils/NotifyVisitor.d.ts"/>

import KEYS from "./SelectionKeys";

export default class Order {
  private _dom: DOMManipulator;
  private _visitor: NotifyVisitor | null;
  private _clickLinkHandler: EventHandler;
  private _changeSelectHandler: EventHandler;

  constructor(dom: DOMManipulator, $container: DOMBase, selector: string) {
    this._dom = dom;
    this._visitor = null;
    this._clickLinkHandler = this._clickLinkCallback.bind(this);
    this._registerLink($container, selector + " a");
    this._changeSelectHandler = this._changeSelectCallback.bind(this);
    this._registerSelect($container, selector + " select");
  }

  registerVisitor(visitor: NotifyVisitor): this {
    this._visitor = visitor;

    return this;
  }

  private _clickLinkCallback(event: TrigEvent): void {
    event.preventDefault();
    const $target = this._dom(event.currentTarget);
    this._applySelect($target);
  }

  private _registerLink($container: DOMBase, selector: string): void {
    $container.off("click.nette", selector, this._clickLinkHandler);
    $container.on("click.nette", selector, this._clickLinkHandler);
  }

  private _changeSelectCallback(event: TrigEvent): void {
    event.preventDefault();
    const $target = this._dom(event.currentTarget);
    let $option = $target.children("option:selected");
    this._applySelect($option);
  }

  private _registerSelect($container: DOMBase, selector: string): void {
    $container.off("change", selector, this._changeSelectHandler);
    $container.on("change", selector, this._changeSelectHandler);
  }

  private _applySelect($item: DOMBase) {
    if (this._visitor) {
      const attr = $item.data("sort-attr");
      const order = $item.data("sort-order");
      this._visitor.notify({
        [KEYS.SORT_ATTR]: attr,
        [KEYS.SORT_ORDER]: order,
      });
    }
  }
}
