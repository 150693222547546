/// <reference path="../global.d.ts"/>

import type BuilderFactory from "../Utils/BuilderFactory";
import type BuilderFormAjaxRequest from "../Utils/BuilderFormAjaxRequest";
import type SnippetHandler from "../Utils/SnippetHandler";
import type { BeforeUpdateEvent } from "../Utils/SnippetHandler";

import Modal from "../Control/Modal";
import InputHelper from "../Utils/InputHelper";
import { forSnippet } from "../Utils/NameSnippets";
import OrderForm from "./OrderForm";

export default class Cart extends OrderForm {
  private _sendRequestHandler: (event: TrigEvent) => void;
  private _deleteHandler: (event: TrigEvent) => void;
  private _showNotesHandler: (event: TrigEvent) => void;
  private _lastChangeHandler: ((event: TrigEvent) => void) | null;
  private _modal: Modal | null;

  constructor(
    $orderForm: JQueryExtends,
    formRequestBuilder: BuilderFormAjaxRequest,
  ) {
    super($orderForm, formRequestBuilder);
    this._sendRequestHandler = this._sendRequestCallback.bind(this);
    this._deleteHandler = this._deleteCallback.bind(this);
    this._showNotesHandler = this._showNotesCallback.bind(this);
    this._lastChangeHandler = null;
    this._modal = null;
  }

  _sendRequestCallback(event: TrigEvent): void {
    this._formRequestBuilder.build(event);
  }

  registerCartEmail(selector: string): this {
    this._$orderForm.off("click", selector, this._sendRequestHandler);
    this._$orderForm.on("click", selector, this._sendRequestHandler);

    return this;
  }

  _deleteCallback(event: TrigEvent): void {
    event.preventDefault();
    this._modal?.confirm(
      "Smazat položku",
      (result) => {
        if (Modal.C_YES === result) {
          this._sendRequestHandler(event);
        }
      },
      "Editace položky",
    );
  }

  registerDelete(selector: string, modal: Modal): this {
    this._modal = modal;
    this._$orderForm.off("click", selector, this._deleteHandler);
    this._$orderForm.on("click", selector, this._deleteHandler);

    return this;
  }

  registerChangeNumber(selector: string): this {
    if (null !== this._lastChangeHandler) {
      InputHelper.disableChange(
        this._$orderForm,
        this._lastChangeHandler,
        selector,
      );
    }
    this._lastChangeHandler = InputHelper.change(
      this._$orderForm,
      this._sendRequestHandler,
      selector,
    );

    return this;
  }

  _showNotesCallback(event: TrigEvent): void {
    event.preventDefault();
    this._$orderForm.find(".note-part").removeClass("hide-js");
    this._$orderForm.find("input[name=showNote]").val(1);
  }

  registerShowNotes(selector: string): this {
    this._$orderForm.off("click", selector, this._showNotesHandler);
    this._$orderForm.on("click", selector, this._showNotesHandler);

    return this;
  }

  registerSnippetHandler(snippetHandler: SnippetHandler, modal: Modal): this {
    snippetHandler
      .for((event: BeforeUpdateEvent) => {
        return forSnippet(event, /^snippet-+cart(?:\b|-+)/);
      })
      .thenInit(() => {
        this.registerChangeNumber(".cart-item-count input.input-number")
          .registerDelete(".cart-item-remove button", modal)
          .registerCartEmail(".cart-send-box button")
          .registerShowNotes("#cart-add-note");
      });

    return this;
  }

  static register(
    builderFactory: BuilderFactory,
    snippetHandler: SnippetHandler,
    modal: Modal,
  ): Cart | null {
    const dom = builderFactory.modifier();
    let $form = dom(".cart form.order-form") as unknown as JQueryExtends;
    if (!$form.exists()) {
      return null;
    }

    const formRequestBuilder = builderFactory.create();
    super.defaultRequestBuilder(formRequestBuilder);
    const cart = new Cart($form, formRequestBuilder).registerSnippetHandler(
      snippetHandler,
      modal,
    );

    return cart;
  }
}
