/// <reference path="../global.d.ts"/>

type ChangeCallback = CustomHandler<TrigEvent>;
type JQueryHTML = JQuery<HTMLElement>;

function emptyHandler(event: TrigEvent) {}

const eventsName = "propertychange input";

export default class InputHelper {
  static change(
    $context: DOMBase | JQueryExtends,
    cb: ChangeCallback,
    selector?: string,
  ): EventHandler {
    const eventHandler = (event: TrigEvent): void => {
      let handler = emptyHandler;
      switch (event.type) {
        case "input":
          handler = cb;
          break;
        case "propertychange":
          if (
            "value" === (event.originalEvent as TransitionEvent).propertyName
          ) {
            handler = cb;
          }
          break;
      }
      handler.call(event.target, event);
    };

    if (selector) {
      ($context as JQueryHTML).on(eventsName, selector, eventHandler);
    } else {
      ($context as JQueryHTML).on(eventsName, eventHandler);
    }

    return eventHandler;
  }

  static disableChange(
    $context: DOMBase | JQueryExtends,
    handler: EventHandler,
    selector?: string,
  ): void {
    if (selector) {
      ($context as JQueryHTML).off(eventsName, selector, handler);
    } else {
      ($context as JQueryHTML).off(eventsName, handler);
    }
  }

  static trigChange($input: DOMBase | JQueryExtends) {
    ($input as JQueryHTML).trigger("input");
  }
}
