/// <reference path="../global.d.ts"/>

export default class NotifyMessage {
  static BUTTON: '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>';
  _$messages: JQueryExtends;

  constructor($messages: JQueryExtends) {
    this._$messages = $messages;
  }

  private _withDelay($element: JQueryExtends) {
    return $element.delay(1000);
  }

  registerShow() {
    if (this._$messages.exists()) {
      this._withDelay(this._$messages)
        .css({ visibility: "visible", opacity: 0.0 })
        .fadeTo(2000, 1.0);
    }
  }

  registerShowCorner($body: DOMBase) {
    if (this._$messages.exists()) {
      let $clone = this._$messages.clone();
      $clone.prepend(NotifyMessage.BUTTON);
      $clone.appendTo($body).addClass("corner-view");
      this._withDelay($clone).fadeIn(500);
    }
  }

  static register(dom: DOMManipulator) {
    const $messages = dom("#notify-message") as unknown as JQueryExtends;
    let notifyMessage = new NotifyMessage($messages);
    notifyMessage.registerShow();

    return notifyMessage;
  }
}
