/// <reference path="../global.d.ts"/>

import Dispatcher from "../Event/Dispatcher";
import type Breakpoints from "../Utils/Breakpoints";
import UtilsEvents, { UpdateWidthEvent } from "../Utils/UtilsEvents";
import ControlEvents from "./ControlEvents";

interface ContainerMessage {
  width: number;
  height: number;
  adaptable: boolean;
}

interface ImageMessage {
  width: number;
  height: number;
}

export default class AdaptiveImages {
  private _dom: DOMManipulator;
  private _cache: Map<string, HTMLImageElement>;

  constructor(dom: DOMManipulator) {
    this._dom = dom;
    this._cache = new Map<string, HTMLImageElement>();
  }

  loadImage(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  }

  adaptImageToContainer(
    $img: DOMBase<HTMLImageElement>,
    $container: DOMBase<HTMLElement>,
  ) {
    const src = $img.attr("src") as string;
    let img = this._cache.get(src);
    if ("undefined" === typeof img) {
      this.loadImage(src).then((img: HTMLImageElement) => {
        this._cache.set(src, img);
        this._process(img, $img, $container);
      });
    } else {
      this._process(img, $img, $container);
    }
  }

  _process(
    img: HTMLImageElement,
    $element: DOMBase<HTMLImageElement>,
    $container: DOMBase<HTMLElement>,
  ) {
    let image: ImageMessage | null = null,
      container: ContainerMessage | null = null;
    try {
      image = this._naturalResolutionImage(img);
      container = this._containerResolution($container);
    } catch (e) {
      image = null;
    }

    if (null === container || null === image) {
      $element.width(0);
      $element.height(0);
    } else {
      const ratio = this.getRatio(container, image);
      let height = image.height * ratio;
      if (container.adaptable) {
        $container.height(height);
      }
      if (1 > ratio) {
        $element.width(image.width * ratio);
        $element.height(height);
      } else {
        $element.width(image.width);
        $element.height(image.height);
      }
    }
  }

  _naturalResolutionImage(img: HTMLImageElement): ImageMessage {
    if (0 === img.width || 0 === img.height) {
      throw new Error("Bad resolution");
    }

    return {
      width: img.width,
      height: img.height,
    };
  }

  _containerResolution($container: DOMBase<HTMLElement>): ContainerMessage {
    let adaptable = $container.hasClass("adapt");
    if (adaptable) {
      $container.css("height", "");
    }
    let height = $container.height() ?? 0;
    adaptable = adaptable && 0 === height;

    return {
      width: $container.width() ?? 0,
      height: height,
      adaptable: adaptable,
    };
  }

  getRatio(container: ContainerMessage, img: ImageMessage): number {
    let ratio: number;
    let ratioW = container.width / img.width;
    if (container.adaptable) {
      ratio = ratioW;
    } else {
      let ratioH = container.height / img.height;
      ratio = Math.min(ratioW, ratioH);
    }

    return ratio;
  }

  adapt() {
    this._dom(".img-in-container").each(
      (index: number, element: HTMLElement) => {
        const $element = this._dom(element) as DOMBase<HTMLImageElement>;
        const $container = $element.closest(".image-wrapper");
        this.adaptImageToContainer($element, $container);
      },
    );
  }

  static register(dom: DOMManipulator, windowDispatcher: Dispatcher) {
    let adaptiveImages = new AdaptiveImages(dom);
    windowDispatcher.addListener(
      UtilsEvents.UpdateWidthEvent,
      function (event: UpdateWidthEvent) {
        adaptiveImages.adapt();
      },
    );
    adaptiveImages.adapt();

    return adaptiveImages;
  }
}
