/// <reference path="../global.d.ts"/>

import type BuilderFormAjaxRequest from "../Utils/BuilderFormAjaxRequest";

export default class OrderForm {
  protected _$orderForm: JQueryExtends;
  protected _formRequestBuilder: BuilderFormAjaxRequest;

  constructor(
    $orderForm: JQueryExtends,
    formRequestBuilder: BuilderFormAjaxRequest,
  ) {
    this._$orderForm = $orderForm;
    this._formRequestBuilder = formRequestBuilder;
  }

  findInput(name: string | null, tag?: string | null) {
    tag = tag || "input";
    return this._$orderForm.find(`${tag}[name$='${name}']`);
  }

  static defaultRequestBuilder(
    requestBuilder: BuilderFormAjaxRequest,
  ): BuilderFormAjaxRequest {
    return requestBuilder
      .setTimeout(100)
      .setButtonSelector("button.btn-success, input.btn-success");
  }
}
