/// <reference path="../global.d.ts"/>

import type Dispatcher from "../Event/Dispatcher";
import type { AddItemToCartEvent } from "../Control/ControlEvents";

import ObjectHelper from "../Utils/ObjectHelper";

interface DataLayerContainer extends DOMBase<Window> {
  dataLayer: any;
}

export default class InfoTgm {
  private _dom: DOMManipulator;
  private _dataLayer: any;

  constructor(dom: DOMManipulator, dataLayer: any) {
    this._dom = dom;
    this._dataLayer = dataLayer || [];
  }

  private _callbackAddCart(event: AddItemToCartEvent): void {
    const payload = event.detail;
    if (ObjectHelper.hasProperty(payload, "info")) {
      this._dataLayer.push(payload.info);
    }
  }

  private _callbackCarousel(event: TrigEvent): void {
    const $target = this._dom(event.currentTarget);
    this._dataLayer.push({
      id: $target.data("id"),
      name: "HP-Carusel",
      creative: $target.find("h3").text().trim().replace(/\s\s+/g, " "),
      position: $target.index(),
      event: "trackEcommerce_Carusel",
    });
  }

  registerAddCart(dispatcher: Dispatcher): this {
    dispatcher.addListener("add.item.cart", this._callbackAddCart.bind(this));

    return this;
  }

  registerCarousel(selector: string): this {
    this._dom(selector).on(
      "click",
      selector,
      this._callbackCarousel.bind(this),
    );

    return this;
  }

  static register(
    dom: DOMManipulator,
    dispatcher: Dispatcher,
    $window: DOMBase<Window>,
  ) {
    const o = $window as DataLayerContainer;
    const infoTgm = new InfoTgm(dom, o.dataLayer);
    infoTgm.registerAddCart(dispatcher);
    infoTgm.registerCarousel("#banner .carousel-item");

    return infoTgm;
  }
}
