import type Dispatcher from "../../Event/Dispatcher";
import type Fragment from "../../Utils/Fragment";
import type { ChangeStateEvent } from "../../Utils/StateEvents";
import type { UpdateFragmentEvent } from "../../Utils/UtilsEvents";
import type DefaultState from "../../Utils/DefaultState";
import { _dump } from "../../Utils/Dumps";
import UtilsEvents from "../../Utils/UtilsEvents";
import StateEvents from "../../Utils/StateEvents";

export default class StateFragment {
  _updateFragmentHandler: (event: UpdateFragmentEvent) => void;
  _changeStateHandler: (event: ChangeStateEvent) => void;
  constructor(
    private _defaultState: DefaultState,
    private _fragment: Fragment,
  ) {
    this._updateFragmentHandler = this._updateFragmentCallback.bind(this);
    this._changeStateHandler = this._changeStateCallback.bind(this);
  }

  registerFragmentToState(dispatcher: Dispatcher): this {
    dispatcher.refreshListener(
      UtilsEvents.UpdateFragmentEvent,
      this._updateFragmentHandler,
    );

    return this;
  }

  registerStateToFragment(dispatcher: Dispatcher): this {
    dispatcher.refreshListener(
      StateEvents.ChangeStateEvent,
      this._changeStateHandler,
    );

    return this;
  }

  private _updateFragmentCallback(event: UpdateFragmentEvent): void {
    const s = Object.assign({}, event.detail);
    _dump("(statefragment) update fragment");
    this._defaultState.getState().setState(s);
  }

  private _changeStateCallback(event: ChangeStateEvent): void {
    const updateState = event.detail.update;
    const newState: Record<string, string> = this._defaultState
      .getClearedDefaultValue(updateState)
      .map(([k, v]) => ((v = !v ? null : v), [k, v]))
      .reduce((acc: Record<string, string>, [k, v]) => ((acc[k] = v), acc), {});
    _dump("(statefragment) change state");
    this._fragment.applyState(newState);
  }

  static register(
    dispatcher: Dispatcher,
    defaultState: DefaultState,
    fragment: Fragment,
  ): StateFragment {
    const stateFragmet = new StateFragment(defaultState, fragment)
      .registerFragmentToState(dispatcher)
      .registerStateToFragment(dispatcher);

    return stateFragmet;
  }
}
