/// <reference path='../../global.d.ts'/>
/// <reference path='../../Utils/NotifyVisitor.d.ts'/>

import type Dispatcher from "../../Event/Dispatcher";
import type ASnippetLoader from "../../Loader/ASnippetLoader";
import type {
  AfterSendLoaderEvent,
  BeforeSendLoaderEvent,
  ErrorSendLoaderEvent,
} from "../../Loader/RequestEvents";
import Breakpoints from "../../Utils/Breakpoints";
import type DefaultState from "../../Utils/DefaultState";
import Fragment from "../../Utils/Fragment";
import type {
  ChangeStateEvent,
  UpdateStateEvent,
} from "../../Utils/StateEvents";
import AnimateTop from "../AnimateTop";
import type {
  AfterLoadListEvent,
  CompleteAnimateTopEvent,
  AppendPageEvent,
} from "../ControlEvents";
import type Modal from "../Modal";
import type WebUI from "../WebUI";

import ConditionEveryCallback from "../../Utils/ConditionEveryCallback";
import ListDefaultState from "./ListDefaultState";
import LoadMore from "./LoadMore";
import Order from "./Order";
import Paginator from "./Paginator";
import KEYS from "./SelectionKeys";
import UrlFactory from "../../Utils/UrlFactory";
import AdaptiveMenu from "../Menu/AdaptiveMenu";
import ControlEvents from "../ControlEvents";
import StateEvents from "../../Utils/StateEvents";
import RequestEvents from "../../Loader/RequestEvents";
import UtilsEvents, { ChangeBreakpointEvent } from "../../Utils/UtilsEvents";
import { _dump } from "../../Utils/Dumps";

export default class List {
  static FRAGMENT_MAP = {
    [KEYS.PAGE]: "stranka",
    [KEYS.MAX_RESULTS]: "pocet-zaznamu",
    [KEYS.ITEMS_PER_PAGE]: "stranka-zaznamu",
    [KEYS.SORT_ATTR]: "razeni",
    [KEYS.SORT_ORDER]: "rad",
    [KEYS.RECOMMEND]: "doporucujeme",
    [KEYS.IS_INNOVATION]: "novinka",
    ASC: "vzestupne",
    DESC: "sestupne",
  };

  private _append: any;
  private _snippetLoader: ASnippetLoader | null;
  private _baseUri: URL;
  private _useLoading: boolean;
  private _nameControl: string;
  private _defaultState: DefaultState;
  private _afterRequest: ConditionEveryCallback;
  private _beforeRequestHandler: CustomHandler<BeforeSendLoaderEvent>;
  private _afterRequestHandler: CustomHandler<AfterSendLoaderEvent>;
  private _errorRequestHandler: CustomHandler<ErrorSendLoaderEvent>;
  private _afterListLoadHandler: CustomHandler<AfterLoadListEvent>;
  private _animateChangeStateHandler: CustomHandler<ChangeStateEvent> | null;
  private _animateTopFinishHandler: CustomHandler<CompleteAnimateTopEvent> | null;
  private _paginatorAfterListLoadHandler: CustomHandler<AfterLoadListEvent> | null;
  private _loadMoreAfterListLoadHandler: CustomHandler<AfterLoadListEvent> | null;

  constructor(
    private _urlFactory: UrlFactory,
    private _dom: DOMManipulator,
    private _dispatcher: Dispatcher,
    private _$list: DOMBase,
    private _webUI: WebUI,
    private _modal: Modal,
    defaultState: DefaultState,
  ) {
    const uri = _$list.data("ajax-href");
    if (!uri) {
      throw new Error("Missing defined uri");
    }
    this._append = null;
    this._snippetLoader = null;
    this._useLoading = true;
    this._baseUri = _urlFactory.create(uri);
    let signal = this._baseUri.searchParams.get("do") as string;
    this._nameControl = signal.substring(0, signal.lastIndexOf("-"));
    this._defaultState = defaultState
      .setDefault(KEYS.PAGE, "1")
      .setDefault(KEYS.MAX_RESULTS, ListDefaultState.ITEM_PER_PAGE)
      .setDefault(KEYS.ITEMS_PER_PAGE, ListDefaultState.ITEM_PER_PAGE)
      .setDefault(ListDefaultState.MAX_PAGE, 1)
      .setDefault(ListDefaultState.MIN_PAGE, 1);

    this._dispatcher // in costructor every override handler
      .addListener(StateEvents.UpdateStateEvent, this._updateState.bind(this))
      .addListener(StateEvents.ChangeStateEvent, this._changeState.bind(this))
      .addListener(ControlEvents.AppendPageEvent, this._appendPage.bind(this));

    this._afterRequest = new ConditionEveryCallback(function () {
      _dispatcher.createEvent(ControlEvents.AfterLoadListEvent, {}).dispatch();
    }).registerReason("request", false);

    this._beforeRequestHandler = (event: BeforeSendLoaderEvent) => {
      if (this._useLoading) {
        this._webUI.startLoading();
      } else {
        this._useLoading = true;
      }
    };

    this._afterRequestHandler = (event: AfterSendLoaderEvent) => {
      this._afterRequest.put("request");
    };

    this._errorRequestHandler = (event: ErrorSendLoaderEvent) => {
      const message = this._$list.data("abort-message");
      this._modal.info(message);
      this._webUI.stopLoading();
    };

    this._afterListLoadHandler = (event: AfterLoadListEvent) => {
      this._webUI.stopLoading();
    };

    this._animateChangeStateHandler = null;
    this._animateTopFinishHandler = null;
    this._paginatorAfterListLoadHandler = null;
    this._loadMoreAfterListLoadHandler = null;
  }

  public getStateOrDefault(name: string): any {
    return this._defaultState.getStateOrDefault(name);
  }

  public isAppenedMode(): boolean {
    return !!this._append;
  }

  private _updateState(event: UpdateStateEvent): void {
    this._fillProductList(event.detail.update);
  }

  private _changeState(event: ChangeStateEvent): void {
    const condition: string[] | null = this._useLoading ? null : ["request"];
    this._afterRequest.clear(condition);

    this._fillProductList(this._append || event.detail.update);
  }

  private _appendPage(event: AppendPageEvent): void {
    const d = event.detail;
    this._useLoading = d.loading;

    this.appendPage(d.page);
  }

  private _fillProductList(options: object): void {
    const url = this._urlFactory.create(this._baseUri);
    const searchParams = url.searchParams;
    this._defaultState.getClearedDefaultValue(options).forEach(([k, v]) => {
      let controlOption = `${this._nameControl}-options[${k}]`;
      if (v) {
        searchParams.set(controlOption, v);
      } else {
        searchParams.delete(controlOption);
      }
      return;
    });
    this._fetchShippent(url);
  }

  private _fetchShippent(uri: URL): void {
    const loader = this.getSnippetLoader();
    const url = uri.toString();
    if (this._append) {
      loader.append(url, ["snippet-productSelection-products"]);
    } else {
      loader.load(url);
    }
  }

  appendPage(page: number): this {
    let itemsPerPage = this._defaultState.getStateOrDefault(
      KEYS.ITEMS_PER_PAGE,
    );
    this._append = this._defaultState.getState().getStateWith({
      [KEYS.PAGE]: `${page}`,
      [KEYS.MAX_RESULTS]: itemsPerPage,
    });
    let tmp = this._defaultState.getStateOrDefault(KEYS.PAGE).split("-");
    let fromPage = +tmp[0];
    let maxResults = (page - fromPage + 1) * itemsPerPage;
    let state = {
      [KEYS.PAGE]: `${fromPage}-${page}`,
      [KEYS.MAX_RESULTS]: maxResults,
    };
    this._defaultState.getState().notify(state);
    this._append = null;

    return this;
  }

  setSnippetLoader(loader: ASnippetLoader): this {
    this._snippetLoader = loader;
    this._dispatcher
      .refreshListener(
        RequestEvents.BeforeSendLoaderEvent,
        this._beforeRequestHandler,
      )
      .refreshListener(
        RequestEvents.AfterSendLoaderEvent,
        this._afterRequestHandler,
      )
      .refreshListener(
        RequestEvents.ErrorSendLoaderEvent,
        this._errorRequestHandler,
      )
      .refreshListener(
        ControlEvents.AfterLoadListEvent,
        this._afterListLoadHandler,
      );

    return this;
  }

  getSnippetLoader(): ASnippetLoader {
    if (null === this._snippetLoader) {
      throw new Error("not set snippet loader");
    }
    return this._snippetLoader;
  }

  registerAnimage($main: DOMBase, breakpoints: Breakpoints): AnimateTop {
    const $context = this._dom("html, body");

    if (null !== this._animateChangeStateHandler) {
      this._dispatcher.removeListener(
        StateEvents.ChangeStateEvent,
        this._animateChangeStateHandler,
      );
    }

    if (null !== this._animateTopFinishHandler) {
      this._dispatcher.removeListener(
        ControlEvents.CompleteAnimateTopEvent,
        this._animateTopFinishHandler,
      );
    }

    const breakpoint = "lg";
    let isBreakpointBound = breakpoints.up(breakpoint);
    breakpoints
      .getDispatcher()
      .addListener(
        UtilsEvents.ChangeBreakpointEvent,
        (event: ChangeBreakpointEvent) => {
          isBreakpointBound = event.detail.up(breakpoint);
        },
      );

    const animateTop = new AnimateTop(this._dispatcher, $context)
      .setTopGetter((): number => {
        return $main.children("header").offset()?.top ?? 0;
      })
      .setCondition((): boolean => {
        return !this.isAppenedMode() && isBreakpointBound;
      });

    this._afterRequest.registerReason("animation", true);
    this._animateChangeStateHandler = (event: ChangeStateEvent): void => {
      const id = setTimeout(function () {
        clearTimeout(id);
        animateTop.stop();
      }, 2500);
      animateTop.animate(2000);
    };

    this._dispatcher.addListener(
      StateEvents.ChangeStateEvent,
      this._animateChangeStateHandler,
    );

    this._animateTopFinishHandler = (event: CompleteAnimateTopEvent) => {
      this._afterRequest.put("animation");
    };

    this._dispatcher.addListener(
      ControlEvents.CompleteAnimateTopEvent,
      this._animateTopFinishHandler,
    );

    return animateTop;
  }

  registerPaginator($container: DOMBase, selector: string): Paginator {
    if (null !== this._paginatorAfterListLoadHandler) {
      this._dispatcher.removeListener(
        ControlEvents.AfterLoadListEvent,
        this._paginatorAfterListLoadHandler,
      );
    }
    const paginator = new Paginator(this._dom, $container, selector);
    paginator.registerVisitor(this._defaultState);

    this._paginatorAfterListLoadHandler = (event: AfterLoadListEvent): void => {
      this._parsePaginatorDefault(paginator);
    };

    this._dispatcher.addListener(
      ControlEvents.AfterLoadListEvent,
      this._paginatorAfterListLoadHandler,
    );

    this._parsePaginatorDefault(paginator);

    return paginator;
  }

  private _parsePaginatorDefault(paginator: Paginator) {
    paginator.refresh();
    this._defaultState
      .setDefault(ListDefaultState.MAX_PAGE, paginator.getMaxPage())
      .setDefault(ListDefaultState.MIN_PAGE, paginator.getMinPage())
      .setDefault(KEYS.MAX_RESULTS, paginator.getItemsPerPage())
      .setDefault(KEYS.ITEMS_PER_PAGE, paginator.getItemsPerPage());
  }

  registerOrder($container: DOMBase, selector: string): Order {
    let order = new Order(this._dom, $container, selector);
    order.registerVisitor(this._defaultState);
    this._defaultState
      .setDefault(KEYS.SORT_ATTR, KEYS.RECOMMEND)
      .setDefault(KEYS.SORT_ORDER, "DESC");

    return order;
  }

  registerLoadMore($container: DOMBase, selector: string): LoadMore {
    if (null !== this._loadMoreAfterListLoadHandler) {
      this._dispatcher.removeListener(
        ControlEvents.AfterLoadListEvent,
        this._loadMoreAfterListLoadHandler,
      );
    }

    let loadMore = new LoadMore(
      this._dispatcher,
      this._defaultState,
      $container,
      selector,
    );
    this._loadMoreAfterListLoadHandler = (event: AfterLoadListEvent): void => {
      loadMore.refresh();
    };

    this._dispatcher.addListener(
      ControlEvents.AfterLoadListEvent,
      this._loadMoreAfterListLoadHandler,
    );

    loadMore.refresh();

    return loadMore;
  }

  registerFragment(fragment: Fragment): void {
    Object.entries(List.FRAGMENT_MAP).reduce((f: Fragment, [from, to]) => {
      return f.addToFragmentMap(from, to);
    }, fragment);
  }

  static register(
    dom: DOMManipulator,
    dispatcher: Dispatcher,
    $list: DOMBase,
    defaultState: DefaultState,
    fragment: Fragment,
    $window: DOMBase<Window>,
    loader: ASnippetLoader,
    webUI: WebUI,
    modal: Modal,
    urlFactory: UrlFactory,
    breakpoints: Breakpoints,
    menuDispatcher: Dispatcher,
  ) {
    const list = new List(
      urlFactory,
      dom,
      dispatcher,
      $list,
      webUI,
      modal,
      defaultState,
    );

    list.setSnippetLoader(loader);
    let $main = $list.closest("main");
    list.registerOrder($main, ".order-by");
    list.registerPaginator($main, ".pagination");
    list
      .registerLoadMore($main, "")
      .registerOnScroll($window)
      .registerOnAnimate(dispatcher)
      .registerMenuDispatcher(menuDispatcher);
    list.registerFragment(fragment);
    list.registerAnimage($main, breakpoints);

    return list;
  }
}
