/// <reference path="../global.d.ts"/>

import type { Naja } from "naja/dist/Naja";
import type Fragment from "../Utils/Fragment";
import type Dispatcher from "../Event/Dispatcher";
import type {
  AfterSendLoaderEvent,
  BeforeSendLoaderEvent,
} from "../Loader/RequestEvents";
import type WebUI from "./WebUI";
import type Modal from "./Modal";
import type Breakpoints from "../Utils/Breakpoints";
import type SnippetHandler from "../Utils/SnippetHandler";

import ProductState from "../Utils/State";
import NajaSnippetLoader from "../Loader/NajaSnippetLoader";
import List from "./Product/List";
import Selection from "./Product/Selection";
import StateFragment from "./Product/StateFragment";
import ListDefaultState from "./Product/ListDefaultState";
import { BeforeUpdateEvent } from "../Utils/SnippetHandler";
import { forSnippet } from "../Utils/NameSnippets";
import UrlFactory from "../Utils/UrlFactory";
import { _dump } from "../Utils/Dumps";
import NetteSnippetLoader from "../Loader/NetteSnippetLoader";
import AdaptiveMenu from "./Menu/AdaptiveMenu";
import { SnippetLoaderSettins } from "../Loader/ASnippetLoader";
import RequestEvents from "../Loader/RequestEvents";

export default class ProductList {
  private _list: List;
  private _selection: Selection;
  private static _webUIHandler: CustomHandler<AfterSendLoaderEvent>;

  constructor(list: List, selection: Selection) {
    this._list = list;
    this._selection = selection;
  }

  public getList(): List {
    return this._list;
  }

  public getSelection(): Selection {
    return this._selection;
  }

  static register(
    dom: DOMManipulator,
    naja: Naja,
    snippetHandler: SnippetHandler,
    dispatcher: Dispatcher,
    fragment: Fragment,
    $window: DOMBase<Window>,
    modal: Modal,
    webUI: WebUI,
    urlFactory: UrlFactory,
    breakpoints: Breakpoints,
    menuDispatcher: Dispatcher,
  ): void {
    if (null !== this._webUIHandler) {
      dispatcher.removeListener(
        RequestEvents.AfterSendLoaderEvent,
        this._webUIHandler,
      );
    }

    this._webUIHandler = (event: AfterSendLoaderEvent) => {
      webUI.withoutFragment();
    };
    dispatcher.addListener(
      RequestEvents.AfterSendLoaderEvent,
      this._webUIHandler,
    );

    function productListFactory(
      $list: DOMBase,
      loader: NajaSnippetLoader<SnippetLoaderSettins>,
    ) {
      const state = new ProductState(dispatcher);
      const defaultState = new ListDefaultState(state);
      StateFragment.register(dispatcher, defaultState, fragment);

      const $filter = dom("#productSelection-filter");
      const $used = dom('<div class="filter-list-used"></div>').insertBefore(
        $list,
      );

      fragment.clearFragmentMap();

      const list = List.register(
        dom,
        dispatcher,
        $list,
        defaultState,
        fragment,
        $window,
        loader,
        webUI,
        modal,
        urlFactory,
        breakpoints,
        menuDispatcher,
      );

      const selection = Selection.register(
        dom,
        dispatcher,
        $filter,
        $used,
        state,
        fragment,
      );

      return new ProductList(list, selection);
    }

    function initializeProductList() {
      const $list = dom(".product-list");
      let productList = null;
      if (
        ($list as unknown as JQueryExtends).exists() &&
        !!$list.data("ajax-href")
      ) {
        const loader = new NajaSnippetLoader(
          dispatcher,
          naja,
          snippetHandler,
        ).allowSnippet("snippet-productSelection-products");

        try {
          productList = productListFactory($list, loader);
        } catch (e) {
          webUI.withoutFragment().stopLoading();
          _dump(e);
        }
      } else {
        webUI.stopLoading();
      }
    }

    snippetHandler
      .for(function (e: BeforeUpdateEvent) {
        return forSnippet(e, "snippet--content");
      })
      .thenInit(initializeProductList);
  }
}
