/// <reference path="../global.d.ts"/>
/// <reference path="./NotifyVisitor.d.ts"/>

import type Dispatcher from "../Event/Dispatcher";
import type { StateEventDetail } from "./StateEvents";

import StateEvents from "./StateEvents";

export default class State implements NotifyVisitor {
  private _dispatcher: Dispatcher;
  private _state: Record<string, any>;

  constructor(dispatcher: Dispatcher) {
    this._dispatcher = dispatcher;
    this._state = {};
  }

  getValue(name: string): any {
    return this._state[name] || null;
  }

  setState(state: Record<string, any>): this {
    this._state = Object.assign({}, state) as Record<string, string>;

    this._dispatcher
      .createEvent(StateEvents.UpdateStateEvent, {
        state: this,
        update: state,
      } as StateEventDetail)
      .dispatch();

    return this;
  }

  notify(configs: Record<string, any>): void {
    this.modifyState(configs);
  }

  mergeState(state: Record<string, any>): this {
    const s = Object.assign({}, state);

    this._dispatcher
      .createEvent(StateEvents.MergeStateEvent, {
        state: this,
        update: s,
      } as StateEventDetail)
      .dispatch();

    Object.assign(this._state, s);

    return this;
  }

  modifyState(state: Record<string, any>): this {
    this.mergeState(state);

    const s = this.getState();
    this._dispatcher
      .createEvent(StateEvents.ChangeStateEvent, {
        state: this,
        update: s,
      } as StateEventDetail)
      .dispatch();

    return this;
  }

  getStateWith(o: Record<string, any>): Record<string, any> {
    return Object.assign({}, this._state, o);
  }

  getState(): Record<string, any> {
    return Object.assign({}, this._state);
  }
}
