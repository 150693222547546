/// <reference path="../../global.d.ts"/>

import type DefaultState from "../../Utils/DefaultState";
import type Dispatcher from "../../Event/Dispatcher";
import type {
  AfterLoadListEvent,
  AppendPageEventDetail,
  BeforeAnimateTopEvent,
  ClosedMenuEvent,
  OpenedMenuEvent,
} from "../ControlEvents";
import KEYS from "./SelectionKeys";
import ListDefaultState from "./ListDefaultState";
import ControlEvents from "../ControlEvents";

export default class LoadMore {
  _$window: DOMBase<Window> | null;
  _$ui: DOMBase;
  private _scrollEventThis: EventHandler | null;
  private _lastScrollPosition: number;
  private _loading: boolean;
  private _clickLoadNextHandler: EventHandler;
  private _animateTopBeforeHandler: CustomHandler<BeforeAnimateTopEvent>;
  private _afterListLoadHandler: CustomHandler<AfterLoadListEvent>;
  private _openMenuHandler: CustomHandler<OpenedMenuEvent>;
  private _closeMenuHandler: CustomHandler<ClosedMenuEvent>;
  private _openMenu: boolean;
  private _animating: boolean;

  constructor(
    private _dispatcher: Dispatcher,
    private _state: DefaultState,
    $container: DOMBase,
    selector: string,
  ) {
    let localSelector = selector + " a.load-more";
    this._$ui = $container.find(localSelector);
    this._clickLoadNextHandler = this._clickLoadNextCallback.bind(this);
    this._registerCallback($container, localSelector);
    this._$window = null;
    this._scrollEventThis = null;
    this._lastScrollPosition = 0;
    this._loading = false;
    this._openMenu = false;
    this._animating = false;
    this._animateTopBeforeHandler = (event: BeforeAnimateTopEvent) => {
      this._animating = true;
      this._update();
    };
    this._afterListLoadHandler = (event: AfterLoadListEvent) => {
      this._animating = false;
      this._update();
    };
    this._openMenuHandler = (event: OpenedMenuEvent) => {
      this._openMenu = true;
      this._update();
    };
    this._closeMenuHandler = (event: ClosedMenuEvent) => {
      this._openMenu = false;
      this._update();
    };
  }

  private _update(): void {
    if (this._openMenu || this._animating) {
      this.autoload(false);
    } else {
      this.autoload(this._hasNext());
    }
  }

  private _clickLoadNextCallback(event: TrigEvent): void {
    event.preventDefault();
    if (!this._loading) {
      this._appendNextPage(true);
    }
  }

  private _registerCallback($container: DOMBase, selector: string) {
    $container.off("click.nette", selector, this._clickLoadNextHandler);
    $container.on("click.nette", selector, this._clickLoadNextHandler);
  }

  private _hasNext(): boolean {
    const toPage = this._toPage();
    const maxPage =
      this._state.getStateOrDefault(ListDefaultState.MAX_PAGE) || 1;
    return toPage <= maxPage;
  }

  private _toPage(): number {
    let page = this._state.getStateOrDefault(KEYS.PAGE);
    let tmp = page.split("-");
    return +(tmp[1] || tmp[0]) + 1;
  }

  private _appendNextPage(useLoading: boolean): void {
    this._loading = true;
    this._dispatcher
      .createEvent(ControlEvents.AppendPageEvent, {
        loading: useLoading,
        page: this._toPage(),
      } as AppendPageEventDetail)
      .dispatch();
  }

  private _appendPageScroll(): void {
    if (!this._loading && !this._openMenu && this._hasNext()) {
      this._loadingUI(true);
      this._appendNextPage(false);
    }
  }

  registerOnScroll($window: DOMBase<Window>): this {
    this._$window = $window;
    this.resetLastPosition();
    if (null === this._scrollEventThis) {
      this._scrollEventThis = (event: TrigEvent): void => {
        let currentScrollPosition = $window.scrollTop() ?? 0;
        if (currentScrollPosition - this._lastScrollPosition > 0) {
          this._lastScrollPosition = currentScrollPosition;
          let boundLoad =
            (this._$ui.offset()?.top ?? 0) - ($window.height() ?? 0) - 500;
          if (currentScrollPosition >= boundLoad) {
            this._appendPageScroll();
          }
        }
      };
    }

    this.autoload(true);

    return this;
  }

  registerOnAnimate(dispatcher: Dispatcher): this {
    dispatcher.refreshListener(
      ControlEvents.BeforeAnimateTopEvent,
      this._animateTopBeforeHandler,
    );
    dispatcher.refreshListener(
      ControlEvents.AfterLoadListEvent,
      this._afterListLoadHandler,
    );
    return this;
  }

  registerMenuDispatcher(dispatcher: Dispatcher): this {
    dispatcher
      .refreshListener(ControlEvents.OpenedMenuEvent, this._openMenuHandler)
      .refreshListener(ControlEvents.ClosedMenuEvent, this._closeMenuHandler);

    return this;
  }

  resetLastPosition(): this {
    this._lastScrollPosition = 0;

    return this;
  }

  refresh(): this {
    this.resetLastPosition();

    this._loadingUI(false);
    this._showUI(this._hasNext());
    this._loading = false;

    return this;
  }

  autoloadWithNext(on: boolean): this {
    this.autoload(on && this._hasNext());

    return this;
  }

  private _loadingUI(on: boolean): void {
    this._$ui.toggleClass("local-loading", on);
  }

  private _showUI(on: boolean) {
    this._$ui.toggleClass("disabled", !on);
  }

  private autoload(on: boolean): this {
    if (this._$window) {
      if (null !== this._scrollEventThis) {
        this._$window.off("scroll", this._scrollEventThis);
        if (on) {
          this._$window.on("scroll", this._scrollEventThis);
        }
      }
    }
    return this;
  }
}
