/// <reference path="../../global.d.ts"/>

import type Dispatcher from "../../Event/Dispatcher";
import type { DefaultStateEvent } from "../../Utils/StateEvents";
import type AFilter from "./AFilter";
import type Selection from "./Selection";

import Template from "../../Utils/Template";
import { _dump } from "../../Utils/Dumps";

export default class Used {
  static CONTAINER_TEMPLATE = new Template(`
      <button class="btn btn-secondary">Zrušit filtraci</button>
      <ul class="listUsedFilter"/>
  `);
  static LIST_ITEM_TEMPLATE = new Template(
    '<li>{content} <a href="#delete-{name}" data-attribute="{name}">&nbsp;</a></li>',
  );
  private _renderHandler: (event: DefaultStateEvent) => void;

  constructor(
    private _dom: DOMManipulator,
    private _selection: Selection,
    private _$elem: DOMBase,
  ) {
    this._renderHandler = this.render.bind(this);
    this._$elem.on("click", "a", this._unuseComponent.bind(this));
    this._$elem.on("click", "button", this._clearComponents.bind(this));
  }

  registerDispatcher(dispatcher: Dispatcher): this {
    dispatcher.refreshListener("update.state", this._renderHandler);
    dispatcher.refreshListener("change.state", this._renderHandler);

    return this;
  }

  private _unuseComponent(event: TrigEvent): void {
    event.preventDefault();
    const $target = this._dom(event.currentTarget);
    const attribute = $target.data("attribute");
    this._selection.cleanComponent(attribute);
  }

  private _clearComponents(event: TrigEvent): void {
    event.preventDefault();
    this._selection.cleanAll();
  }

  render(event: DefaultStateEvent) {
    const components = this._selection.getComponents();
    _dump("(used) render", components);
    let $list: JQueryExtends, $button: DOMBase<HTMLButtonElement>;
    $list = this._$elem.find("ul.listUsedFilter") as unknown as JQueryExtends;
    if (!$list.exists()) {
      const $context = this._dom(Used.CONTAINER_TEMPLATE.apply({}));
      this._$elem.append($context);
      $list = this._$elem.find("ul.listUsedFilter") as unknown as JQueryExtends;
    }
    $button = this._$elem.find("button");

    $list.empty();

    let usedAnyFilter = false;
    for (const [name, component] of components.entries()) {
      if (component.isUsed()) {
        usedAnyFilter = true;
        const content = Used.LIST_ITEM_TEMPLATE.apply({
          content: component.toString(),
          name: name,
        });
        $list.append(this._dom(content));
      }
    }

    if (usedAnyFilter) {
      $button.show();
    } else {
      $button.hide();
    }
  }
}
