import type Dispatcher from "../Event/Dispatcher";
import type { RequestEventDetail } from "./RequestEvents";

export type SnippetLoaderSettins = Record<string, any> & {
  appendSnippets: string[];
};

import RequestEvents from "./RequestEvents";

export default abstract class ASnippetLoader<S = SnippetLoaderSettins> {
  protected _dispatcher: Dispatcher;
  protected _lastRequestUri: string | null;
  protected _settings: S;
  protected _emptySettings: S;

  constructor(dispatcher: Dispatcher) {
    this._dispatcher = dispatcher;
    this._lastRequestUri = null;
    this._settings = {} as S;
    this._emptySettings = {
      appendSnippets: [],
    } as S;
  }

  load(uri: string): void {
    this._sendRequest(uri, this._emptySettings);
  }

  append(uri: string, snippets: Array<string>): void {
    this._sendRequest(uri, {
      appendSnippets: snippets,
    } as S);
  }

  protected _sendRequest(uri: string, settings: S): void {
    this._lastRequestUri = uri;
    this._settings = settings;
    this._dispatcher
      .createEvent(RequestEvents.BeforeSendLoaderEvent, {
        settings,
      } as RequestEventDetail)
      .dispatch();
    this._send(settings);
  }

  protected abstract _send(settings: S): void;

  protected _afterLoad(settings: S, data: object): void {
    this._dispatcher
      .createEvent(RequestEvents.AfterSendLoaderEvent, {
        settings,
      } as RequestEventDetail)
      .dispatch();
  }

  protected _onError(settings: S, data: object): void {
    this._dispatcher
      .createEvent(RequestEvents.ErrorSendLoaderEvent, {
        settings,
      } as RequestEventDetail)
      .dispatch();
  }
}
