/// <reference path="../global.d.ts"/>

import Modal from "../Control/Modal";
import OrderForm from "./OrderForm";

import type { Naja } from "naja/dist/Naja";
import type BuilderFormAjaxRequest from "../Utils/BuilderFormAjaxRequest";
import type Urls from "../Utils/Urls";
import { _dump } from "../Utils/Dumps";

export default class GatewayPayment {
  constructor(
    public urls: Urls,
    public dom: DOMManipulator,
    private _modal: Modal,
    private _naja: Naja,
  ) {}

  async checkPayment(url: string | URL) {
    return this._naja.makeRequest("get", url).catch((errors: any) => {
      this.showInfo("Problem s ukladanim objednavky", Modal.T_ERROR);
    });
  }

  applyUrlRedirect(
    data: { changeUrl?: string; transferUrl?: string; redirect?: string },
    $form: JQueryExtends,
  ): boolean {
    if ("changeUrl" in data && data.changeUrl) {
      const nTransferUrl: string = data.transferUrl ?? data.changeUrl;
      this._applyUrl($form as unknown as DOMBase, data.changeUrl, nTransferUrl);
    }
    if ("redirect" in data && data.redirect) {
      this.urls.changeTo(data.redirect);
      this.hideInfo();

      return true;
    }

    return false;
  }

  protected _applyUrl(
    $form: DOMBase,
    changeUrl: string,
    transferUrl: string,
  ): void {
    const oChangeUrl = this.urls.factory.create(changeUrl);
    $form.attr("action", oChangeUrl.toString());
    this.urls.replaceTo(oChangeUrl, "Přijatá objednávka");

    const hash = oChangeUrl.searchParams.get("hash") as string;
    const oTransferUrl = this.urls.factory.create(transferUrl);
    const checkoutHref = oTransferUrl.href;
    oTransferUrl.searchParams.set("hash", hash);
    $form
      .find(`a[href="${checkoutHref}"]`)
      .attr("href", oTransferUrl.toString());
  }

  showInfo(content: string | DOMBase, theme: string, title?: string): this {
    this._modal.withTheme(theme).info(content, title);

    return this;
  }

  hideInfo(): this {
    this._modal.hide();

    return this;
  }

  static register(
    dom: DOMManipulator,
    urls: Urls,
    naja: Naja,
    modal: Modal,
  ): GatewayPayment {
    const gatewayPayment = new GatewayPayment(urls, dom, modal, naja);

    return gatewayPayment;
  }
}

export abstract class BaseGatewayPayment extends OrderForm {
  protected _checkUrl: string | null;
  protected _isPaid: boolean;
  protected _submitPaymentHandler: (event: TrigEvent) => void;

  constructor(
    $orderForm: JQueryExtends,
    formRequestBuilder: BuilderFormAjaxRequest,
    protected _gatewayPayment: GatewayPayment,
  ) {
    super($orderForm, formRequestBuilder);
    this._submitPaymentHandler = this.submitPaymentCallback.bind(this);
    this._isPaid = false;
    this._checkUrl = null;
  }

  private submitPaymentCallback(event: TrigEvent): void {
    if (!this.isPaid()) {
      this._pay(event);
    }
  }

  isPaid(): boolean {
    return !!this._isPaid;
  }

  protected abstract _pay(event: TrigEvent): void;
}
