export type AddItemToCartEventDetail = object;
export type AddItemToCartEvent = CustomEvent<AddItemToCartEventDetail>;

type AnimateTopEvent<T> = CustomEvent<T>;
export type AnimateTopEventDetail = void;
export type BeforeAnimateTopEvent = AnimateTopEvent<AnimateTopEventDetail>;
export type CompleteAnimateTopEvent = AnimateTopEvent<AnimateTopEventDetail>;

export type AppendPageEventDetail = {
  loading: boolean;
  page: number;
};
export type AppendPageEvent = CustomEvent<AppendPageEventDetail>;

export type AfterLoadListEventDetail = void;
export type AfterLoadListEvent = CustomEvent<AfterLoadListEventDetail>;

type MenuEvent<T> = CustomEvent<T>;
export type MenuEventDetail = {
  source: DOMBase;
};
export type ExpandMenuEvent = MenuEvent<MenuEventDetail>;
export type OpenedMenuEvent = MenuEvent<MenuEventDetail>;
export type ClosedMenuEvent = MenuEvent<MenuEventDetail>;

export default class ControlEvents {
  static AddItemToCartEvent = "add.item.cart";
  static AppendPageEvent = "append.page";
  static BeforeAnimateTopEvent = "before.animate.top";
  static CompleteAnimateTopEvent = "complete.animate.top";
  static AfterLoadListEvent = "after.load.list";

  static ExpandMenuEvent = "expand.menu";
  static OpenedMenuEvent = "opened.menu";
  static ClosedMenuEvent = "closed.menu";
}
