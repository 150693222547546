/// <reference path="../global.d.ts"/>

import * as wNumb from "wnumb";

export type NumberFormat = wNumb.Instance;
export type NumberOptions = wNumb.Options;

export function numberFormat(o: NumberOptions): NumberFormat {
  return (wNumb as unknown as (o: NumberOptions) => NumberFormat)(o);
}
