/// <reference path="../global.d.ts"/>

import type WebUI from "../Control/WebUI";
import type BuilderFactory from "../Utils/BuilderFactory";
import type Helpers from "../Utils/Helpers";
import type GatewayPayment from "./GatewayPayment";

import Address from "./Address";
import GoPay from "./GoPay";
import Comgate from "./Comgate";
import Voucher from "./Voucher";

export default class Customer {
  static register(
    builderFactory: BuilderFactory,
    gatewayPayment: GatewayPayment,
    window: Window,
    webUI: WebUI,
    helpers: Helpers,
  ) {
    const dom = builderFactory.modifier();
    const $form = dom(".customer form.order-form") as unknown as JQueryExtends;
    if (!$form.exists()) {
      return;
    }

    let builder = builderFactory.create();
    Address.register($form, builder, dom, webUI);
    builder = builderFactory.create();
    Voucher.register($form, builder);
    builder = builderFactory.create();
    GoPay.register($form, builder, gatewayPayment, window);
    builder = builderFactory.create();
    Comgate.register($form, builder, gatewayPayment, helpers);
  }
}
