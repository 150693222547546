/// <reference path="../global.d.ts"/>
import type BuilderFormAjaxRequest from "../Utils/BuilderFormAjaxRequest";

import InputHelper from "../Utils/InputHelper";
import OrderForm from "./OrderForm";
import { _dump } from "../Utils/Dumps";

interface Coder {
  validate(code: string): boolean;
}

class NumberHashCoder implements Coder {
  constructor(private _sizes: Array<number>) {}

  validate(code: string): boolean {
    let lengthCode = code.length;
    if (this._sizes.indexOf(lengthCode) < 0) {
      return false;
    }

    let basicCode = code.substring(0, lengthCode - 1);
    let supplement = this._getSupplement(basicCode);
    return code === `${basicCode}${supplement}`;
  }

  private _getSupplement(code: string): string {
    let sum = 0;
    for (let i = 0; i < code.length; ++i) {
      sum += this._getNumberFromChar(code.charAt(i));
    }
    let ordz = this._getNumberFromChar("z");
    let supplement = this._getCharFromNumber(sum % ordz);

    return supplement;
  }

  private _getNumberFromChar(char: string): number {
    let result = 0;
    let ordChar = char.charCodeAt(0);
    if (ordChar < 58) {
      result = ordChar - 48;
    } else if (ordChar < 65) {
      result = 0;
    } else if (ordChar < 91) {
      result = ordChar - 55;
    } else if (ordChar < 97) {
      result = 0;
    } else if (ordChar < 123) {
      result = ordChar - 61;
    }

    return result;
  }

  private _getCharFromNumber(number: number): string {
    let result = 0;
    if (number < 10) {
      result = number + 48;
    } else if (number < 36) {
      result = number + 55;
    } else if (number < 62) {
      result = number + 61;
    }

    return String.fromCharCode(result);
  }
}

class RegExpCoder implements Coder {
  private _regexes: Array<RegExp>;

  constructor(sources: Array<string>) {
    this._regexes = sources.map((reg: string) => {
      return new RegExp(reg, "i");
    });
  }
  validate(code: string): boolean {
    return this._regexes.some((re) => re.test(code), this);
  }
}

export default class Voucher extends OrderForm {
  private _codesSelector: string;
  private _codes: string | null;
  private _lastSendCodes: string | null;
  private _changeVoucherHandler: ((event: TrigEvent) => void) | null;
  private _validators: Array<Coder>;

  constructor(
    $orderForm: JQueryExtends,
    formRequestBuilder: BuilderFormAjaxRequest,
  ) {
    super($orderForm, formRequestBuilder);
    this._codesSelector = "orderContainer[vouchersCode]";
    this._codes = "";
    this._lastSendCodes = "";
    this._changeVoucherHandler = null;
    this._validators = [];
  }

  private _getInput(): DOMBase<HTMLInputElement> {
    return this.findInput(this._codesSelector) as DOMBase<HTMLInputElement>;
  }

  getRegExpSources(): Array<string> {
    return this._getInput().data("regexes") || [];
  }

  registerValidator(validator: Coder): this {
    this._validators.push(validator);

    return this;
  }

  private _changeVoucherCallback(event: TrigEvent) {
    const $input = this._getInput();
    let codes = ($input.val() as string).trim();
    if (this._codes !== codes) {
      this._codes = codes;
      this._changeCodes(event, $input, codes);
    }
  }

  private _changeCodes(event: TrigEvent, $input: DOMBase, codes: string): void {
    const $controlGroup = $input.closest(".form-group");
    if (this._validateCodes(codes)) {
      $controlGroup.removeClass("has-danger");
      $input.removeClass("is-invalid");
      this._updateVoucherCodes(event, codes);
    } else {
      $controlGroup.addClass("has-danger");
      $input.addClass("is-invalid");
    }
  }

  private _updateVoucherCodes(event: TrigEvent, codes: string): void {
    if (this._lastSendCodes !== codes) {
      this._lastSendCodes = codes;
      this._changeVoucherCodes(event);
    }
  }

  private _changeVoucherCodes(event: TrigEvent): void {
    const request = this._formRequestBuilder.build(event);
    this._formRequestBuilder.oneOnRequest(
      request,
      (data: object) => {
        const $vouchers = this._getInput();
        $vouchers.trigger("focus");
        $vouchers.val("").val(this._codes as string);

        return data;
      },
      (error: object) => {
        _dump(error);
      },
    );
  }

  private _validateCodes(codes: string): boolean {
    let validate = true;
    if (0 < codes.length) {
      validate = codes.split(/\s*[ ,;]\s*/).every(this._validateCode, this);
    }

    return validate;
  }

  private _validateCode(code: string): boolean {
    return this._validators.some((c) => c.validate(code), this);
  }
  registerChangeCodes(): this {
    this._codes = this._getInput().val() as string;
    let controlSelector = `input[name="${this._codesSelector}"]`;
    if (null !== this._changeVoucherHandler) {
      InputHelper.disableChange(
        this._$orderForm,
        this._changeVoucherHandler,
        controlSelector,
      );
    }
    this._changeVoucherHandler = InputHelper.change(
      this._$orderForm,
      this._changeVoucherCallback.bind(this),
      controlSelector,
    );

    return this;
  }

  static register(
    $form: JQueryExtends,
    requestBuilder: BuilderFormAjaxRequest,
  ): Voucher {
    super
      .defaultRequestBuilder(requestBuilder)
      .setButtonSelector('button[name$="[addVouchers]"]');

    const voucher = new Voucher($form, requestBuilder);
    const numberHashSizes = [10, 13, 14];
    const regExpSources = voucher.getRegExpSources();
    voucher
      .registerValidator(new NumberHashCoder(numberHashSizes))
      .registerValidator(new RegExpCoder(regExpSources))
      .registerChangeCodes();

    return voucher;
  }
}
