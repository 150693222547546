/// <reference path="../../global.d.ts"/>
/// <reference path="../../Utils/NotifyVisitor.d.ts"/>

import KEYS from "./SelectionKeys";

export default class Paginator {
  private _visitor: NotifyVisitor | null;
  private _$control: DOMBase | null;
  private _fresh: boolean;
  private _clickChangePageHandler: EventHandler;

  constructor(
    private _dom: DOMManipulator,
    private _$container: DOMBase,
    private _selector: string,
  ) {
    this._$control = null;
    this._fresh = true;
    this._visitor = null;
    this._clickChangePageHandler = this._clickChangePageCallback.bind(this);
    this._registerCallback(_selector + " a");
  }

  registerVisitor(visitor: NotifyVisitor): this {
    this._visitor = visitor;

    return this;
  }

  private _clickChangePageCallback(event: TrigEvent): void {
    event.preventDefault();
    const $target = this._dom(event.currentTarget);
    let page = "" + $target.data("page");
    if (this._visitor) {
      this._visitor.notify({
        [KEYS.PAGE]: page,
      });
    }
  }

  private _registerCallback(selector: string): void {
    this._$container.off("click.nette", selector, this._clickChangePageHandler);
    this._$container.on("click.nette", selector, this._clickChangePageHandler);
  }

  refresh(): this {
    this._$control = this._$container.find(this._selector).first();
    this._fresh = true;
    return this;
  }

  getControl(): DOMBase {
    if (!this._$control || !this._fresh) {
      this.refresh();
    }

    if (null === this._$control) {
      throw new Error("unknow component for paginator");
    }

    return this._$control;
  }

  getMaxPage(): number {
    return +this.getControl().data("max") || 1;
  }

  getMinPage(): number {
    return +this.getControl().data("min") || 1;
  }

  getPage(): number {
    return +this.getControl().find("li.active span").text();
  }

  getItemsPerPage(): number {
    return +this.getControl().data("items-per-page") || 12;
  }
}
