/// <reference path="../../global.d.ts"/>
import type { API } from "nouislider";
import type { NumberFormat } from "../../Utils/Formats";

import AFilter from "./AFilter";

import { create } from "nouislider";

declare type SubRange = number | [number] | [number, number];
interface Range {
  [key: string]: SubRange;
  min: SubRange;
  max: SubRange;
}

export default class FilterRange extends AFilter {
  format: NumberFormat;
  private _values: Array<number>;
  private _$values!: Array<DOMBase<HTMLInputElement>>;
  private _unit!: string;
  private _bounds!: Array<number>;
  private _slider!: API;

  constructor(dom: DOMManipulator, $elem: JQueryExtends, format: NumberFormat) {
    super(dom, $elem);
    this.format = format;
    this._values = [];

    this.refresh();

    this._$elem.on("change", "input", this._inputCallback.bind(this));
  }

  refresh() {
    if (this._slider) {
      this._slider.destroy();
    }

    const $context = this._$elem.find(".values");
    this._$values = [
      $context.find(".lower") as DOMBase<HTMLInputElement>,
      $context.find(".upper") as DOMBase<HTMLInputElement>,
    ];

    this._values = this._$values.map((elem, index) => {
      const value = elem.val() as string | number;
      return "number" === typeof value ? value : parseInt(value);
    });
    this._unit = $context.find(".unit").html();
    this._bounds = this._$values.map((elem, index) => {
      const value = elem.attr("value") as string;
      return parseInt(value);
    });

    const $range = this._$elem.find(".widget");
    this._slider = create($range.get(0) as HTMLElement, {
      start: this._values,
      connect: [false, true, false],
      behaviour: "snap",
      snap: false,
      range: this._generateRange(this._bounds[0], this._bounds[1]),
      format: this.format,
    });
    this._slider.on("update", this._updateCallback.bind(this));
    this._slider.on("change", this._changeCallback.bind(this));
  }
  private _generateRange(minimum: number, maximum: number): Range {
    let logMin: number = minimum < 10 ? 1 : Math.floor(Math.log10(minimum));
    let logMax: number = Math.ceil(Math.log10(maximum));
    let range: Range = {
      min: [minimum, Math.pow(10, logMin - 1)] as [number, number],
      max: [maximum] as [number],
    };
    let percentDifferent: number = 100 / (logMax - logMin);
    let percent: number = percentDifferent;
    for (let i = logMin + 1; i < logMax; i = i + 1) {
      range[percent + "%"] = [Math.pow(10, i), Math.pow(10, Math.ceil(i / 2))];
      percent += percentDifferent;
    }
    return range;
  }

  private _changeCallback(
    values: (number | string)[],
    handle: number,
    sets: number[],
  ) {
    this._values[handle] = Math.round(sets[handle]);
    this._notifyChange();
  }

  private _updateCallback(
    values: (number | string)[],
    handle: number,
    sets: number[],
  ) {
    this._$values[handle].val(values[handle]);
  }

  private _inputCallback(event: TrigEvent) {
    if (this._$values.some((x) => x.hasClass("has-error"))) {
      return;
    }
    const values = this._$values.map((x) => x.val() as string);
    this.setValues(values);
    this._notifyChange();
  }

  private _notifyChange() {
    const attribute = this.getAttribute();
    const values = this.getValues();
    const options = {
      [attribute]: this._inBounds(values) ? "" : this.serialize(values),
    };

    this.notifyVisitor(options);
  }

  setValues(values: Array<string>): this {
    if (null !== values && values.length > 0) {
      this._values = values.map((value) => this.format.from(value));
      this._setUsedByBounds(this._values);
    } else {
      this._values = [...this._bounds];
      this._setUsed(false);
    }

    this.render();

    return this;
  }

  render(): void {
    this._slider.set(this._values);
  }

  private _inBounds(values: Array<number>): boolean {
    return values[0] === this._bounds[0] && values[1] === this._bounds[1];
  }

  private _setUsedByBounds(values: Array<number>): void {
    this._setUsed(values !== null && !this._inBounds(values));
  }

  getValues(): Array<any> {
    return this._values;
  }

  formatValues() {
    const values = this.getValues();
    const fv = values.map((value) => this.format.to(parseInt(value)));
    return `od ${fv[0]} do ${fv[1]} ${this._unit}`;
  }
}
