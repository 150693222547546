/// <reference path="../global.d.ts"/>

import "featherlight/assets/javascripts/jquery.detect_swipe";
import "featherlight/src/featherlight";
import "featherlight/src/featherlight.gallery";
import Template from "../Utils/Template";

import type SnippetHandler from "../Utils/SnippetHandler";
import type { BeforeUpdateEvent } from "../Utils/SnippetHandler";

type JQueryGallery = JQueryExtends;

const o = (jQuery.featherlight = jQuery.featherlight || {
  defaults: {},
});
Object.assign(o.defaults, {
  closeIcon:
    '<img src="/res/front/img/icons/close.svg" alt="Zavřít" width="20px" height="20px">',
  closeOnClick: "background",
  closeOnEsc: true,
  loading:
    '<div><span>Načítám... </span><span class="local-loading"></span></div>',
});

o.autoBind = null;

export default class Gallery {
  static TEMPLATE_LINK: Template = new Template(
    `<a class="gallery" href="{src}" title="{alt}"></a>`,
  );
  private _dom: DOMManipulator;

  constructor(dom: DOMManipulator) {
    this._dom = dom;
  }

  use(selector: string): this {
    const $elements = this._dom(selector) as unknown as JQueryGallery;
    $elements.featherlight();

    return this;
  }

  wrap(selector: string): this {
    const $elements = this._dom(selector) as JQuery<HTMLImageElement>;
    $elements.each((index, element) => {
      const $element = this._dom(element);
      const content = Gallery.TEMPLATE_LINK.apply({
        src: $element.attr("src") as string,
        alt: $element.attr("alt") as string,
      });
      $element.wrap(content);
    });

    return this;
  }

  gallery(selector: string): this {
    const $gallery = this._dom(selector) as unknown as JQueryGallery;
    $gallery.featherlightGallery({
      previousIcon: "&#9664;",
      nextIcon: "&#9654;",
      galleryFadeIn: 100,
      galleryFadeOut: 300,
    });

    return this;
  }

  static register(
    dom: DOMManipulator,
    snippetHandler: SnippetHandler | null,
  ): Gallery {
    const gallery = new Gallery(dom);
    if (null === snippetHandler) {
      gallery.use("a.single-view").wrap("img.allow-view").gallery("a.gallery");
    } else {
      snippetHandler.always().thenInit(() => {
        gallery
          .use("a.single-view")
          .wrap("img.allow-view")
          .gallery("a.gallery");
      });
    }
    return gallery;
  }
}
