/// <reference path="../global.d.ts"/>

import {
  default as TimeoutPromise,
  PromiseResolve,
  PromiseReject,
} from "./TimeoutPromise";
import type { Naja } from "naja/dist/Naja";

export default class BuilderFormAjaxRequest {
  private _dom: DOMManipulator;
  private _timeoutPromise: TimeoutPromise<object>;
  private _buttonSelector: string;
  private _e: TrigEvent | null;
  private _naja: Naja;

  constructor(dom: DOMManipulator, naja: Naja) {
    this._dom = dom;
    this._naja = naja;
    this._e = null;
    this._timeoutPromise = TimeoutPromise.create(100).with(
      this._promiseCallback.bind(this),
    );
    this._buttonSelector = "button.btn-success";
  }

  private _promiseCallback(
    resolve: PromiseResolve<object>,
    reject: PromiseReject,
  ) {
    const button = this._getButton(this._getTarget()).get(0) as HTMLElement;
    return this._naja.uiHandler.clickElement(button).then(resolve, reject);
  }

  setButtonSelector(buttonSelector: string): this {
    this._buttonSelector = buttonSelector;

    return this;
  }

  setTimeout(timeout: number): this {
    this._timeoutPromise.setTimeout(timeout);
    return this;
  }

  build(e: TrigEvent) {
    this._e = e;
    this._e.preventDefault();
    return this._timeoutPromise.get();
  }

  private _getTarget() {
    return this._dom(this._e?.target);
  }

  private _getButton($element: DOMBase): DOMBase {
    return $element.is("submit") || $element.is("[type='submit']")
      ? $element
      : this._getForm($element).find(this._buttonSelector);
  }

  private _getForm($element: DOMBase): DOMBase {
    return $element.closest("form");
  }

  oneOnRequest<U>(
    request: Promise<U>,
    resolve: (value: U) => U | PromiseLike<U>,
    reject: PromiseReject,
  ) {
    if (this._timeoutPromise.isFirstGetting()) {
      request.then(resolve, reject);
    }
  }

  fireLastEvent() {
    let $button = this._getButton(this._getTarget());
    $button.trigger("click");
  }
}
