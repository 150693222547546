/// <reference path="../global.d.ts"/>
import type { PopstateFragmentEvent } from "../Utils/UtilsEvents";

import Dispatcher from "../Event/Dispatcher";
import Fragment from "../Utils/Fragment";
import UtilsEvents from "../Utils/UtilsEvents";
type JQueryTab = JQueryExtends;

export default class ProductDetail {
  static PARTS = [
    "vlastnosti-produktu",
    "motazni-list-produktu,",
    "komentare-produktu",
    "nahradni-dily-produktu",
    "dil-produktu",
    "dotaz-produktu",
  ];

  private _regexp: RegExp;
  private _enabledPushToHash: boolean;

  constructor(
    private _selector: string,
    private _dom: DOMManipulator,
  ) {
    this._regexp = new RegExp(`(?:${ProductDetail.PARTS.join("|")})$`);
    this._enabledPushToHash = true;
  }

  private _showTab(hashGetter: () => string): JQueryExtends | null {
    const $element = this._dom(this._selector) as unknown as JQueryExtends;
    if (!$element.exists()) {
      return null;
    }
    let hash = hashGetter.call(this);
    if (!hash) {
      hash = "vlastnosti-produktu";
    }
    this._showHash(hash, $element);

    return $element;
  }

  private _showHash(hash: string, $onElement: JQueryExtends): void {
    if (null !== $onElement && this._regexp.test(hash)) {
      const $tab = $onElement.find(
        `a[href="#${hash}"]`,
      ) as unknown as JQueryTab;
      $tab.tab("show");
    }
  }

  registerFragment(fragment: Fragment): this {
    const $element = this._showTab(function (): string {
      return fragment.getRawHash();
    });
    $element?.on("shown.bs.tab", "a[href]", (event) => {
      if (this._enabledPushToHash) {
        const hash = (event.target as HTMLAnchorElement).href.split("#")[1];
        fragment.pushRawHash(hash);
      }
    });
    return this;
  }

  registerDispatcher(dispatcher: Dispatcher): this {
    dispatcher.addListener(
      UtilsEvents.PopstateFragmentEvent,
      (event: PopstateFragmentEvent) => {
        this._enabledPushToHash = false;
        this._showTab(function () {
          return event.detail.location.hash();
        });
        this._enabledPushToHash = true;
      },
    );
    return this;
  }

  static register(
    dom: DOMManipulator,
    dispatcher: Dispatcher,
    fragment: Fragment,
  ): ProductDetail {
    return new ProductDetail(".pd-tabs", dom)
      .registerFragment(fragment)
      .registerDispatcher(dispatcher);
  }
}
