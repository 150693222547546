/// <reference path="../global.d.ts"/>

export default class UrlFactory {
  _base: URL;
  constructor(location: Location) {
    this._base = new URL("", location as unknown as string);
  }

  create(path: string | URL | null): URL {
    return new URL(path ?? "", this._base);
  }

  static register(window: Window): UrlFactory {
    return new UrlFactory(window.location);
  }
}
