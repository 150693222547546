/// <reference path="../../global.d.ts"/>

import AFilter from "./AFilter";

export default class FilterText extends AFilter {
  _text: string;

  constructor(dom: DOMManipulator, $elem: JQueryExtends) {
    super(dom, $elem);
    this._text = "";
  }

  setValues(values: Array<any>): this {
    this._setUsed(0 < values.length);
    this._text = values.join("");
    this.render();

    return this;
  }

  render(): void {
    this._$elem.text(this.formatValues());
  }

  getValues(): Array<any> {
    return [this._text];
  }

  formatValues(): string {
    return this._text ?? "";
  }
}
