import type State from "./State";

export type StateEvent<T> = CustomEvent<T>;

export type StateEventDetail = {
  state: State;
  update: Record<string, string>;
};

export type DefaultStateEvent = StateEvent<StateEventDetail>;
export type UpdateStateEvent = DefaultStateEvent;
export type MergeStateEvent = DefaultStateEvent;
export type ChangeStateEvent = DefaultStateEvent;

export default class StateEvents {
  static UpdateStateEvent = "update.state";
  static MergeStateEvent = "merge.state";
  static ChangeStateEvent = "change.state";
}
