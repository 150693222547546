/// <reference path="../global.d.ts"/>
import type { AnimateTopEventDetail } from "./ControlEvents";
import type Dispatcher from "../Event/Dispatcher";
import ControlEvents from "./ControlEvents";

type Condition = () => boolean;
type TopGetter = () => number;
type JCallback = (event: TrigEvent) => void;

export default class AnimateTop {
  private _animate: boolean;
  private _condition: Condition | null;
  private _topGetter: TopGetter | null;
  private _$context: DOMBase;
  private _stopKeysThis: JCallback;
  private _stopScrollForceThis: JCallback;

  constructor(
    private _dispatcher: Dispatcher,
    $context: DOMBaseAny,
  ) {
    this._condition = null;
    this._topGetter = null;
    this._animate = false;
    this._$context = $context;
    this._stopKeysThis = this._stopKeys.bind(this);
    this._stopScrollForceThis = this._stopScrollForce.bind(this);
  }

  public setCondition(condition: Condition | null): this {
    this._condition = condition;

    return this;
  }

  public setTopGetter(topGetter: TopGetter): this {
    this._topGetter = topGetter;

    return this;
  }

  public animate(time: number = 2000): void {
    if (null !== this._condition && this._condition()) {
      const top = this._topGetter ? this._topGetter() : 0;
      if (this._$context) {
        this._$context.stop(true).animate(
          {
            scrollTop: top,
          },
          {
            duration: time,
            start: this._start.bind(this),
            always: this._finish.bind(this),
          },
        );
      }
    } else {
      this._dispatchBeforeEvent();
      this._dispatchCompleteEvent();
    }
  }

  public stop(): void {
    if (this._animate) {
      this._stopScrollContext();
    }
  }

  private _start(init: any): void {
    if (!this._animate) {
      this._animate = true;
      this._$context.on("DOMMouseScroll mousewheel", this._stopScrollForceThis);
      this._$context.on("keydown", this._stopKeysThis);
      this._dispatchBeforeEvent();
    }
  }

  private _finish(animation: any, jumpedToEnd: any): void {
    if (this._animate) {
      this._$context.off(
        "DOMMouseScroll mousewheel",
        this._stopScrollForceThis,
      );
      this._$context.off("keydown", this._stopKeysThis);
      this._dispatchCompleteEvent();
      this._animate = false;
    }
  }

  private _dispatchBeforeEvent(): void {
    this._dispatcher
      .createEvent(
        ControlEvents.BeforeAnimateTopEvent,
        undefined as AnimateTopEventDetail,
      )
      .dispatch();
  }

  private _dispatchCompleteEvent(): void {
    this._dispatcher
      .createEvent(
        ControlEvents.CompleteAnimateTopEvent,
        undefined as AnimateTopEventDetail,
      )
      .dispatch();
  }

  private _stopScrollContext(): void {
    this._$context.stop(true);
  }

  private _stopKeys(event: TrigEvent): void {
    if (event.key && ["Escape", "ArrowUp", "ArrowDown"].includes(event.key)) {
      event.preventDefault();
      this._stopScrollContext();
    }
  }

  private _stopScrollForce(event: TrigEvent): void {
    this._stopScrollContext();
  }
}
