export default class ConditionEveryCallback {
  private _reasons: Map<string, boolean>;

  constructor(private _cb: () => void) {
    this._reasons = new Map();
  }

  registerReason(reason: string, init: boolean = false): this {
    this._reasons.set(reason, !init);

    return this;
  }

  clear(reasons: string[] | null = null): this {
    reasons ??= Array.from(this._reasons.keys());
    reasons.reduce((rs, r) => {
      rs.set(r, true);
      return rs;
    }, this._reasons);

    return this;
  }

  put(reason: string): this {
    if (this._reasons.has(reason)) {
      this._reasons.set(reason, false);
    } else {
      throw new Error("Unknow reason for put");
    }

    for (const [_, v] of this._reasons) {
      if (v) {
        return this;
      }
    }

    this._cb.call(null);

    return this;
  }
}
