/// <reference path="../global.d.ts"/>
/// <reference path="live-form-validation.d.ts"/>

import type { Extension, Naja } from "naja/dist/Naja";

import naja from "naja/dist/index.esm";
(window as WindowsStorageAny)["naja"] = naja;

import { LiveForm, Nette } from "live-form-validation";

LiveForm.setOptions({
  showValid: true,
  showAllErrors: true,
  messageParentClass: true,
  messageErrorPrefix: "",
  wait: 500,
});

(window as WindowsStorageAny)["LiveForm"] = LiveForm;
(window as WindowsStorageAny)["Nette"] = Nette;

class LiveFormExtension implements Extension {
  constructor(
    private document: Document,
    public className: string = "ajax",
  ) {
    this._getInicializedForms(this.document, true);
  }

  _configureNetteForms(naja: Naja): void {
    Nette.noInit = true;
    naja.formsHandler.netteForms = Nette;
  }

  _configureUiHandler(naja: Naja, selector: string): void {
    naja.uiHandler.selector = `${selector}:not([href="#"])`;
  }

  selectNotSynchronus(naja: Naja): this {
    this._configureNetteForms(naja);
    this._configureUiHandler(naja, ":not(.synchronus)");

    return this;
  }

  selectAjax(naja: Naja): this {
    this._configureNetteForms(naja);
    this._configureUiHandler(naja, ".ajax");

    return this;
  }

  _runOnLoadValidation(form: HTMLFormElement): void {
    if (LiveForm.hasClass(form, "validate-on-load")) {
      LiveForm.setFormProperty(form, "onLoadValidation", true);
      Nette.validateForm(form);
      LiveForm.setFormProperty(form, "onLoadValidation", false);
    }
  }

  _hasValidationRules(element: Element): Boolean {
    return !!element.getAttribute("data-nette-rules");
  }

  _getInicializedForms(
    $context: ParentNode,
    runLoadValidate: boolean,
  ): Array<HTMLFormElement> {
    const forms = Array.from($context.querySelectorAll("form"));
    for (const form of forms) {
      for (const element of Array.from(form.elements)) {
        if (this._hasValidationRules(element)) {
          Nette.initForm(form);

          if (runLoadValidate) {
            this._runOnLoadValidation(form);
          }
          break;
        }
      }
    }
    return forms;
  }

  _getInicializedElements(
    $context: ParentNode,
    notForms: Array<HTMLFormElement>,
  ): Array<HTMLInputElement> {
    const elements = Array.from(
      $context.querySelectorAll("input, button, select, textarea"),
    ) as Array<HTMLInputElement>;

    return elements.reduce(
      (
        els: Array<HTMLInputElement>,
        el: HTMLInputElement,
        i: number,
      ): Array<HTMLInputElement> => {
        if (
          null != el.form &&
          this._hasValidationRules(el) &&
          !notForms.includes(el.form)
        ) {
          LiveForm.setupHandlers(el);
          LiveForm.processServerErrors(el);
          els.push(el);
        }
        return els;
      },
      [],
    );
  }

  initialize(naja: Naja) {
    Nette.addEvent(this.document.body, "click", this._clickHandle.bind(this));
    naja.snippetHandler.addEventListener("afterUpdate", (event) => {
      const snippet = event.detail.snippet;
      const forms = this._getInicializedForms(snippet, false);
      const elements = this._getInicializedElements(snippet, forms);
    });
  }

  private _clickHandle(event: TrigEvent): void {
    const $target = event.target || (event as any).srcElement;
    if (LiveForm.hasClass($target, this.className)) {
      return;
    }
    if ($target.form && $target.type in { submit: 1, image: 1 }) {
      $target.form["nette-submittedBy"] = $target;
    }
  }
}

export { LiveFormExtension, naja };
