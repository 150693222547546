/// <reference path="../global.d.ts"/>

import Template from "../Utils/Template";
import TimeoutPromise from "../Utils/TimeoutPromise";
import TypeaheadBuilder from "../Utils/TypeaheadBuilder";
import { _dump } from "../Utils/Dumps";

interface Category {
  uri: string;
  text: string;
}
interface Product {
  type: string;
  text: string;
  code: string;
  category: Category;
  price: number;
}

export default class Search {
  static TEMPLATE_SUGGEST = new Template(`
    <div class="row">
        <div class="col-md-2 text-center">
            <img src="{src}" alt="{text}">
        </div>
        <div class="col-md-7 col-xl-8 text-left info">
            <div class="text">{text}</div>
            <div class="code text-right">Kód: {code}</div>
            <div class="category">
                <a class="btn btn-orange" href="{curi}" title="{ctext}">
                    Kategorie: "{ctext}"
                </a>
            </div>
        </div>
        <div class="col-md-3 col-xl-2 text-right price">
            {price}&nbsp;{currency}
        </div>
</div>`);

  private _timeout: TimeoutPromise<void>;
  private _currency: string;
  private __isLink: boolean;
  _$input: DOMBaseAny;
  private _hideFormHandler: (event: TrigEvent) => void;
  private _keyUpHandler: (event: TrigEvent) => void;
  private _clickLinkHandler: (event: TrigEvent) => void;

  constructor(
    private _dom: DOMManipulator,
    private _$context: DOMBaseAny,
  ) {
    this._$input = this._$context.find("input[type=text]");
    this._currency = this._$input.data("currency");
    this._timeout = TimeoutPromise.create(300);
    this.__isLink = false;
    this._hideFormHandler = this._hideFormCallback.bind(this);
    this._keyUpHandler = this._keyUpCallback.bind(this);
    this._clickLinkHandler = this._clickLinkCallback.bind(this);
  }

  private _showForm() {
    this._$context
      .addClass("d-inline-block")
      .siblings()
      .not(".rmm-toggle")
      .addClass("d-none d-md-inline-block");
  }

  private _hideForm() {
    this._$context
      .removeClass("d-inline-block")
      .siblings()
      .not(".rmm-toggle")
      .removeClass("d-none d-md-inline-block");
  }

  private _hideFormCallback(event: TrigEvent) {
    let $target = this._dom(event.currentTarget);
    $target.off("focusout", this._hideFormHandler);
    $target.off("keyup", this._keyUpHandler);

    const promise = this._timeout.get();
    if (this._timeout.isFirstGetting()) {
      promise.then(() => {
        this._hideForm();
        this._timeout.clean();
      });
    } else {
      this._timeout.clean();
    }
  }

  private _keyUpCallback(event: TrigEvent) {
    if (27 === event.which) {
      this._hideFormCallback(event);
    }
  }

  private _clickLinkCallback(event: TrigEvent) {
    event.preventDefault();

    this._$input.on("focusout", this._hideFormHandler);
    this._$input.on("keyup", this._keyUpHandler);
    this._showForm();
    this._$input.trigger("focus");
  }

  registerLink($context: DOMBaseAny): this {
    $context.off("click", this._clickLinkHandler);
    $context.on("click", this._clickLinkHandler);
    return this;
  }

  registerTypeahead(location: Location) {
    let typeaheadBuilder = TypeaheadBuilder.create(
      this._dom,
    ) as any as TypeaheadBuilder<Product>;
    typeaheadBuilder
      .setHint(true)
      .setHighlight(false)
      .setMinLength(2)
      .setNotFound(undefined)
      .setDisplay("text")
      .setSuggestion(this.transformDataToView.bind(this));
    let $typeahead = typeaheadBuilder.build(this._$input);
    try {
      this._fixClickLink();
    } catch (e) {
      _dump(e);
    }
    this._$input.on("typeahead:select", (event: TrigEvent, suggest: any) => {
      this._changeLocation(event, location, suggest.uri);
    });
    return $typeahead;
  }

  private _detectIsLink(event: TrigEvent): void {
    const $target = this._dom(event.target);
    if ($target.is("a")) {
      this.__isLink = true;
    }
  }

  private _changeLocation(
    event: TrigEvent,
    location: Location,
    suggestUri: string,
  ): void {
    if (this.__isLink) {
      this.__isLink = false;
    } else {
      location.href = suggestUri;
    }
  }

  private _fixClickLink(): void {
    this._$context
      .get(0)
      .addEventListener("click", this._detectIsLink.bind(this), true);
  }

  transformDataToView(payload: Product): string {
    if ("product" === payload.type) {
      return this._transformProductView(payload);
    }

    return "";
  }

  private _transformProductView(product: any): string {
    return Search.TEMPLATE_SUGGEST.apply({
      src: product.image.src,
      text: product.text,
      code: product.code,
      curi: product.category.uri,
      ctext: product.category.text,
      price: product.price,
      currency: this._currency,
    });
  }

  static register(dom: DOMManipulator, window: Window) {
    const $context = dom(".search-link a");
    let $container = $context.closest(".search-link").siblings(".search-form");
    let search = new Search(dom, $container).registerLink($context);
    search.registerTypeahead(window.location);

    return search;
  }
}
