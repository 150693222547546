/// <reference path="../../global.d.ts"/>

import type Breakpoints from "../../Utils/Breakpoints";
import UtilsEvents, { ChangeBreakpointEvent } from "../../Utils/UtilsEvents";

export default class StickyMenu {
  static BoundBreakpoint = "sm";
  initialOffset: number;
  private _menu: JQuery;
  _$window: DOMBase<Window>;
  _isBoundBreakpoint: boolean;

  constructor(menu: JQuery, $window: DOMBase<Window>) {
    this.initialOffset = menu.offset()?.top ?? 0;
    this._menu = menu;
    this._$window = $window;
    this._isBoundBreakpoint = true;
  }

  adapt() {
    if (
      this._isBoundBreakpoint &&
      (this._$window.scrollTop() ?? 0) > this.initialOffset
    ) {
      this._menu.addClass("sticky");
    } else {
      this._menu.removeClass("sticky");
    }
  }

  registerBreakpoints(breakpoints: Breakpoints): this {
    breakpoints
      .getDispatcher()
      .addListener(
        UtilsEvents.ChangeBreakpointEvent,
        (event: ChangeBreakpointEvent) => {
          this._isBoundBreakpoint = event.detail.up(StickyMenu.BoundBreakpoint);
        },
      );
    this._isBoundBreakpoint = breakpoints.up(StickyMenu.BoundBreakpoint);

    return this;
  }

  static register(
    dom: DOMManipulator,
    breakpoints: Breakpoints,
    $window: DOMBase<Window>,
  ) {
    let menu = dom("#navbar-menu");
    let stickyMenu = new StickyMenu(menu, $window);
    stickyMenu.registerBreakpoints(breakpoints);
    $window.on("scroll", (event: TrigEvent) => {
      stickyMenu.adapt();
    });
    stickyMenu.adapt();

    return stickyMenu;
  }
}
