/// <reference path="../global.d.ts"/>
import type {
  Naja,
  Extension as NajaExtension,
  CompleteEvent,
} from "naja/dist/Naja";
import type { BeforeUpdateEvent } from "naja/dist/core/SnippetHandler";

export type Condition = (e: BeforeUpdateEvent) => boolean;
export type DestructHandler = CustomHandler<BeforeUpdateEvent>;
export { BeforeUpdateEvent, CompleteEvent };

type ResultHandler = CustomHandler<CompleteEvent>;
type InitHandler = () => void;

class MultiPromises {
  private _resolve: ResultHandler[];
  private _reject: ResultHandler[];

  constructor() {
    this._resolve = [];
    this._reject = [];
  }

  resolve(e: CompleteEvent): void {
    this._resolve.forEach(function (h): void {
      h.call(h, e);
    });
  }

  reject(e: CompleteEvent): void {
    this._reject.forEach(function (h): void {
      h.call(h, e);
    });
  }

  then(cb: ResultHandler): this {
    this._resolve.push(cb);

    return this;
  }

  catch(cb: ResultHandler): this {
    this._reject.push(cb);

    return this;
  }

  thenInit(init: InitHandler) {
    this._resolve.push(function (e: CompleteEvent) {
      init();
    });
    init();
  }
}

class Executor {
  _modify: boolean;
  _promise: MultiPromises | null;

  constructor(
    private conditionCb: Condition | null = null,
    private descructCb?: DestructHandler,
  ) {
    this._modify = false;
    this._promise = null;
  }

  conditionHandler(event: BeforeUpdateEvent): boolean {
    return "function" !== typeof this.conditionCb || this.conditionCb(event);
  }

  destructHandler(event: BeforeUpdateEvent): void {
    if ("function" === typeof this.descructCb) {
      this.descructCb(event);
    }
  }

  update(on: boolean = true): this {
    this._modify = on;
    return this;
  }

  isModified(): boolean {
    return this._modify;
  }

  getPromise() {
    if (null === this._promise) {
      this._promise = new MultiPromises();
    }
    return this._promise;
  }

  resolve(e: CompleteEvent): void {
    if (null !== this._promise) {
      this._promise.resolve(e);
    }
  }

  reject(e: CompleteEvent): void {
    if (null !== this._promise) {
      this._promise.reject(e);
    }
  }

  resolveIfModified(e: CompleteEvent): void {
    if (this._modify) {
      this._modify = false;
      this.resolve(e);
    }
  }
}

class SnippetHandlerExtension implements NajaExtension {
  constructor(private snippetHandler: SnippetHandler) {}

  initialize(naja: Naja): void {
    naja.snippetHandler.addEventListener(
      "beforeUpdate",
      (e: BeforeUpdateEvent) => {
        this.snippetHandler.forEachExecutors(function (item) {
          if (!item.isModified() && item.conditionHandler(e)) {
            item.update();
            item.destructHandler(e);
          }
        });
      },
    );
    naja.addEventListener("complete", (e: CompleteEvent): void => {
      if (!("error" in e.detail) || "undefined" === typeof e.detail.error) {
        this.snippetHandler.forEachExecutors(function (item) {
          item.resolveIfModified(e);
        });
      } else {
        this.snippetHandler.forEachExecutors(function (item) {
          item.reject(e);
        });
      }
    });
  }
}

export default class SnippetHandler {
  private _executors: Executor[];

  constructor() {
    this._executors = [];
  }

  public getExecutors(): Executor[] {
    return this._executors;
  }

  public forEachExecutors(cb: (item: Executor, i?: number) => void): void {
    this._executors.forEach(cb);
  }

  public for(
    condition: Condition,
    destructHandler?: DestructHandler,
  ): MultiPromises {
    const executor = new Executor(condition, destructHandler);
    this._executors.push(executor);

    return executor.getPromise();
  }

  public always(destructHandler?: DestructHandler): MultiPromises {
    const executor = new Executor(null, destructHandler);
    this._executors.push(executor);

    return executor.getPromise();
  }

  static register(naja: Naja): SnippetHandler {
    const snippetHandler = new SnippetHandler();
    naja.registerExtension(new SnippetHandlerExtension(snippetHandler));
    return snippetHandler;
  }
}
