/// <reference path="../global.d.ts"/>

import type { Naja } from "naja/dist/Naja";
import type { ExpandMobileDetail } from "./Menu/AdaptiveMenu";
import type UrlFactory from "../Utils/UrlFactory";
import type { ExpandMenuEvent } from "./ControlEvents";
import Dispatcher from "../Event/Dispatcher";
import ControlEvents from "./ControlEvents";

export default class ReloadUrl {
  private _expandMenuHandler: CustomHandler<ExpandMenuEvent>;

  constructor(
    private _urlFactory: UrlFactory,
    private _naja: Naja,
  ) {
    this._expandMenuHandler = this._expandMenuCallback.bind(this);
  }

  private _expandMenuCallback(event: ExpandMenuEvent): void {
    const detail = event.detail as unknown as ExpandMobileDetail;
    const $source = detail.source;
    const path = $source.exists()
      ? ($source.attr("href") as string)
      : "/kompletni-sortiment";
    const url = this._urlFactory.create(path);
    url.searchParams.append("do", "changeCategory");
    this._naja.makeRequest("get", url, null, {
      history: false,
    });
  }

  registerMenuDispatcher(dispatcher: Dispatcher) {
    dispatcher.addListener(
      ControlEvents.ExpandMenuEvent,
      this._expandMenuHandler,
    );
  }

  static register(naja: Naja, urlFactory: UrlFactory): ReloadUrl {
    const reloadUrl = new ReloadUrl(urlFactory, naja);

    return reloadUrl;
  }
}
