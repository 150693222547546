export const SNIPPET_MAIN = 'snippet--main-content';
export const SNIPPET_FULL = 'snippet--full-content';

export type SnippetEvent = CustomEvent<{
    snippet: Element;
}>

export function forSnippet(event: SnippetEvent, snippet: RegExp|string): boolean {
    if ('string' === typeof snippet) {
        return snippet === event.detail.snippet.id
    } else if (snippet instanceof RegExp) {
        return snippet.test(event.detail.snippet.id);
    } else {
        return false
    }
}

